import {
    Grid, Badge, Tab, Tabs, Card, TablePagination, Paper, TableContainer, Table,
    TableRow, TableCell, TableHead, TableBody, Radio, FormControlLabel,
    RadioGroup, Box, Menu, MenuItem, IconButton, Checkbox, Slider, Dialog
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { cutValueFormated, dateToDateLocale, formatSeconds, getNumberBusinessDaysOnward } from "../../util/GlobalFunctions";
import {
    PieChart as PieChartIcon, AccountTree as AccountTreeIcon, Help as HelpIcon,
    AccessTimeFilled as AccessTimeFilledIcon, KeyboardArrowRight as KeyboardArrowRightIcon,
    CropOriginal as CropOriginalIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon,
    Construction as ConstructionIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
    ScatterPlot as ScatterPlotIcon, ImportContacts as ImportContactsIcon,
    ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, AddBox as AddBoxIcon
} from "@mui/icons-material";
import { HelperDialog, StyledTableCell, StyledTableRow, TrueffortButton, TrueffortChip, 
  TrueffortNavButton, TrueffortTableHeadCell } from "../../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import { formatDateString } from "../../util/DateDataFormat";
import CardEmpty from "../custom/CardEmpty";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { COLORS_RELEVANT, MIN_LEVEL_ADMIN } from "../../util/Constants";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import InstanceService from "../../services/instance.service";
import { Scatter } from "react-chartjs-2";
import CustomAvatar from "../custom/CustomAvatar";

function GlobalReportUsers({ startDate, endDate, idSelectedUser, idsSelectedUsers, idSelectedProject, 
  inputSearch, showLoader, dataUsers, indexTab, dataByWeekly, handleChangeIndexTab, countDatesWeekly, 
  pageWeeklyTab, setPageWeeklyTab, dataByBenchmark, users, periods, setPeriodsTab, countPerPageWeekly, setCountPerPageWeekly }) {
  const { user } = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const { t } = useTranslation();
  const [dataUsersFilter, setDataUsersFilter] = React.useState([]);
  const [configMagnitud, setConfigMagnitud] = React.useState(1);
  let ROWS_PER_PAGE = 4;
  const [rowsPerPageUsers, setRowsPerPageUsers] = React.useState(ROWS_PER_PAGE);
  const [usersPage, setUsersPage] = React.useState(0);
  const [usersCount, setUsersCount] = React.useState(0);
  const [showTableProductivityDetail, setShowTableProductivityDetail] = React.useState(false);
  const [showTableElementDetail, setShowTableElementDetail] = React.useState(false);
  const [showTablePhaseDetail, setShowTablePhaseDetail] = React.useState(false);
  const [showTableAppDetail, setShowTableAppDetail] = React.useState(false);
  const [headWeeklyTable, setHeadWeeklyTable] = React.useState([]);
  const [dataWeeklyTable, setDataWeeklyTable] = React.useState([]);
  let hourInitPeriod = new Date();
  hourInitPeriod.setHours(0, 0, 0, 0);
  const [usePeriods, setUsePeriods] = React.useState(false);
  const [openDialogAddPeriod, setOpenDialogAddPeriod] = React.useState(false);
  const [newPeriod, setNewPeriod] = React.useState({ startTime: hourInitPeriod, endTime: hourInitPeriod });
  const [errorAddPeriod, setErrorAddPeriod] = React.useState("");
  const [showMessageWarningDetailWeekly, setShowMessageWarningDetailWeekly] = React.useState("");
  //const [rowsPerPageWeekly, setRowsPerPageWeekly] = React.useState(10);
  const [weeklyPage, setWeeklyPage] = React.useState(0);
  const [weeklyCount, setWeeklyCount] = React.useState(countDatesWeekly);
  const [numberLinesShowWeekly, setNumberLinesShowWeekly] = React.useState(countDatesWeekly);
  const [anchorElemFilterGraphBenchmark, setAnchorElemFilterGraphBenchmark] = React.useState(null);
  const openOptionsGraphBenchmark = Boolean(anchorElemFilterGraphBenchmark);
  const [menuNumberGraphBenchmark, setMenuNumberGraphBenchmark] = React.useState(0);
  const [axisB, setAxisB] = React.useState({ id: 1, name: "IED vs IP" });
  const [dataBenchmark, setDataBenchmark] = React.useState({ data: [] });
  const [valueEfficiency, setValueEfficiency] = React.useState(user.instance?.efficiency);
  const [valueRangeProductivity, setValueRangeProductivity] = React.useState([user.instance?.lowerProductivity, user.instance?.upperProductivity]);
  const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);
  const instanceService = React.useMemo(() => new InstanceService(), []);

  React.useEffect(() => {
    instanceService.get(user.instance?.idInstance).then(({ data: response }) => {
      if (response.success) {
        setValueEfficiency(response.data.efficiency);
        setValueRangeProductivity([response.data.lowerProductivity,
        response.data.upperProductivity]);
      }
    });
  }, [])

  React.useEffect(() => {
    //console.log(dataByBenchmark);
    let finalDataBenchmark = [];
    let idsUser = idsSelectedUsers.map((u) => u.idUser);
    //console.log(idsUser);
    dataByBenchmark.userList.map((us) => {
      //console.log(us);
      let activitiesByUser = dataByBenchmark.activities.filter((act) => act.idUser == us.idUser);
      let resFilter = dataByBenchmark.data.filter((dat) => dat.users.map((u) => u.idUser).includes(us.idUser));
      let appsRelevants = us.relevantApplications.filter(ra => ra.isRelevant).map(ap => ap.idApplication);
      let datesWithActivity = activitiesByUser.map((act) => act.startDate);

      let startD = new Date(startDate);
      let SRj = 0;
      let DHT = 0;
      while (startD <= endDate) {
        if (startD.getDay() != 0 && startD.getDay() != 6) {
          DHT++;
          let dateA = formatDateString(startD, 'yyyy-MM-dd');
          if (!datesWithActivity.includes(dateA)) {
            SRj++;
          }
        }
        startD.setDate(startD.getDate() + 1);
      }

      let resTransform = [];
      resFilter.forEach((d) => {
        let upt = d.users.find((u) => u.idUser == us.idUser);
        if (upt.startDate != null) {
          let dedicatedTime = 0;
          let dedicatedTimeProd = 0;
          let acts = activitiesByUser.filter((act) => act.idProjectTask == d.idProjectTask);
          acts.forEach((act) => {
            dedicatedTime += act.totalSeconds;
            if (appsRelevants.includes(act.idApplication)) {
              dedicatedTimeProd += act.totalSeconds;
            }
          });

          let sD = new Date(upt.startDate);
          let eD = new Date(upt.finishDate);
          let estimatedDays = d.estimatedDays == 0 ? 1 : d.estimatedDays;
          let estimatedTime = d.estimatedTime == 0 ? 1 : d.estimatedTime;
          resTransform.push({
            idProjectTask: d.idProjectTask, idStatus: d.status.idStatus, idTaskType: d.taskType.idTaskType, 
            startDate: sD, endDate: eD, realDuration: getNumberBusinessDaysOnward(sD, eD), 
            dedicatedTimeTotal: d.dedicatedTime, dedicatedTime: dedicatedTime / 3600, 
            dedicatedTimeProd: dedicatedTimeProd / 3600, idProjectPhase: d.projectPhase.idProjectPhase, 
            estimatedDays: estimatedDays, estimatedTime: estimatedTime, 
            estimatedFinishDate: getEstimatedFinishDate(sD, estimatedDays),
            countUsers: d.users.length, estimatedTimeInd: estimatedTime / d.users.length,
            taskName: d.taskType.name, phaseName: d.projectPhase.phaseType.name,
            idProjectElement: d.projectPhase.projectElement.idProjectElement, elementName: d.projectPhase.projectElement.name,
            idProject: d.projectPhase.projectElement.project.idProject,
            projectName: d.projectPhase.projectElement.project.name
          });
        }
      })
      //console.log(resTransform);
      if (resTransform.length > 0) {
        let DRij = resTransform.reduce((ac, cu) => ac + cu.realDuration, 0);
        let TRij = resTransform.reduce((ac, cu) => ac + cu.dedicatedTime, 0);
        let DEij = resTransform.reduce((ac, cu) => ac + cu.estimatedDays, 0);
        let TEij = resTransform.reduce((ac, cu) => ac + cu.estimatedTimeInd, 0);
        let HPj = activitiesByUser.reduce((ac, cu) => ac + (appsRelevants.includes(cu.idApplication) ? cu.totalSeconds : 0), 0) / 3600;
        let HD = 8;
        let HL = HD * DHT;
        let HBT = HD * (DHT - SRj);

        let FED = DRij / DEij;
        //let intFed = 0.2;
        let PMFed = 1;
        let valueEfficiencyAux = valueEfficiency == 0 ? 1 : valueEfficiency;
        let lAdecFED = [PMFed - (valueEfficiencyAux / 100), PMFed + (valueEfficiencyAux / 100)];
        let LB = lAdecFED[1] + ((valueEfficiencyAux / 100) * 2);
        let LS = lAdecFED[0] - ((valueEfficiencyAux / 100) * 2);

        let interpretacionFed = FED >= lAdecFED[0] && FED <= lAdecFED[1] ? "Adecuado" : 
          FED > lAdecFED[1] ? "Bajo" : "Sobre saliente";
        const NLSFed = 9;
        let IED = NLSFed - (NLSFed / (LB - LS)) * (FED - LS);
        if (IED < 0) { IED = 0; }
        if (IED > 9) { IED = 9; }
        //let IED = FED > LB ? 0 : FED < LS ? 9 : NLSFed - (NLSFed / (LB - LS)) * (FED - LS);
        //console.log("FED", FED, "PMFed", PMFed, "LB", LB, "LS", LS, "IED", IED);

        let FEE = TRij / TEij;
        let interpretacionIee = FEE >= lAdecFED[0] && FEE <= lAdecFED[1] ? "Adecuado" :
            FEE > lAdecFED[1] ? "Bajo" : "Sobre saliente";
        const NLSIee = 9;
        let IEE = NLSIee - (NLSIee / (LB - LS)) * (FEE - LS);
        if (IEE < 0) { IEE = 0; }
        if (IEE > 9) { IEE = 9; }
        //let IEE = FEE > LB ? 0 : FEE < LS ? 9 : NLSIee - (NLSIee / (LB - LS)) * (FEE - LS);
        //console.log("FEE", FEE, "IEE", IEE);

        let FIP = HPj / HL;
        let valueRangeProductivityAux = valueRangeProductivity[1] == valueRangeProductivity[0] ? valueRangeProductivity[0] - 1 : valueRangeProductivity[0];
        let LAdecuado = [valueRangeProductivityAux / 100, valueRangeProductivity[1] / 100];
        let interpretacionFip = FIP >= LAdecuado[0] && FIP <= LAdecuado[1] ? "Adecuado" :
            FIP < LAdecuado[0] ? "Bajo" : "Sobre saliente";
        let intRangeProd = ((valueRangeProductivity[1] - valueRangeProductivityAux) / 100);
        let LBFip = (valueRangeProductivityAux - (valueRangeProductivity[1] - valueRangeProductivityAux)) / 100;
        let LSFip = 1 + (intRangeProd / 2);
        const NLSFip = 9;
        let IP = FIP < LBFip ? 0 : FIP > LSFip ? 9 : (NLSFip / (LSFip - LBFip)) * (FIP - LBFip);
        //console.log("FIP", FIP, "LSFip", LSFip, "LBFip", LBFip);

        //console.log("Drij", DRij, "TRij", TRij, "DEij", DEij, "TEij", TEij, "HPj", HPj, "HD", HD, 
        //    "HL", HL, "HBT", HBT, "SRj", SRj, "DHT", DHT, "FED", FED, "IED", IED, "FEE", FEE, "IEE", IEE, "interpretacionFed", interpretacionFed,  
        //    "interpretacionIee", interpretacionIee, "FIP", FIP, "IP", IP, "interpretacionFip", interpretacionFip);
        finalDataBenchmark.push({
          idUser: us.idUser, name: us.name + ' ' + (us.lastName ? us.lastName : ''), DRij, TRij, DEij, TEij, HD, SRj, HL, HBT, HPj, DHT, IED,
          interpretacionFed, IEE, interpretacionIee, IP, interpretacionFip
        })
      }
    });
    //console.log(finalDataBenchmark);
    let groups = [];
    let configs = [];
    let listRemaining = [];
    let remaining = { label: 'Sin grupo', data: [], backgroundColor: COLORS_RELEVANT[0] };
    let remainingArray_ = finalDataBenchmark.filter((d) => !idsUser.includes(d.idUser));
    remainingArray_.forEach((rem) => {
      remaining.data.push({ x: (rem.IP / 9) * 100, y: ((axisB.id == 1 ? rem.IED : rem.IEE) / 9) * 100 });
      listRemaining.push({
        idUser: rem.idUser, name: rem.name, intX: rem.interpretacionFip,
        intY: axisB.id == 1 ? rem.interpretacionFed : rem.interpretacionIee
      });
    });
    if (remaining.data.length > 0) { 
      groups.push(remaining);
      configs.push(listRemaining);
    }

    idsSelectedUsers.map((u_) => {
      let db = finalDataBenchmark.find((d) => d.idUser == u_.idUser);
      if (db) {
        let g = { label: u_.name + ' ' +(u_.lastName ? u_.lastName : ''), 
          data: [{ x: (db.IP / 9) * 100, y: ((axisB.id == 1 ? db.IED : db.IEE) / 9) * 100 }],
          backgroundColor: COLORS_RELEVANT[groups.length]
        };
        configs.push([{
          idUser: db.idUser, name: db.name, intX: db.interpretacionFip,
          intY: axisB.id == 1 ? db.interpretacionFed : db.interpretacionIee
        }])
        groups.push(g);
      }
    });
        
    //console.log(configs);
    //console.log(groups);
    setDataBenchmark({ data: groups, configs });
  }, [dataByBenchmark, axisB, idsSelectedUsers, valueEfficiency, valueRangeProductivity]);

  React.useEffect(() => {
    let dataAux = dataUsers.map(d => d);
    if (inputSearch != "") {
      dataAux = dataAux.filter(d => d.name.toUpperCase().includes(inputSearch.toUpperCase()));
    }

    let data = dataAux.map(us => {
      let projs_ = [];
      let elems_ = [];
      let phases_ = [];
      let tasks_ = [];
      let projsFinished_ = [];
      let elemsFinished_ = [];
      let phasesFinished_ = [];
      let tasksFinished_ = [];
      us.projects.forEach(p => {
        projs_.push(p);
        if (p.idStatus == 4) { projsFinished_.push(p); }
        p.elements.forEach(e => {
          elems_.push(e);
          if (e.idStatus == 4) { elemsFinished_.push(e); }
          e.phases.forEach(ph => {
            phases_.push(ph);
            if (ph.idStatus == 4) { phasesFinished_.push(ph); }
            ph.tasks.forEach(t => {
              tasks_.push(t);
              if (t.idStatus == 4) { tasksFinished_.push(t); }
            });
          });
        });
      });
      let finished = { projects: projsFinished_.length, elements: elemsFinished_.length, phases: phasesFinished_.length, tasks: tasksFinished_.length };

      projs_ = projs_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityProjects = {
        min: (projs_[0].effortRelevant / projs_[0].effort),
        max: (projs_[projs_.length - 1].effortRelevant / projs_[projs_.length - 1].effort), avg: 0
      };
      let avgProj = 0;
      projs_.forEach(p => avgProj += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgProj = avgProj / projs_.length;
      productivityProjects.avg = avgProj;

      elems_ = elems_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityElements = {
        min: (elems_[0].effortRelevant / elems_[0].effort),
        max: (elems_[elems_.length - 1].effortRelevant / elems_[elems_.length - 1].effort), avg: 0
      };
      let avgElem = 0;
      elems_.forEach(p => avgElem += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgElem = avgElem / elems_.length;
      productivityElements.avg = avgElem;

      phases_ = phases_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityPhases = {
        min: (phases_[0].effortRelevant / phases_[0].effort),
        max: (phases_[phases_.length - 1].effortRelevant / phases_[phases_.length - 1].effort), avg: 0
      };
      let avgPhases = 0;
      phases_.forEach(p => avgPhases += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgPhases = avgPhases / phases_.length;
      productivityPhases.avg = avgPhases;

      tasks_ = tasks_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityTasks = {
        min: (tasks_[0].effortRelevant / tasks_[0].effort),
        max: (tasks_[tasks_.length - 1].effortRelevant / tasks_[tasks_.length - 1].effort), avg: 0
      };
      let avgTasks = 0;
      tasks_.forEach(p => avgTasks += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgTasks = avgTasks / tasks_.length;
      productivityTasks.avg = avgTasks;

      let projEffortFinished = projsFinished_.reduce((acc, cur) => acc + cur.effort, 0);
      let elemEfortFinished = elemsFinished_.reduce((a, c) => a + c.effort, 0);
      let phaseEffortFinished = phasesFinished_.reduce((ac, cu) => ac + cu.effort, 0);
      let taskEffortFinished = tasksFinished_.reduce((accu, curr) => accu + curr.effort, 0);
      let effortFinished = {
        projects: projEffortFinished, elements: elemEfortFinished,
        phases: phaseEffortFinished, tasks: taskEffortFinished
      };

      //productivity = cantidad de items finalizados / suma del esfuerzo registrado de los items finalizados
      //PDR = suma del esfuerzo registrado de los items finalizados / cantidad de items finalizados
      //velocity = cantidad de items finalizados / cantidad de dias que contienen esfuerzo del item
      productivityProjects = { min: 0, avg: 0, max: 0 };
      productivityElements = { min: 0, avg: 0, max: 0 };
      productivityPhases = { min: 0, avg: 0, max: 0 };
      productivityTasks = { min: 0, avg: 0, max: 0 };

      if (projsFinished_.length > 0) {
        productivityProjects.avg = projsFinished_.length / (effortFinished.projects / 3600);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityProjects.min = 1 / (projsFinished_[0].effort / 3600);
        productivityProjects.max = 1 / (projsFinished_[projsFinished_.length - 1].effort / 3600);
      }

      if (elemsFinished_.length > 0) {
        productivityElements.avg = elemsFinished_.length / (effortFinished.elements / 3600);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityElements.min = 1 / (elemsFinished_[0].effort / 3600);
        productivityElements.max = 1 / (elemsFinished_[elemsFinished_.length - 1].effort / 3600);
      }

      if (phasesFinished_.length > 0) {
        productivityPhases.avg = phasesFinished_.length / (effortFinished.phases / 3600);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityPhases.min = 1 / (phasesFinished_[0].effort / 3600);
        productivityPhases.max = 1 / (phasesFinished_[phasesFinished_.length - 1].effort / 3600);
      }

      if (tasksFinished_.length > 0) {
        productivityTasks.avg = tasksFinished_.length / (effortFinished.tasks / 3600);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityTasks.min = 1 / (tasksFinished_[0].effort / 3600);
        productivityTasks.max = 1 / (tasksFinished_[tasksFinished_.length - 1].effort / 3600);
      }

      let productivityEffort = {
        projects: productivityProjects, elements: productivityElements,
        phases: productivityPhases, tasks: productivityTasks
      };

      let projDurationFinished = projsFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let elemDurationFinished = elemsFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let phaseDurationFinished = phasesFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let taskDurationFinished = tasksFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let durationFinished = {
        projects: projDurationFinished, elements: elemDurationFinished,
        phases: phaseDurationFinished, tasks: taskDurationFinished
      };

      let prodDurationProj = { min: 0, avg: 0, max: 0 };
      let prodDurationElem = { min: 0, avg: 0, max: 0 };
      let prodDurationPha = { min: 0, avg: 0, max: 0 };
      let prodDurationTasks = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        prodDurationProj.avg = projsFinished_.length / projsFinished_.reduce((a, c) => a + c.duration, 0);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationProj.min = 1 / projsFinished_[0].duration;
        prodDurationProj.max = 1 / projsFinished_[projsFinished_.length - 1].duration;
      }

      if (elemsFinished_.length > 0) {
        prodDurationElem.avg = elemsFinished_.length / elemsFinished_.reduce((a, c) => a + c.duration, 0);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationElem.min = 1 / elemsFinished_[0].duration;
        prodDurationElem.max = 1 / elemsFinished_[elemsFinished_.length - 1].duration;
      }

      if (phasesFinished_.length > 0) {
        prodDurationPha.avg = phasesFinished_.length / phasesFinished_.reduce((a, c) => a + c.duration, 0);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationPha.min = 1 / phasesFinished_[0].duration;
        prodDurationPha.max = 1 / phasesFinished_[phasesFinished_.length - 1].duration;
      }

      if (tasksFinished_.length > 0) {
        prodDurationTasks.avg = tasksFinished_.length / tasksFinished_.reduce((a, c) => a + c.duration, 0);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationTasks.min = 1 / tasksFinished_[0].duration;
        prodDurationTasks.max = 1 / tasksFinished_[tasksFinished_.length - 1].duration;
      }

      let productivityDuration = {
        projects: prodDurationProj, elements: prodDurationElem,
        phases: prodDurationPha, tasks: prodDurationTasks
      };

      let projDurationDaysWorkedFinished = projsFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let elemDurationDaysWorkedFinished = elemsFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let phaseDurationDaysWorkedFinished = phasesFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let taskDurationDaysWorkedFinished = tasksFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let durationDaysWorkedFinished = { projects: projDurationDaysWorkedFinished, elements:
        elemDurationDaysWorkedFinished, phases: phaseDurationDaysWorkedFinished,
        tasks: taskDurationDaysWorkedFinished
      };

      let prodDurationDaysWorkedProj = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedElem = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedPha = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedTasks = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        prodDurationDaysWorkedProj.avg = projsFinished_.length / projsFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedProj.min = 1 / projsFinished_[0].daysWithEffort;
        prodDurationDaysWorkedProj.max = 1 / projsFinished_[projsFinished_.length - 1].daysWithEffort;
      }

      if (elemsFinished_.length > 0) {
        prodDurationDaysWorkedElem.avg = elemsFinished_.length / elemsFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedElem.min = 1 / elemsFinished_[0].daysWithEffort;
        prodDurationDaysWorkedElem.max = 1 / elemsFinished_[elemsFinished_.length - 1].daysWithEffort;
      }

      if (phasesFinished_.length > 0) {
        prodDurationDaysWorkedPha.avg = phasesFinished_.length / phasesFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedPha.min = 1 / phasesFinished_[0].daysWithEffort;
        prodDurationDaysWorkedPha.max = 1 / phasesFinished_[phasesFinished_.length - 1].daysWithEffort;
      }

      if (tasksFinished_.length > 0) {
        prodDurationDaysWorkedTasks.avg = tasksFinished_.length / tasksFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedTasks.min = 1 / tasksFinished_[0].daysWithEffort;
        prodDurationDaysWorkedTasks.max = 1 / tasksFinished_[tasksFinished_.length - 1].daysWithEffort;
      }

      let productivityDurationDaysWorked = {
        projects: prodDurationDaysWorkedProj, elements: prodDurationDaysWorkedElem,
        phases: prodDurationDaysWorkedPha, tasks: prodDurationDaysWorkedTasks
      };

      let avgPDRProject = 0;
      let PDRProject = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRProject = (projsFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / projsFinished_.length;
        PDRProject.min = (projsFinished_[0].effort / 3600) / 1;
        PDRProject.max = (projsFinished_[projsFinished_.length - 1].effort / 3600) / 1;
        PDRProject.avg = avgPDRProject;
      }

      let avgPDRElements = 0;
      let PDRElements = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRElements = (elemsFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / elemsFinished_.length;
        PDRElements.min = (elemsFinished_[0].effort / 3600) / 1;
        PDRElements.max = (elemsFinished_[elemsFinished_.length - 1].effort / 3600) / 1;
        PDRElements.avg = avgPDRElements;
      }

      let avgPDRPhases = 0;
      let PDRPhases = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRPhases = (phasesFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / phasesFinished_.length;
        PDRPhases.min = (phasesFinished_[0].effort / 3600) / 1;
        PDRPhases.max = (phasesFinished_[phasesFinished_.length - 1].effort / 3600) / 1;
        PDRPhases.avg = avgPDRPhases;
      }

      let avgPDRTasks = 0;
      let PDRTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRTasks = (tasksFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / tasksFinished_.length;
        PDRTasks.min = (tasksFinished_[0].effort / 3600) / 1;
        PDRTasks.max = (tasksFinished_[tasksFinished_.length - 1].effort / 3600) / 1;
        PDRTasks.avg = avgPDRTasks;
      }
      let PDREffort = { projects: PDRProject, elements: PDRElements, phases: PDRPhases, tasks: PDRTasks };

      let avgPDRDurationProject = 0;
      let PDRDurationProj = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationProject = projsFinished_.reduce((a, c) => a + c.duration, 0) / projsFinished_.length;
        PDRDurationProj.min = projsFinished_[0].duration / 1;
        PDRDurationProj.max = projsFinished_[projsFinished_.length - 1].duration / 1;
        PDRDurationProj.avg = avgPDRDurationProject;
      }

      let avgPDRDurationElements = 0;
      let PDRDurationElem = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationElements = elemsFinished_.reduce((a, c) => a + c.duration, 0) / elemsFinished_.length;
        PDRDurationElem.min = elemsFinished_[0].duration / 1;
        PDRDurationElem.max = elemsFinished_[elemsFinished_.length - 1].duration / 1;
        PDRDurationElem.avg = avgPDRDurationElements;
      }

      let avgPDRDurationPhases = 0;
      let PDRDurationPhases = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationPhases = phasesFinished_.reduce((a, c) => a + c.duration, 0) / phasesFinished_.length;
        PDRDurationPhases.min = phasesFinished_[0].duration / 1;
        PDRDurationPhases.max = phasesFinished_[phasesFinished_.length - 1].duration / 1;
        PDRDurationPhases.avg = avgPDRDurationPhases;
      }

      let avgPDRDurationTasks = 0;
      let PDRDurationTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationTasks = tasksFinished_.reduce((a, c) => a + c.duration, 0) / tasksFinished_.length;
        PDRDurationTasks.min = tasksFinished_[0].duration / 1;
        PDRDurationTasks.max = tasksFinished_[tasksFinished_.length - 1].duration / 1;
        PDRDurationTasks.avg = avgPDRDurationTasks;
      }
      let PDRDuration = { projects: PDRDurationProj, elements: PDRDurationElem,
        phases: PDRDurationPhases, tasks: PDRDurationTasks
      };

      let avgPDRDurDaysWorkedProj = 0;
      let PDRDurDaysWorkedProj = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedProj = projsFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / projsFinished_.length;
        PDRDurDaysWorkedProj.min = projsFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedProj.max = projsFinished_[projsFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedProj.avg = avgPDRDurDaysWorkedProj;
      }

      let avgPDRDurDaysWorkedElem = 0;
      let PDRDurDaysWorkedElem = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedElem = elemsFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / elemsFinished_.length;
        PDRDurDaysWorkedElem.min = elemsFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedElem.max = elemsFinished_[elemsFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedElem.avg = avgPDRDurDaysWorkedElem;
      }

      let avgPDRDurDaysWorkedPha = 0;
      let PDRDurDaysWorkedPha = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedPha = phasesFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / phasesFinished_.length;
        PDRDurDaysWorkedPha.min = phasesFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedPha.max = phasesFinished_[phasesFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedPha.avg = avgPDRDurDaysWorkedPha;
      }

      let avgPDRDurDaysWorkedTasks = 0;
      let PDRDurDaysWorkedTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedTasks = tasksFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / tasksFinished_.length;
        PDRDurDaysWorkedTasks.min = tasksFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedTasks.max = tasksFinished_[tasksFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedTasks.avg = avgPDRDurDaysWorkedTasks;
      }
      let PDRDurationDaysWorked = { projects: PDRDurDaysWorkedProj, elements: PDRDurDaysWorkedElem,
        phases: PDRDurDaysWorkedPha, tasks: PDRDurDaysWorkedTasks
      };

      let vProject = projsFinished_.length > 0 ? 
        projsFinished_.length / projsFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vElements = elemsFinished_.length > 0 ? 
        elemsFinished_.length / elemsFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vPhases = phasesFinished_.length > 0 ? 
        phasesFinished_.length / phasesFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vTasks = tasksFinished_.length > 0 ? 
        tasksFinished_.length / tasksFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let velocity = { projects: vProject, elements: vElements, phases: vPhases, tasks: vTasks };

      let groupsIdsElementsType = {};
      let groupsElementsType = [];
      for (let elementType of elems_) {
        if (groupsIdsElementsType[elementType.idElementType] == undefined) {
          groupsIdsElementsType[elementType.idElementType] = {
            idElementType: elementType.idElementType, name: elementType.nameElementType,
            elementsByType: elems_.filter(e => e.idElementType == elementType.idElementType)
          };
          groupsElementsType.push(groupsIdsElementsType[elementType.idElementType]);
        }
      }

      let groupsIdsPhasesType = {};
      let groupsPhasesType = [];
      for (let pt of phases_) {
        if (groupsIdsPhasesType[pt.idPhaseType] == undefined) {
          groupsIdsPhasesType[pt.idPhaseType] = {
            idPhaseType: pt.idPhaseType, name: pt.name,
            phasesByType: phases_.filter(e => e.idPhaseType == pt.idPhaseType)
          };
          groupsPhasesType.push(groupsIdsPhasesType[pt.idPhaseType]);
        }
      }
      let groupsIdsTasksType = {};
      let groupsTasksType = [];
      for (let tt of tasks_) {
        if (groupsIdsTasksType[tt.idTaskType] == undefined) {
          groupsIdsTasksType[tt.idTaskType] = {
            idTaskType: tt.idTaskType, name: tt.name,
            tasksByType: tasks_.filter(e => e.idTaskType == tt.idTaskType)
          };
          groupsTasksType.push(groupsIdsTasksType[tt.idTaskType]);
        }
      }

      let totalDaysWorkedProject = us.projects.reduce((a, c) => a + c.daysWithEffort, 0);
      let totalDaysWorkedElement = groupsElementsType.map(e => e.elementsByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDaysWorkedPhases = groupsPhasesType.map(p => p.phasesByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDaysWorkedTasks = groupsTasksType.map(t => t.tasksByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((ac, cu) => ac + cu, 0);
      let durationDaysWorked = { projects: totalDaysWorkedProject, elements: totalDaysWorkedElement,
        phases: totalDaysWorkedPhases, tasks: totalDaysWorkedTasks
      };

      let totalDurationProject = us.projects.reduce((a, c) => a + c.duration, 0);
      let totalDurationElement = groupsElementsType.map(e => e.elementsByType.reduce((a, c) => a + c.duration, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDurationPhases = groupsPhasesType.map(p => p.phasesByType.reduce((a, c) => a + c.duration, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDurationTasks = groupsTasksType.map(t => t.tasksByType.reduce((a, c) => a + c.duration, 0)).reduce((ac, cu) => ac + cu, 0);
      let duration = { projects: totalDurationProject, elements: totalDurationElement, phases: totalDurationPhases, tasks: totalDurationTasks };

      let prevUs = dataUsersFilter.find(u => u.idUser == us.idUser);

      return {
        ...us, timeEntryFormat: getFormatShedule(us.checkInHour), timeExitFormat: getFormatShedule(us.checkOutHour),
        productivityEffort, productivityDuration, productivityDurationDaysWorked, finished, effortFinished,
        durationFinished, durationDaysWorkedFinished, PDREffort,
        PDRDuration, PDRDurationDaysWorked, velocity, groupsElementsType, groupsPhasesType, groupsTasksType, duration, durationDaysWorked,
        showListProjectDetail: prevUs ? prevUs.showListProjectDetail : false
      };
    });
    //console.log(data);
    setDataUsersFilter(data);
    setUsersCount(data.length);
  }, [dataUsers, inputSearch]);

  React.useEffect(() => {
    if (periods.length > 0) {
      setUsePeriods(true);
    }
  }, []);

  React.useEffect(() => {
      let ids = idsSelectedUsers.map((u) => u.idUser);
      let auxData = [];
      auxData = ids.length > 0 ? dataByWeekly.filter((rec) => ids.includes(rec.idUser)) : dataByWeekly.map(a => a);
      let result = [];
      let periodsHead = [];
      if (auxData.length > 0) {
          auxData[0].dataPeriods.forEach((per, j) => { periodsHead.push(per.idPeriod); });
      }
      //console.log(auxData);
      let auxEndDate = new Date(endDate);
      auxEndDate = new Date(auxEndDate.setDate(auxEndDate.getDate() - (weeklyPage * countPerPageWeekly)));

      let auxStartDate = new Date(auxEndDate);
      auxStartDate = new Date(auxStartDate.setDate(auxStartDate.getDate() + (1 - countPerPageWeekly)));
      if (auxStartDate.getTime() < startDate.getTime()) { auxStartDate = new Date(startDate); }

      //console.log(auxStartDate + " " + auxEndDate);
      auxStartDate = dateToDateLocale(auxStartDate);
      auxEndDate = dateToDateLocale(auxEndDate);
      //console.log(periodsHead);
      let totalTimeTotal = 0;
      let totalTimeProductiveTotal = 0;
      //console.log(startDate);
      while (auxEndDate >= auxStartDate) {
          let dateA = formatDateString(auxEndDate, 'yyyy-MM-dd');
          //console.log(dateA);
          let record = { date: dateA, totalTime: 0, totalTimeProductive: 0, periods: [], showDetail: false };
          let totalTime = 0;
          let totalTimeProductive = 0;

          let checkInListGlobal = [], checkOutListGlobal = [];
          let detailByUser = [];
          auxData.forEach((r) => {
              let totalTimeUser = 0;
              let totalTimeProdUser = 0;
              let dataFilter = r.data.filter((rec) => rec.startDate == dateA);
              let checkInUserList = [];
              let checkOutUserList = [];
              dataFilter.forEach((act, ind) => {
                  checkInListGlobal.push(act.startTime);
                  checkOutListGlobal.push(act.endTime);
                  checkInUserList.push(act.startTime);
                  checkOutUserList.push(act.endTime);
                  totalTimeUser += act.totalSeconds;
                  if (r.idsApplicationRelevants.includes(act.idApplication)) {
                      totalTimeProdUser += act.totalSeconds;
                  }
              });
              totalTime += totalTimeUser;
              totalTimeProductive += totalTimeProdUser;
              checkInUserList = checkInUserList.sort((a, b) => a.startTime - b.startTime);
              checkOutUserList = checkOutUserList.sort((a, b) => a.endTime - b.endTime);
              if (totalTimeUser > 0) {
                  detailByUser.push({
                      idUser: r.idUser, name: r.name, image: r.image, checkIn: (checkInUserList.length > 0 ? checkInUserList[0] : null),
                      checkOut: (checkOutUserList.length > 0 ? checkOutUserList[checkOutUserList.length - 1] : null), periods: [],
                      totalTime: totalTimeUser, totalTimeProductive: totalTimeProdUser
                  });
              }
          });

          checkInListGlobal = checkInListGlobal.sort((a, b) => a.startTime - b.startTime);
          checkOutListGlobal = checkOutListGlobal.sort((a, b) => a.endTime - b.endTime);
          let checkIn = checkInListGlobal.length > 0 ? checkInListGlobal[0] : null;
          let checkOut = checkOutListGlobal.length > 0 ? checkOutListGlobal[checkOutListGlobal.length - 1] : null;

          let periodos = [];
          periodsHead.forEach((p) => {
              let recordsByPeriodAndDate = [];
              let totalTimePeriod = 0;
              let totalTimePeriodProductive = 0;
              let checkInList = [], checkOutList = [];
              auxData.forEach((r) => {
                  let checkInListByUserAndPeriod = [];
                  let checkOutListByUserAndPeriod = [];
                  let totalByPeriodAndUser = 0;
                  let totalProdByPeriodAndUser = 0;

                  let objDataPeriod = r.dataPeriods.find((per) => per.idPeriod == p);
                  let objDataPeriodFilter = objDataPeriod.data.filter((rec) => rec.startDate == dateA);
                  objDataPeriodFilter.forEach((act, ind) => {
                      if (ind == 0) { checkInList.push(act) }
                      if (ind == (objDataPeriodFilter.length - 1)) { checkOutList.push(act) }
                      checkInListByUserAndPeriod.push(act.startTime);
                      checkOutListByUserAndPeriod.push(act.endTime);
                      recordsByPeriodAndDate.push(act);

                      totalByPeriodAndUser += act.totalSeconds;
                      //totalTimePeriod += act.totalSeconds;
                      if (r.idsApplicationRelevants.includes(act.idApplication)) {
                          totalProdByPeriodAndUser += act.totalSeconds;
                          //totalTimePeriodProductive += act.totalSeconds;
                      }
                  });

                  totalTimePeriod += totalByPeriodAndUser;
                  totalTimePeriodProductive += totalProdByPeriodAndUser;

                  checkInListByUserAndPeriod = checkInListByUserAndPeriod.sort((a, b) => a.startTime - b.startTime);
                  checkOutListByUserAndPeriod = checkOutListByUserAndPeriod.sort((a, b) => a.endTime - b.endTime);

                  let indexUser = detailByUser.findIndex((u) => u.idUser == r.idUser);
                  if (indexUser != -1) {
                      detailByUser[indexUser].periods.push({
                          periodKey: p, data: objDataPeriodFilter,
                          checkIn: checkInListByUserAndPeriod.length > 0 ? checkInListByUserAndPeriod[0] : null,
                          checkOut: checkOutListByUserAndPeriod.length > 0 ? checkOutListByUserAndPeriod[checkOutListByUserAndPeriod.length - 1] : null,
                          totalTime: totalByPeriodAndUser, totalTimeProductive: totalProdByPeriodAndUser
                      });
                  }
              });

              checkInList = checkInList.sort((a, b) => a.startTime - b.startTime);
              checkOutList = checkOutList.sort((a, b) => a.endTime - b.endTime);
              let checkIn_ = (checkInList.length > 0 ? checkInList[0].startTime : null);
              let checkOut_ = (checkOutList.length > 0 ? checkOutList[checkOutList.length - 1].endTime : null);
              periodos.push({
                  periodKey: p, data: recordsByPeriodAndDate, checkIn: checkIn_, checkOut: checkOut_,
                  totalTime: totalTimePeriod, totalTimeProductive: totalTimePeriodProductive
              });
          });

          let remainingTotalTime = totalTime;
          let remainingTotalTimeProductive = totalTimeProductive;
          periodos.forEach((per) => {
              remainingTotalTime -= per.totalTime;
              remainingTotalTimeProductive -= per.totalTimeProductive;
          });
          if (periodos.length > 0) {
              periodos.push({ periodKey: 'remaining', checkIn, checkOut, totalTime: remainingTotalTime, totalTimeProductive: remainingTotalTimeProductive });
              //console.log(detailByUser);
              for (let i = 0; i < detailByUser.length; i++) {
                  let t = 0;
                  let tp = 0;
                  for (let j = 0; j < detailByUser[i].periods.length; j++) {
                      t += detailByUser[i].periods[j].totalTime;
                      tp += detailByUser[i].periods[j].totalTimeProductive;
                  }
                  detailByUser[i].periods.push({
                      periodKey: 'remaining',
                      checkIn: detailByUser[i].checkIn, checkOut: detailByUser[i].checkOut,
                      totalTime: detailByUser[i].totalTime - t,
                      totalTimeProductive: detailByUser[i].totalTimeProductive - tp
                  });
              }
          }
          //console.log(periodos);
          record.totalTime = totalTime;
          totalTimeTotal += totalTime;
          record.totalTimeProductive = totalTimeProductive;
          totalTimeProductiveTotal += totalTimeProductive;
          record.periods = periodos;
          record.detailByUser = detailByUser;
          result.push(record);
          auxEndDate.setDate(auxEndDate.getDate() - 1);
      }
      //console.log(periodsHead);
      let periodsTotal = [];
      if (periodsHead.length > 0) {
          periodsHead.push('remaining');
          periodsHead.forEach((ph) => {
              let pT = { periodKey: ph, totalTime: 0, totalTimeProductive: 0 };
              let list = result.map((r) => r.periods.filter((p) => p.periodKey == ph)[0]);
              pT.totalTime = list.reduce((ac, cu) => ac + cu.totalTime, 0);
              pT.totalTimeProductive = list.reduce((ac, cu) => ac + cu.totalTimeProductive, 0);
              periodsTotal.push(pT);
          });
      }
      result.push({
          date: t('reports.total'), showDetail: true, checkIn: null, checkOut: null,
          periods: periodsTotal, totalTime: totalTimeTotal, totalTimeProductive: totalTimeProductiveTotal
      });

      if (weeklyPage == 0) {
          let totalH = 0, totalProductiveH = 0;
          auxData.forEach((u) => {
              totalH += u.total;
              totalProductiveH += u.totalProductive;
          });
          //console.log(periodsHead);
          let periodsTotales = [];
          let tpt = 0, tppt = 0;
          for (let i = 0; i < periodsHead.length - 1; i++) {
              let totalByPeriod = 0;
              let totalProductiveByPeriod = 0;
              auxData.forEach((u) => {
                  let p_ = u.dataPeriods.find(per => per.idPeriod == periodsHead[i]);
                  totalByPeriod += p_.total;
                  totalProductiveByPeriod += p_.totalProductive;
              })
              tpt += totalByPeriod;
              tppt += totalProductiveByPeriod;
              periodsTotales.push({ periodKey: periodsHead[i], total: totalByPeriod, totalProductive: totalProductiveByPeriod });
          }
          if (periodsHead.length > 0) {
              periodsTotales.push({ periodKey: 'remaining', total: totalH - tpt, totalProductive: totalProductiveH - tppt });
          }
          //console.log(periodsTotales);
          setHeadWeeklyTable({ total: totalH, totalProductive: totalProductiveH, periods: periodsTotales });
      }
      //result.push({date:'Total', totalTime:0, totalTimeProductive:0 })
      //console.log(result);
      setDataWeeklyTable(result);
      let startDate_ = new Date(startDate);
      startDate_.setHours(0);
      startDate_.setMinutes(0);
      startDate_.setSeconds(0);
      startDate_.setMilliseconds(0);
      let current = new Date();
      current = new Date(current.setMonth(current.getMonth() - 6));
      current.setHours(0);
      current.setMinutes(0);
      current.setSeconds(0);
      current.setMilliseconds(0);
      if (startDate_.getTime() < current.getTime()) {
          setShowMessageWarningDetailWeekly(t("reports.time_summary.msg_warning_information_not_available").replace("{0}", formatDateString(current, 'yyyy-MM-dd')));
      } else {
          setShowMessageWarningDetailWeekly("");
      }
  }, [dataByWeekly, countDatesWeekly, weeklyPage]);

  React.useEffect(() => {
    setWeeklyCount(countDatesWeekly);
    setNumberLinesShowWeekly(countDatesWeekly);
  }, [countDatesWeekly]);

  React.useEffect(() => {
    setWeeklyPage(pageWeeklyTab);
  }, [pageWeeklyTab]);

  const getFormatShedule = (time) => {
    let init = new Date(time);
    init = new Date(init.getTime() + init.getTimezoneOffset() * 60000);
    return (init.getHours() < 10 ? "0" + init.getHours() : init.getHours()) + ":" +
      (init.getMinutes() < 10 ? "0" + init.getMinutes() : init.getMinutes());
  }

  const getEstimatedFinishDate = (start, count) => {
      start.setHours(start.getHours() + 6);
      let estimatedFinishDate = new Date(start);
      let c = 0;
      while (c < count) {
          let day = new Date(estimatedFinishDate).getDay();
          if (day == 5) {
              c++;
              estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 3);
          } else if (day == 6) {
              c++;
              estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 2);
          } else {
              c++
              estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 1);
          }
      }
      return estimatedFinishDate;
  }

  const getExtractBar = (name, index, isFirst, isLast, totalTime, extractTime) => {
    //console.log(totalTime, extractTime);
      return (<span title={name} style={{
          background: COLORS_RELEVANT[index % COLORS_RELEVANT.length], width: ((extractTime / totalTime) * 100) + '%', height: '20px',
          borderTopLeftRadius: isFirst ? '5px' : '', borderBottomLeftRadius: isFirst ? '5px' : '',
          borderTopRightRadius: isLast ? '5px' : '', borderBottomRightRadius: isLast ? '5px' : ''
      }}>
      </span>)
  }

  const styleTableContent = { fontSize: '12px', padding: 0 };
  const alignText = { textAlign: 'center' };
  const columnData = { textAlign: "center", width: '33%', height: '20px', marginBottom: '0.2em' };
  const rowData = { width: '100%', display: 'flex', flexDirection: 'row' };
  const columnDataDetail = { textAlign: "center", width: '75px' };
  const rowDataWeekly = { width: '100px', minWidth: '100px', padding: 0 };
  const rowDataDateWeekly = { width: '120px', minWidth: '120px', padding: 0 };
  const styleWorkingOn = { width: '220px', display: 'flex' };
  const styleVelocity = { textAlign: "center", height: '20px', marginBottom: '0.2em' };

  const handleChangeMagnitud = (event) => {
    setConfigMagnitud(event.target.value);
  };

  const AvatarAndUser = (name, urlImage, isActive) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isActive != null ?
          <Badge variant="dot" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{
              '& .MuiBadge-badge': { right: 5, top: 12, border: `2px solid #EFEFEF`,
                backgroundColor: isActive ? '#78B737' : '#989898', width: '15px',
                height: '15px', borderRadius: '64px'
              }
            }}
          >
            <CustomAvatar 
              url={urlImage}
            />
          </Badge> : 
          <CustomAvatar 
            url={urlImage}
          />
        }
        <div style={{ paddingLeft: '0.2em' }}>{name}</div>
      </div>
    );
  }

  const divColumnData = (d1, d2, d3) => {
    return (
      <div style={{ ...styleTableContent, ...columnData }}>
        {(configMagnitud == 1 ? d1 : configMagnitud == 2 ? d2 : d3).toFixed(2)}
      </div>
    );
  }

  const handleChangeWeeklyRowsPerPage = (event) => {
    //setRowsPerPageWeekly(parseInt(event.target.value, 10));
    setCountPerPageWeekly(parseInt(event.target.value, 10));
    setWeeklyPage(0);
  }
  //console.log(rowsPerPageWeekly);

  const handleChangeWeeklyPage = (event, newPage) => {
    setWeeklyPage(newPage);
    setPageWeeklyTab(newPage);
  }

  const handleChangeUsersRowsPerPage = (event) => {
    setRowsPerPageUsers(parseInt(event.target.value, 10));
    setUsersPage(0);
  }

  const handleChangeUsersPage = (event, newPage) => {
    setUsersPage(newPage);
  }

  const handleClickMenuBenchmark = (event, value) => {
    setAnchorElemFilterGraphBenchmark(event.currentTarget);
    setMenuNumberGraphBenchmark(value);
  }

  const handleCloseDialogAddPeriod = () => {
    setOpenDialogAddPeriod(false);
  };

  const handleActiveHelperChange = () => {
    setActiveHelperDialogIndex((prev) => prev + 1);
  }

  const DialogAddPeriod = (onClose, open) => {
    const handleClose = () => { 
      onClose(); 
    };

    const handleListItemClick = () => {
      if (newPeriod.endTime.getTime() > newPeriod.startTime.getTime()) {
        let hasError = false;
        for (let i = 0; i < periods.length; i++) {
          hasError = newPeriod.startTime.getTime() < periods[i].startTime.getTime() && newPeriod.endTime.getTime() > periods[i].startTime.getTime();
          if (hasError) {
            setErrorAddPeriod(t("reports.time_summary.msg_error_1").replace("{0}", dateToDateLocale(periods[i].startTime).toISOString().split('T')[1].substring(0, 5)));
            break;
          }
        }

        if (!hasError) {
          for (let i = 0; i < periods.length; i++) {
            hasError = newPeriod.startTime.getTime() > periods[i].startTime.getTime() && newPeriod.startTime.getTime() < periods[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_2").replace("{0}", dateToDateLocale(periods[i].endTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }
        if (!hasError) {
          for (let i = 0; i < periods.length; i++) {
            hasError = newPeriod.endTime.getTime() > periods[i].startTime.getTime() && newPeriod.endTime.getTime() < periods[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_1").replace("{0}", dateToDateLocale(periods[i].startTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }

        if (!hasError) {
          for (let i = 0; i < periods.length; i++) {
            hasError = newPeriod.startTime.getTime() < periods[i].startTime.getTime() && newPeriod.endTime.getTime() > periods[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_3").replace("{0}",
                dateToDateLocale(periods[i].startTime).toISOString().split('T')[1].substring(0, 5)).replace("{1}",
                dateToDateLocale(periods[i].endTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }

        if (!hasError) {
          setErrorAddPeriod("");
          let p = periods.slice();
          p.push(newPeriod);
          setPeriodsTab(p);
          setNewPeriod({ startTime: hourInitPeriod, endTime: hourInitPeriod });
          handleClose();
        }
      } else {
        setErrorAddPeriod(t("reports.time_summary.msg_error_4"));
      }
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <div style={{ padding: '0.8em' }}>
          <h6>{t("reports.time_summary.title_add_period")}</h6>
          <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '0.2em', width: '100%' }}>
            <div style={{ width: '40%' }}>
              <p style={{ paddingRight: '0.2em' }}>{t("reports.time_summary.in_hour") + ':'}</p>
            </div>
            <div style={{ width: '60%' }}>
              <TimeField
                size="small"
                value={dayjs(newPeriod.startTime.toISOString())}
                onChange={val => {
                  setNewPeriod({ ...newPeriod, startTime: val.$d });
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '0.2em', paddingTop: '0.2em' }}>
            <div style={{ width: '40%' }}><p style={{ paddingRight: '0.2em' }}>{t("reports.time_summary.out_hour") + ':'}</p></div>
            <div style={{ width: '60%' }}>
              <TimeField
                size="small"
                value={dayjs(newPeriod.endTime.toISOString())}
                onChange={val => {
                  setNewPeriod({ ...newPeriod, endTime: val.$d });
                }}
              />
            </div>
          </div>
          {errorAddPeriod != "" && 
            <div style={{ fontSize: '12px', color: '#E4555A', paddingTop: '0.3em' }}>{errorAddPeriod}</div>}
          <div style={{ display: 'flex', paddingTop: '1em', justifyContent: 'space-around' }}>
            <TrueffortButton
              variant="contained"
              color="grayMedium"
              size="small"
              onClick={() => {
                setNewPeriod({ startTime: hourInitPeriod, endTime: hourInitPeriod });
                handleClose();
                setErrorAddPeriod("");
              }}
            >{"Cancelar"}</TrueffortButton>
            <TrueffortButton
              variant="contained"
              size="small"
              onClick={() => handleListItemClick()}
            >{"Aceptar"}</TrueffortButton>
          </div>
        </div>
      </Dialog>
    );
  }

  const image = new Image();
  image.src = './assets/images/int_benchmark.png';

  const options = {
    animation: false,
    responsive:true,
    elements: {
      point: {
        pointStyle: 'circle',
        radius: 10  
      }
    },
    scales: { 
      x: { title: { 
          text: t("reports.benchmark.axis_IP") + " (%)", 
          display: true
        }, type: 'linear', min: 0, max:100
      }, 
      y: { title: { 
          text: (axisB.id == 1 ? t("reports.benchmark.axis_IED") : t("reports.benchmark.axis_IEE")) + " (%)", 
          display: true
        }, type:'linear', min: 0, max:100
      } 
    },
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      tooltip: {
        enabled: 'auto',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = dataBenchmark.configs[context.datasetIndex][context.dataIndex].name 
              + ' (' + (axisB.id == 1 ? t("reports.benchmark.IED") : t("reports.benchmark.IEE")) 
              + ': ' + dataBenchmark.configs[context.datasetIndex][context.dataIndex].intY +' - ' 
              + t("reports.benchmark.IP") + ': ' + dataBenchmark.configs[context.datasetIndex][context.dataIndex].intX + ')';
            return (
              label
            );
          }
        }
      }
    }
  };

  const plugin = {
    id: "custom_canvas_background_image",
    beforeDraw: (chart) => {
      //console.log(chart);
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        ctx.drawImage(image, left, top, width, height);
      } else {
        image.onload = () => chart.draw();
      }
    }
  }

  const getRadioButton = (handle, value, title, subtitle, width) => {
    return (<Box style={{display:'flex', flexDirection:'column', width:width}}>
    <FormControlLabel
      sx={{marginBottom:'0'}}
      onChange={handle} value={value}
      control={<Radio size="small" />} label={title} />
      <span style={{fontSize:'10px', color:'gray', paddingLeft:'1em'}}>{subtitle}</span>
    </Box>);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Paper elevation={0} sx={{ padding: '12px', bgcolor: showLoader ? 'transparent' : '#F5F5F5', marginTop: '1em', width: '100%' }} >
          {!showLoader &&
            <Grid container spacing={1} xs={12}>
              <Grid id={"idTabsUsers"} item xl={10} md={10} sm xs={12}>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  value={indexTab}
                  onChange={handleChangeIndexTab}
                >
                  {features.userProductivityReportItemsPerformed && 
                    <Tab id="idTabGlobal" sx={{ minHeight: '0px' }}
                      icon={<PieChartIcon />} iconPosition="start"
                      label={t("reports.global_view")} value={0} />}
                  {false && <Tab id="idTabDetail" sx={{ minHeight: '0px' }}
                    icon={<AccountTreeIcon />} iconPosition="start"
                    label={t("reports.detail_view")} value={1} />}
                  {features.worksheetsUsers && <Tab id="idTabWeekly" sx={{ minHeight: '0px' }}
                    icon={<ImportContactsIcon />} iconPosition="start"
                    label={t("reports.time_summary.title")} value={3} />}
                  {features.reportBenchmark && <Tab id="idTabDetailGraph" sx={{ minHeight: '0px' }}
                    icon={<ScatterPlotIcon />} iconPosition="start"
                    label={t("reports.benchmark.title")} value={4} />}
                </Tabs>
              </Grid>
            </Grid>
          }
          {!showLoader && (indexTab == 0) &&
            <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
              {dataUsersFilter.length == 0 ?
                <CardEmpty> <div>{t("msg.info.empty_response")}</div></CardEmpty> :
                <Card sx={{ padding: "8px", width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'unset', marginBottom: '0.5em' }}>
                    <div style={{ paddingRight: '0.4em', fontSize: '12px', fontWeight: 'bold', paddingRight: '1em' }}>{t("reports.show")}</div>
                    <RadioGroup row
                      defaultValue={1}
                      name="row-radio-buttons-group"
                      sx={{
                          '& .MuiTypography-root': { fontSize: '12px' },
                          '& .MuiRadio-root': { padding: '0 0 0 1px' }
                      }}
                    >
                      {getRadioButton(handleChangeMagnitud, 1, t("reports.effort"), t("reports.hours"), '80px')}
                      {getRadioButton(handleChangeMagnitud, 2, t("reports.duration"), t("reports.working_days"), '100px')}
                      {getRadioButton(handleChangeMagnitud, 3, t("reports.days_worked"), t("reports.days_with_activity"), '180px')}
                    </RadioGroup>
                  </div>
                  <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                    <TableContainer sx={{ overflow: "scroll" }} component={Paper}>
                      <Table responsive={true}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ ...styleTableContent }}>
                                {t("User")}
                            </TableCell>
                            <TableCell style={{ ...styleTableContent }}>
                                {t("reports.working_in")}
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, width: '80px' }}>
                                {t("reports.in_process")}
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, width: '80px' }}>
                                {t("reports.finished")}
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, width: '80px' }}>
                                {configMagnitud == 1 ? t('reports.effort') :
                                    configMagnitud == 2 ? t('reports.duration') : t('reports.days_worked')}
                            </TableCell>
                            <TableCell sx={{ paddingTop: 0, paddingBottom: 0, background: '#EFEFEF', width: '210px' }}>
                              <TableRow>
                                <TableCell colSpan={3} style={{ ...styleTableContent, ...alignText, width: '33%' }}>
                                  <div style={{ padding: 0, fontWeight: 'bold' }}>{t("Productivity")}</div>
                                  <div style={{ fontSize: '9px' }}>{configMagnitud == 1 ?
                                    t('reports.productivity_description_effort') :
                                    t('reports.productivity_description_duration')}</div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.minimum")}
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.average")}
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.maximum")}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell sx={{ paddingTop: 0, paddingBottom: 0, background: '#EFEFEF', width: '210px' }}>
                              <TableRow>
                                <TableCell colSpan={3} style={{ ...styleTableContent, ...alignText, width: '33%' }}>
                                  <div style={{ padding: 0, fontWeight: 'bold' }}>{"PDR"}</div>
                                  <div style={{ fontSize: '9px' }}>{configMagnitud == 1 ?
                                    t('reports.PDR_description_effort') :
                                    t('reports.PDR_description_duration')}</div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.minimum")}
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.average")}
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText }}>
                                  {t("reports.maximum")}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, textAlign: 'center' }}>
                              <div style={{ fontWeight: 'bold' }}>{t("reports.velocity")}</div>
                              <div style={{ fontSize: '9px' }}>{t("reports.productivity_description_duration_1")}</div>
                              <div style={{ fontSize: '9px' }}>{t("reports.productivity_description_duration_2")}</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataUsersFilter.slice(usersPage * rowsPerPageUsers,
                            usersPage * rowsPerPageUsers + rowsPerPageUsers).map((us, ind) => (
                              <TableRow>
                                <TableCell style={{ width: '230px', padding: '0.2em' }}>{AvatarAndUser(us.name, us?.urlImage, us.isRecentlyActivity)}</TableCell>
                                <TableCell style={{ width: '250px', ...styleTableContent }}>
                                  <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                    {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                      {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.totalEffort, p.effort))}
                                    </div>}
                                    {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                      {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.duration.projects, p.duration))}
                                    </div>}
                                    {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                      {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.durationDaysWorked.projects, p.duration))}
                                    </div>}
                                    <div style={{ paddingLeft: '0.2em' }}>{t("reports.projects.title")}</div>
                                  </TableRow>
                                  <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                    {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.totalEffort, g.elementsByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                    </div>}
                                    {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.duration.elements, g.elementsByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                    </div>}
                                    {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.durationDaysWorked.elements, g.elementsByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                    </div>}
                                    <div style={{ paddingLeft: '0.2em' }}>{t("reports.elements")}</div>
                                  </TableRow>
                                  <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                    {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.totalEffort, ph.phasesByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                    </div>}
                                    {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.duration.phases, ph.phasesByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                    </div>}
                                    {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.durationDaysWorked.phases, ph.phasesByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                    </div>}
                                    <div style={{ paddingLeft: '0.2em' }}>{t("reports.phases")}</div>
                                  </TableRow>
                                  <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                    {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.totalEffort, t.tasksByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                    </div>}
                                    {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.duration.tasks, t.tasksByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                    </div>}
                                    {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                      {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.durationDaysWorked.tasks, t.tasksByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                    </div>}
                                    <div style={{ paddingLeft: '0.2em' }}>{t("reports.tasks")}</div>
                                  </TableRow>
                                </TableCell>
                                <TableCell style={{ ...styleTableContent }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.projects.filter(p => p.idStatus == 2).length}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.projects.map(p => p.elements.filter(e => e.idStatus == 2).length)
                                        .reduce((acc, cur) => acc + cur, 0)}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.projects.map(p => p.elements.map(e => e.phases.filter(ph => ph.idStatus == 2).length)
                                        .reduce((acc, cur) => acc + cur, 0)).reduce((ac, cu) => ac + cu, 0)}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.projects.map(p => p.elements.map(e => e.phases.map(ph => ph.tasks.filter(t => t.idStatus == 2).length)
                                        .reduce((acc, cur) => acc + cur, 0)).reduce((ac, cu) => ac + cu, 0)).reduce((a, c) => a + c, 0)}
                                    </TableRow>
                                  </div>
                                </TableCell>
                                <TableCell style={{ ...styleTableContent }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.finished.projects}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.finished.elements}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.finished.phases}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {us.finished.tasks}
                                    </TableRow>
                                  </div>
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, width: '70px' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {configMagnitud == 1 ? (us.effortFinished.projects / 3600).toFixed(2) :
                                        configMagnitud == 2 ? us.durationFinished.projects : us.durationDaysWorkedFinished.projects}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {configMagnitud == 1 ? (us.effortFinished.elements / 3600).toFixed(2) :
                                        configMagnitud == 2 ? us.durationFinished.elements : us.durationDaysWorkedFinished.elements}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {configMagnitud == 1 ? (us.effortFinished.phases / 3600).toFixed(2) :
                                        configMagnitud == 2 ? us.durationFinished.phases : us.durationDaysWorkedFinished.phases}
                                    </TableRow>
                                    <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                      {configMagnitud == 1 ? (us.effortFinished.tasks / 3600).toFixed(2) :
                                        configMagnitud == 2 ? us.durationFinished.tasks : us.durationDaysWorkedFinished.tasks}
                                    </TableRow>
                                  </div>
                                </TableCell>
                                <TableCell sx={{ width: '210px' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.productivityEffort.projects.min, us.productivityDuration.projects.min,
                                        us.productivityDurationDaysWorked.projects.min)}
                                      {divColumnData(us.productivityEffort.projects.avg, us.productivityDuration.projects.avg,
                                        us.productivityDurationDaysWorked.projects.avg)}
                                      {divColumnData(us.productivityEffort.projects.max, us.productivityDuration.projects.max,
                                        us.productivityDurationDaysWorked.projects.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.productivityEffort.elements.min, us.productivityDuration.elements.min,
                                        us.productivityDurationDaysWorked.elements.min)}
                                      {divColumnData(us.productivityEffort.elements.avg, us.productivityDuration.elements.avg,
                                        us.productivityDurationDaysWorked.elements.avg)}
                                      {divColumnData(us.productivityEffort.elements.max, us.productivityDuration.elements.max,
                                        us.productivityDurationDaysWorked.elements.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.productivityEffort.phases.min, us.productivityDuration.phases.min,
                                        us.productivityDurationDaysWorked.phases.min)}
                                      {divColumnData(us.productivityEffort.phases.avg, us.productivityDuration.phases.avg, us.productivityDurationDaysWorked.phases.avg)}
                                      {divColumnData(us.productivityEffort.phases.max, us.productivityDuration.phases.max,
                                        us.productivityDurationDaysWorked.phases.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.productivityEffort.tasks.min, us.productivityDuration.tasks.min,
                                        us.productivityDurationDaysWorked.tasks.min)}
                                      {divColumnData(us.productivityEffort.tasks.avg, us.productivityDuration.tasks.avg,
                                        us.productivityDurationDaysWorked.tasks.avg)}
                                      {divColumnData(us.productivityEffort.tasks.max, us.productivityDuration.tasks.max,
                                        us.productivityDurationDaysWorked.tasks.max)}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell sx={{ width: '210px', textAlign: 'center' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.PDREffort.projects.min, us.PDRDuration.projects.min,
                                        us.PDRDurationDaysWorked.projects.min)}
                                      {divColumnData(us.PDREffort.projects.avg, us.PDRDuration.projects.avg,
                                        us.PDRDurationDaysWorked.projects.avg)}
                                      {divColumnData(us.PDREffort.projects.max, us.PDRDuration.projects.max,
                                        us.PDRDurationDaysWorked.projects.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.PDREffort.elements.min, us.PDRDuration.elements.min,
                                        us.PDRDurationDaysWorked.elements.min)}
                                      {divColumnData(us.PDREffort.elements.avg, us.PDRDuration.elements.avg,
                                        us.PDRDurationDaysWorked.elements.avg)}
                                      {divColumnData(us.PDREffort.elements.max, us.PDRDuration.elements.max,
                                        us.PDRDurationDaysWorked.elements.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.PDREffort.phases.min, us.PDRDuration.phases.min,
                                        us.PDRDurationDaysWorked.phases.min)}
                                      {divColumnData(us.PDREffort.phases.avg, us.PDRDuration.phases.avg,
                                        us.PDRDurationDaysWorked.phases.avg)}
                                      {divColumnData(us.PDREffort.phases.max, us.PDRDuration.phases.max,
                                        us.PDRDurationDaysWorked.phases.max)}
                                    </div>
                                    <div style={{ ...rowData }}>
                                      {divColumnData(us.PDREffort.tasks.min, us.PDRDuration.tasks.min,
                                        us.PDRDurationDaysWorked.tasks.min)}
                                      {divColumnData(us.PDREffort.tasks.avg, us.PDRDuration.tasks.avg,
                                        us.PDRDurationDaysWorked.tasks.avg)}
                                      {divColumnData(us.PDREffort.tasks.max, us.PDRDuration.tasks.max,
                                        us.PDRDurationDaysWorked.tasks.max)}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, width: '120px' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.projects).toFixed(2)}</div>
                                    <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.elements).toFixed(2)}</div>
                                    <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.phases).toFixed(2)}</div>
                                    <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.tasks).toFixed(2)}</div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Box sx={{
                        display: 'flex', justifyContent: "flex-end",
                        alignItems: "center", marginTop: '1em'
                      }}>
                        <TablePagination
                          labelRowsPerPage={t("elements.pagination.label")}
                          rowsPerPageOptions={[4, 8, 12]}
                          component={"div"}
                          onRowsPerPageChange={handleChangeUsersRowsPerPage}
                          onPageChange={handleChangeUsersPage}
                          count={usersCount}
                          rowsPerPage={rowsPerPageUsers}
                          page={usersPage}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Card>
              }
            </Grid>
          }
          {!showLoader && (indexTab == 1) &&
            <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
              {dataUsersFilter.length == 0 ? 
                <Card sx={{ padding: "8px", width: '100%', height: '10em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>{t("msg.info.empty_response")}</div>
                </Card> :
                <Card sx={{ padding: "8px", width: '100%' }}>
                  <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                    <TableContainer sx={{ overflow: "scroll" }} component={Paper}>
                      <Table responsive={true}>
                        <TableHead>
                          <StyledTableRow>
                            <TrueffortTableHeadCell colSpan={8}></TrueffortTableHeadCell>
                            <TrueffortTableHeadCell colSpan={showTableProductivityDetail ? 5 : 1}
                              style={{ ...styleTableContent, textAlign: "center", width: showTableProductivityDetail ? '350px' : '40px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#D4E3FF' }}>
                                <div style={{ width: showTableProductivityDetail ? '95%' : '50%', justifyContent: 'center' }}>
                                  <AccessTimeFilledIcon fontSize="12" />
                                  {showTableProductivityDetail ? "Tiempo del periodo (hrs)" : ""}
                                </div>
                                <div style={{ width: showTableProductivityDetail ? '5%' : '50%' }}>
                                  <KeyboardArrowRightIcon fontSize="12" style={{ cursor: 'pointer' }}
                                    onClick={() => setShowTableProductivityDetail(!showTableProductivityDetail)}
                                  />
                                </div>
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell colSpan={1}></TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow style={{ background: 'rgba(224, 224, 224, 1)' }}>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("User")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("reports.active_project")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <CropOriginalIcon />
                                {showTableElementDetail ? t("reports.element") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTableElementDetail(!showTableElementDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <AccountTreeIcon />
                                {showTablePhaseDetail ? t("reports.phase") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTablePhaseDetail(!showTablePhaseDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("reports.now_working_on")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <ConstructionIcon />
                                {showTableAppDetail ? t("reports.application") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTableAppDetail(!showTableAppDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, width: '90px' }}>
                                {t("reports.check_in_hour")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                                {t("reports.check_out_hour")}
                            </TrueffortTableHeadCell>
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.in_this_task")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#CFF1AB', width: '75px' }}>
                                {t("reports.productive")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.total")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#FFCDCF', width: '75px' }}>
                                {t("reports.not_productive")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.remaining")}
                            </TrueffortTableHeadCell>}
                            {!showTableProductivityDetail && <TrueffortTableHeadCell style={{ padding: 0, background: '#D4E3FF' }}></TrueffortTableHeadCell>}
                            <TrueffortTableHeadCell>{t("Productivity")}</TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {dataUsersFilter.slice(usersPage * rowsPerPageUsers,
                            usersPage * rowsPerPageUsers + rowsPerPageUsers).map((us, ind) => (
                              <>
                                <TableRow>
                                  <TableCell style={{ width: '230px' }}>{AvatarAndUser(us.name, us?.urlImage, us.isRecentlyActivity)}</TableCell>
                                  <TableCell style={{ width: '200px', backgroundColor: '#DCDCDC' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div style={{ width: '90%' }}>
                                        {us.lastTask?.projectPhase?.projectElement?.project?.name}
                                      </div>
                                      <div style={{ width: '10%' }}>
                                        <KeyboardArrowRightIcon onClick={() => {
                                          let usInd = dataUsersFilter.findIndex((u) => u.idUser == us.idUser);
                                          let usAux = dataUsersFilter.map(u => u);
                                          usAux[usInd].showListProjectDetail = !usAux[usInd].showListProjectDetail;
                                          setDataUsersFilter(usAux);
                                        }} />
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ maxWidth: '100px' }}>{showTableElementDetail ? us.lastTask?.projectPhase?.projectElement?.name : ""}</TableCell>
                                  <TableCell style={{ maxWidth: '100px' }}>{showTablePhaseDetail ? us.lastTask?.projectPhase?.phaseType?.name : ""}</TableCell>
                                  <TableCell style={{ backgroundColor: '#F2E9FF', width: '200px', color: '#553D74', fontWeight: 700 }}>{us?.lastTask?.taskType?.name}</TableCell>
                                  <TableCell>{showTableAppDetail && us.lastTask ? us.lastApplication?.name : ""}</TableCell>
                                  <TableCell>{us.timeEntryFormat}</TableCell>
                                  <TableCell>{us.timeExitFormat}</TableCell>
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.totalSecondsIntoLastTask, false, false, false)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#CFF1AB' }}>
                                      {formatSeconds(us.totalEffortRelevant)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.totalEffort)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#FFCDCF' }}>
                                      {formatSeconds(us.totalEffort - us.totalEffortRelevant)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.workTimeBySchedule != 0 ? us.workTimeBySchedule - us.totalEffort : 0)}
                                    </StyledTableCell>}
                                  {!showTableProductivityDetail && <StyledTableCell />}
                                  <TableCell>{((us.workTimeBySchedule == 0 ? us.totalEffortRelevant / us.totalEffort : us.totalEffortRelevant / us.workTimeBySchedule) * 100).toFixed(0) + '%'}</TableCell>
                                </TableRow>
                                {us.showListProjectDetail && us.projects.map((p, i) =>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{ background: '#EFEFEF' }}>{p.name}</TableCell>
                                    <TableCell colSpan={6} style={{ minWidth: '200px', background: '#EFEFEF' }}>
                                      <div style={{ width: '100%', height: '1.5em', background: '#DCDCDC', borderRadius: '4px', alignItems: 'center' }}>
                                        <div style={{
                                          height: '1.5em', background: COLORS_RELEVANT[i],
                                          width: ((p.effort / us.totalEffort) * 100) + '%', borderRadius: '4px'
                                        }}>
                                          <span style={{ color: 'white', fontWeight: '700' }}>{formatSeconds(p.effort, false, true, true)}</span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(undefined)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#CFF1AB' }}>
                                        {formatSeconds(p.effortRelevant, false, false, false)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(p.effort, false, false, false)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#FFCDCF' }}>
                                        {formatSeconds(p.effort - p.effortRelevant)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(undefined)}</StyledTableCell>}
                                    <TableCell>{((us.workTimeBySchedule == 0 ? p.effortRelevant / us.totalEffort : p.effortRelevant / us.workTimeBySchedule) * 100).toFixed(0) + '%'}</TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Box sx={{
                        display: 'flex', justifyContent: "flex-end",
                        alignItems: "center", marginTop: '1em'
                      }}>
                        <TablePagination
                          labelRowsPerPage={t("elements.pagination.label")}
                          rowsPerPageOptions={[4, 8, 12]}
                          component={"div"}
                          onRowsPerPageChange={handleChangeUsersRowsPerPage}
                          onPageChange={handleChangeUsersPage}
                          count={usersCount}
                          rowsPerPage={rowsPerPageUsers}
                          page={usersPage}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Card>
              }
            </Grid>
          }
          {!showLoader && indexTab == 3 &&
            <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
              {dataWeeklyTable.length == 0 ?
                <Card sx={{
                  padding: "8px", width: '100%', height: '10em', display: 'flex',
                  justifyContent: 'center', alignItems: 'center'
                }}>
                  <div>{t("msg.info.empty_response")}</div>
                </Card> :
                <Card sx={{ padding: "8px", width: '100%' }}>
                  <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                    <Grid item style={{ paddingTop: 0, paddingBottom: '8px' }}>
                      <Checkbox size="small" sx={{ padding: 0 }}
                        onChange={(event) => {
                          setUsePeriods(event.target.checked);
                          if (!event.target.checked) {
                            setPeriodsTab([]);
                          }
                        }}
                        checked={usePeriods}
                      />
                      <span style={{ fontSize: "12px" }}>{t("reports.time_summary.use_periods")}</span>
                      {usePeriods && <IconButton style={{ padding: 0, color: '#07074E' }}>
                        <AddBoxIcon sx={{ width: '0.9em' }}
                          onClick={() => { setOpenDialogAddPeriod(true); }}
                        />
                      </IconButton>}
                      {periods.map((per, ind) => <TrueffortChip
                        key={ind + "_PeriodChip"}
                        label={dateToDateLocale(per.startTime).toISOString().split('T')[1].substring(0, 5) + " - "
                          + dateToDateLocale(per.endTime).toISOString().split('T')[1].substring(0, 5)}
                        size="small"
                        onDelete={() => {
                          let pers = periods.map((p) => p);
                          pers.splice(ind, 1);
                          setPeriodsTab(pers);
                        }}
                      />)}
                    </Grid>
                    <TableContainer sx={{ overflow: "scroll", maxWidth: '1450px !important' }} component={Paper}>
                      <Table responsive="true">
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#DCDCDC !important' }}>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("reports.time_summary.date")}
                            </TrueffortTableHeadCell>
                            {headWeeklyTable.periods.map((per, ind) => (
                              <TableCell sx={{ paddingTop: 0, paddingBottom: 0, background: '#EFEFEF', width: '200px' }}>
                                <TableRow sx={{ width: '200px' }}>
                                  <TableCell colSpan={4} style={{ ...styleTableContent, ...alignText }}>
                                    <div style={{ width: '100%', minHeight: '1.9em' }}>
                                      {ind < (headWeeklyTable.periods.length - 1) ? t("reports.time_summary.period") : ""}
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      {ind == (headWeeklyTable.periods.length - 1) ? t("reports.remaining") : per.periodKey.substring(0, 5) + per.periodKey.substring(8, 16)}
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow sx={{ width: '200px' }}>
                                  <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>{t("reports.time_summary.check_in")}</TableCell>
                                  <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>{t("reports.time_summary.check_out")}</TableCell>
                                  <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>
                                    <div>{t("reports.total")}</div>
                                    <div>{formatSeconds(per.total ? per.total : 0, false, true, false)}</div>
                                  </TableCell>
                                  <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>
                                    <div>{t("reports.time_summary.total_productive")}</div>
                                    <div>{formatSeconds(per.totalProductive ? per.totalProductive : 0, false, true, false)}</div>
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                            ))}
                            {periods.length == 0 && <>
                              <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                                <div>{"Entrada"}</div>
                              </TrueffortTableHeadCell>
                              <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                                <div>{"Salida"}</div>
                              </TrueffortTableHeadCell>
                            </>}
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div>{t("reports.total")}</div>
                              <div>{formatSeconds(headWeeklyTable.total ? headWeeklyTable.total : 0, false, true, false)}</div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div>{t("reports.time_summary.total_productive")}</div>
                              <div>{formatSeconds(headWeeklyTable.totalProductive ? headWeeklyTable.totalProductive : 0, false, true, false)}</div>
                            </TrueffortTableHeadCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataWeeklyTable.map((day, i) => (
                            <>
                              <StyledTableRow style={{ height: '30px' }}>
                                <StyledTableCell style={{
                                  ...rowDataDateWeekly,
                                  paddingLeft: i < (dataWeeklyTable.length - 1) && day.totalTime > 0 ? 0 : '2.3em'
                                }}>
                                  {i < (dataWeeklyTable.length - 1) && day.totalTime > 0 ? <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      let days = dataWeeklyTable.map(d => d);
                                      days[i].showDetail = !days[i].showDetail;
                                      let count = numberLinesShowWeekly + (days[i].showDetail ? days[i].detailByUser.length : -days[i].detailByUser.length);
                                      setNumberLinesShowWeekly(count);
                                      setDataWeeklyTable(days);
                                    }}
                                  >
                                    {day.showDetail ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                  </IconButton> : null}
                                  {day.date}
                                </StyledTableCell>
                                {day.periods.map((per, ind) => (
                                  <StyledTableCell sx={{ paddingTop: 0, paddingBottom: 0, width: '200px', ...alignText }}>
                                    <TableRow sx={{ width: '200px', backgroundColor: 'transparent !important' }}>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{""}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{""}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTime, false, true, false)}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTimeProductive, false, true, false)}</StyledTableCell>
                                    </TableRow>
                                  </StyledTableCell>
                                ))}
                                {periods.length == 0 && <>
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{""}</StyledTableCell>
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{""}</StyledTableCell>
                                </>}
                                <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(day.totalTime, false, true, false)}</StyledTableCell>
                                <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(day.totalTimeProductive, false, true, false)}</StyledTableCell>
                              </StyledTableRow>
                              {i < (dataWeeklyTable.length - 1) && day.showDetail && day.detailByUser.map((us, ind) => (
                                <StyledTableRow style={{ height: '40px' }}>
                                  <StyledTableCell style={{ ...rowDataWeekly, paddingLeft: '0.4em' }}>{AvatarAndUser(us.name.length > 12 ? us.name.substring(0, 12) + "..." : us.name, us.image?.url, null)}</StyledTableCell>
                                  {us.periods.map((per, ind_) => (
                                    <StyledTableCell sx={{ paddingTop: 0, paddingBottom: 0, width: '200px', ...alignText }}>
                                      <StyledTableRow sx={{ width: '200px', backgroundColor: 'transparent !important' }}>
                                        <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{per.checkIn ? formatDateString(per.checkIn, 'HH:mm:ss') : '-'}</StyledTableCell>
                                        <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{per.checkOut ? formatDateString(per.checkOut, 'HH:mm:ss') : '-'}</StyledTableCell>
                                        <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTime, false, true, false)}</StyledTableCell>
                                        <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTimeProductive, false, true, false)}</StyledTableCell>
                                      </StyledTableRow>
                                    </StyledTableCell>
                                  ))}
                                  {periods.length == 0 && <>
                                    <StyledTableCell style={{ ...rowDataWeekly }}>{us.checkIn ? formatDateString(us.checkIn, 'HH:mm:ss') : '-'}</StyledTableCell>
                                    <StyledTableCell style={{ ...rowDataWeekly }}>{us.checkOut ? formatDateString(us.checkOut, 'HH:mm:ss') : '-'}</StyledTableCell>
                                  </>}
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(us.totalTime, false, true, false)}</StyledTableCell>
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(us.totalTimeProductive, false, true, false)}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Box sx={{
                        display: 'flex', justifyContent: "flex-end", alignItems: "center", marginTop: '1em'
                      }}>
                        <TablePagination
                          labelRowsPerPage={t("elements.pagination.label")}
                          rowsPerPageOptions={[10, 20, 30]}
                          component={"div"}
                          onRowsPerPageChange={handleChangeWeeklyRowsPerPage}
                          onPageChange={handleChangeWeeklyPage}
                          count={weeklyCount}
                          rowsPerPage={countPerPageWeekly}
                          page={weeklyPage}
                        />
                      </Box>
                    </div>
                    {showMessageWarningDetailWeekly != "" && periods.length > 0 && idsSelectedUsers.length == 1 &&
                      <div style={{ fontSize: '12px', color: '#E4555A', paddingTop: '0.3em' }}>{showMessageWarningDetailWeekly}</div>}
                  </Grid>
                </Card>
              }
            </Grid>
          }
          {!showLoader && indexTab == 4 &&
            <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5', marginTop: '1em', width: '100%' }}>
              <div>
                {dataBenchmark.data.length > 0 ?
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                      <Card sx={{ padding: "8px", height: '100%' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '25px' }}>
                              <HelperDialog
                                title={t("reports.benchmark.config_efficiency")}
                                body={<div>
                                  <span>{(axisB.id == 1 ? t("reports.benchmark.description_efficiency_IED") : t("reports.benchmark.description_efficiency_IEE")) + t("reports.benchmark.description_example")}</span><br />
                                  <span>{"- "}<strong>{t("reports.benchmark.int_under")}</strong>{t("reports.benchmark.description_under").replace("{0}", valueEfficiency)}</span><br /><br />
                                  <span>{"- "}<strong>{t("reports.benchmark.int_suitable")}</strong>{t("reports.benchmark.description_suitable").replace("{0}", valueEfficiency)}</span><br /><br />
                                  <span>{"- "}<strong>{t("reports.benchmark.int_overhang")}</strong>{t("reports.benchmark.description_overhang").replace("{0}", valueEfficiency)}</span>
                                </div>}
                                dialogIndex={0}
                                totalDialogs={2}
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 0}
                                handleClose={() => setActiveHelperDialogIndex(-1)}
                              >
                                <IconButton onClick={() => setActiveHelperDialogIndex(0)} >
                                  <HelpIcon sx={{ color: '#6875C7' }} />
                                </IconButton>
                              </HelperDialog>
                              <span style={{ fontSize: "12px", paddingRight: '0.9em' }}>{t("reports.benchmark.config_efficiency")}</span>
                              <Box sx={{ width: 100, display:'flex', flexDirection:'row' }}>
                                <Slider
                                  value={valueEfficiency}
                                  onChange={(ev, nv) => setValueEfficiency(nv)}
                                  onChangeCommitted={(event) => {
                                    if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
                                      instanceService.updateEfficiency(user.instance.idInstance, valueEfficiency).then(({ data: response }) => {
                                      }).catch(error => {
                                        console.error("error", error);
                                      });
                                    }
                                  }}
                                  max={50}
                                  valueLabelFormat={(e) => e + "%"}
                                  sx={{ color: '#9A64E2', marginBottom: 0 }}
                                  marks={[ { value: 0, label: '0%' },
                                    { value: 50, label: '50%' }]}
                                  disabled={(user.role?.accessLevel < MIN_LEVEL_ADMIN)}
                                />
                              </Box>
                              <span style={{ fontSize: "12px", paddingLeft: '0.9em' }}>{'[' + valueEfficiency + '%]'}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '25px', paddingLeft: '1.5em' }}>
                              <HelperDialog
                                title={t("reports.benchmark.config_IP")}
                                body={
                                  <div>
                                    <span>{t("reports.benchmark.description_IP")}</span><br />
                                    <span>{"- "}<strong>{t("reports.benchmark.int_under")}</strong>{t("reports.benchmark.description_under_IP").replace("{0}", valueRangeProductivity[0])}</span><br />
                                    <span>{"- "}<strong>{t("reports.benchmark.int_suitable")}</strong>{t("reports.benchmark.description_suitable_IP").replace("{0}", valueRangeProductivity[0]).replace("{1}", valueRangeProductivity[1])}</span><br />
                                    <span>{"- "}<strong>{t("reports.benchmark.int_overhang")}</strong>{t("reports.benchmark.description_overhang_IP").replace("{0}", valueRangeProductivity[1])}</span>
                                  </div>
                                }
                                dialogIndex={1}
                                totalDialogs={2}
                                isOpen={activeHelperDialogIndex == 1}
                                handleClose={() => setActiveHelperDialogIndex(-1)}
                              >
                                <span style={{ fontSize: "12px", paddingRight: '0.9em' }}>{t("reports.benchmark.config_IP")}</span>
                              </HelperDialog>
                              <Box sx={{ width: 150 }}>
                                <Slider
                                  value={valueRangeProductivity}
                                  onChange={(ev, nv) => setValueRangeProductivity(nv)}
                                  onChangeCommitted={(event) => {
                                    if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
                                      instanceService.updateProductivityRange(user.instance.idInstance, {
                                        lowerProductivity: valueRangeProductivity[0], 
                                        upperProductivity: valueRangeProductivity[1]
                                      }).then(({ data: response }) => {
                                      }).catch(error => {
                                        console.error("error", error);
                                      });
                                    }
                                  }}
                                  max={120}
                                  min={50}
                                  valueLabelFormat={(e) => e + "%"}
                                  sx={{ color: '#9A64E2', marginBottom: 0 }}
                                  marks={[ { value: 100, label: '' }, { value: 50, label: '50%' },
                                    { value: 120, label: '120%' }]}
                                  disabled={user.role?.accessLevel < MIN_LEVEL_ADMIN}
                                />
                              </Box>
                              <span style={{ fontSize: "12px", paddingLeft: '0.9em' }}>
                                {'[' + valueRangeProductivity[0] + '% - ' + valueRangeProductivity[1] + '%]'}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Grid item >
                                <TrueffortNavButton
                                  id="idSelectedGraphBenchmark"
                                  size="small"
                                  variant="contained"
                                  onClick={(event) => handleClickMenuBenchmark(event, 1)}
                                  className={""}
                                  fullWidth
                                >
                                  {cutValueFormated(axisB.name, 18)}
                                  <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
                                </TrueffortNavButton>
                              </Grid>
                            </div>
                            <Menu id="idTasksFilterGraphBenchmark"
                              anchorEl={anchorElemFilterGraphBenchmark}
                              open={openOptionsGraphBenchmark}
                              onClose={() => setAnchorElemFilterGraphBenchmark(null)}
                            >
                              {menuNumberGraphBenchmark == 1 && <div>
                                <MenuItem id="axisB_0" key={"0_B"} onClick={() => setAxisB({ id: 1, name: "IED vs IP" })}>
                                  {"IED vs IP"}
                                </MenuItem>
                                <MenuItem id="axisB_1" key={"1_B"} onClick={() => setAxisB({ id: 2, name: "IEE vs IP" })}>
                                  {"IEE vs IP"}
                                </MenuItem>
                              </div>}
                            </Menu>
                          </Grid>
                          <div style={{ width: '100%', height: '90%', paddingTop: '2em' }}>
                            <Grid item xs={10}>
                              <Scatter data={{ datasets: dataBenchmark.data}} options={options} plugins={[plugin]} />
                            </Grid>
                          </div>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Card sx={{ padding: "8px", textAlign: 'justify'}}>
                        <div>
                          <span><strong>{t("reports.benchmark.config_efficiency") + ': '}</strong><br/>
                          {'- ' + t("reports.benchmark.axis_IED") + ': ' + t("reports.benchmark.description_efficiency_IED")}</span><br /><br />
                          <span>{'- ' + t("reports.benchmark.axis_IEE") + ': ' + t("reports.benchmark.description_efficiency_IEE")}</span><br /><br />
                          <span><strong>{t("reports.benchmark.config_IP") + ': '}</strong>{t("reports.benchmark.description_IP")}</span>
                        </div>
                      </Card>
                    </Grid>
                  </Grid> :
                  <CardEmpty> <div>{t("reports.benchmark.empty_response")}</div></CardEmpty>
                }
              </div>
            </Paper>
          }
        </Paper>
        {DialogAddPeriod(handleCloseDialogAddPeriod, openDialogAddPeriod)}
      </div>
    </LocalizationProvider>
  );
}

export default GlobalReportUsers;