import * as React from "react";
import { Grid, Menu, MenuItem, Stack, CircularProgress, Divider} from "@mui/material";
import {KeyboardArrowDown as KeyboardArrowDownIcon, CalendarMonth as CalendarMonthIcon, 
    Dashboard as DashboardIcon, Groups as GroupsIcon} from "@mui/icons-material";
import '../../css/Dashboard.css'
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { cutValueFormated, dateToDateLocale } from "../../util/GlobalFunctions";
import ReportsService from "../../services/reports.service";
import CustomDatePickerInput from "../custom/CustomDatePickerInput";
import UserService from "../../services/user.service";
import { TrueffortNavButton, TrueffortSearchInput } from "../../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import GlobalReportUsers from "./GlobalReportUsers";
import GlobalReportProjects from "./GlobalReportProjects";
import SummaryActivityService from "../../services/summaryActivity.service";
import SelectMulti from 'react-select';
import TaskStateService from "../../services/taskState.service";
import { ID_STATUS_COMPLETED, ID_STATUS_INPROCESS } from "../../util/Constants";

const GlobalReportTabs = ({user, setLastUserActivity, allProjects, allUsers, report}) => {
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [inputSearch, setInputSearch] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState({});
  const [dataUsers, setDataUsers] = React.useState([]);
  const [dataByWeekly, setDataByWeekly] = React.useState([]);
  const [dataByBenchmark, setDataByBenchmark] = React.useState({data: [], userList: [], activities:[]});
  const [nameSelectedDays, setNameSelectedDays] = React.useState(t("dashboard.today"));
  const [valuesSelectedProjects, setValuesSelectedProjects] = React.useState([]);
  const [valuesSelectedUsers, setValuesSelectedUsers] = React.useState( allUsers.length == 1 ? 
    allUsers.map((us) => ({ value: us.idUser, label: us.name + ' ' + us.lastName } )) : []);
  const [idSelectedProject, setIdSelectedProject] = React.useState("");
  const [selectedProject, setSelectedProject] = React.useState({});
  const [idSelectedUser, setIdSelectedUser] = React.useState("");
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [idsSelectedUsers, setIdsSelectedUsers] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const reportsService = React.useMemo(() => new ReportsService(), []);
  const userService = React.useMemo(() => new UserService(), []);
  const summaryActivityService = React.useMemo(() => new SummaryActivityService(), []);
  const [users, setUsers] = React.useState(allUsers);
  const [indexTab, setIndexTab] = React.useState(0);
  const [indexTabProject, setIndexTabProject] = React.useState(0);
  const [countDatesWeekly, setCountDatesWeekly] = React.useState(1);
  const [pageWeeklyTab, setPageWeeklyTab] = React.useState(0);
  const [countPerPageWeekly, setCountPerPageWeekly] = React.useState(10);
  const [periodsTab, setPeriodsTab] = React.useState([]);
  const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = React.useState(0);
  const [projectsReport, setProjectsReport] = React.useState([]);
  const taskStateService = React.useMemo(() => new TaskStateService(), []);
  const [taskStates, setTaskStates] = React.useState([]);
  const [valuesStatusSelectFilter, setValuesStatusSelectFilter] = React.useState([{value: ID_STATUS_INPROCESS, label: "En Proceso"}]);

  React.useEffect(() => {
    setUsers(allUsers);
    setValuesSelectedUsers(allUsers.length == 1 ? 
      allUsers.map((us) => ({ value: us.idUser, label: us.name + ' ' + us.lastName } )) : []);
  }, [allUsers]);

  React.useEffect(() => {
    let ed = new Date();
    setEndDate(ed);
    if (report == "projects") {
      let d = new Date();
      d.setMonth(d.getMonth() - 6);
      d.setDate(1);
      setStartDate(d);
      setIndexTabProject(0);
      fillTaskStates();
    }/* else if (report == "users") {
      setStartDate(new Date());
    }*/
  }, [report])

  React.useEffect(() => {    
    let count = endDate.getDate() - startDate.getDate();
    switch(count) {
      case 0:
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 5:
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 15:
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 30:
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
      default:
        setNameSelectedDays(t("dashboard.customized"));
        break;
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    setShowLoader(true);
    if (report == "projects") {
      if (indexTabProject == 0) {
        fillProjectsReportsService(startDate, endDate);
      } else if (indexTabProject == 1) {
        fillProjectsInProgressReport();
      }
    }
  }, [startDate, endDate, idSelectedUser, idSelectedProject, indexTabProject]);

  React.useEffect(() => {
    setShowLoader(true);
    if (report == "users" && indexTab == 4) {
      reportsService.findGlobalBenchmark(dateToDateLocale(startDate).toISOString().slice(0,10), 
        dateToDateLocale(endDate).toISOString().slice(0,10),'', idSelectedProject).then(({data:resBench}) => {
        let currentDate = dateToDateLocale(new Date()).toISOString();
        summaryActivityService.find(dateToDateLocale(startDate).toISOString().slice(0,10), 
          dateToDateLocale(endDate).toISOString().slice(0,10), currentDate.slice(0, 10), 
          '', '', idSelectedProject).then(({data: respData}) => {
          if (!respData.success || respData?.data.length == 0) {
            console.error("No existen datos para realizar los calculos");
            setShowLoader(false);
            return;
          }

          if (!resBench.success) {
            console.error("Ocurrio un error al obtener la información");
            setShowLoader(false)
            return;
          }

          setDataByBenchmark({data: idSelectedProject == '' ? resBench.data.projectTaskList : 
            resBench.data.projectTaskList.filter((pt) => pt.projectPhase.projectElement.project.idProject == idSelectedProject),
            userList: resBench.data.userList, activities: respData.data});
        }).catch((err) => {
          console.error(err);
        }).finally(() => setShowLoader(false));
      }).catch((er) => {
        console.error(er);
        setShowLoader(false);
      });
    }
  }, [startDate, endDate, report, idSelectedProject, indexTab]);

  React.useEffect(() => {
    setShowLoader(true);
    if (report == "users" && (indexTab == 0 || indexTab == 1) ) {
      fillSummaryUsersService();
      let today = new Date();
      let end = new Date(endDate != null ? endDate : new Date());
      today.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      if (today.getTime() == end.getTime()) {
        const id = setInterval(() => fillSummaryUsersService(), 30000);
        return () => clearInterval(id);
      }
    }
  }, [startDate, endDate, idSelectedProject, report, idSelectedUser, indexTab]);

  React.useEffect(() => {
    if (report == "users" && indexTab == 3) {
      setShowLoader(true);
      fillDataByWeeklyService();
      setCountDatesWeekly(Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
    }
  }, [startDate, endDate, report, indexTab, idsSelectedUsers, periodsTab, idSelectedProject, pageWeeklyTab, countPerPageWeekly]);

  const fillTaskStates = () => {
    taskStateService.getAll().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        setTaskStates(response.data);
      }
    });
  }
  
  const fillProjectsReportsService = async (sd, ed) => {
    reportsService.findGlobalProjects("", "", 
      sd ? sd.toISOString().substring(0, 10) : "", ed ? ed.toISOString().substring(0,10) : "").then(({data: response}) => {
      //console.log(response);
      if (response.success) {
        setProjectsReport(response.data);
        setShowLoader(false);
      } else {
        console.error(response);
      }
    }).catch((ex) => {
      console.error(ex);
    }).finally(() => setShowLoader(false));
  };

  const fillProjectsInProgressReport = async () => {
    //console.log("fillProjectsInProgressReport");
    reportsService.globalProjectsStatus().then(({data : response}) => {
      if (response.success) {
        let data = response.data.map((p) => {
          let missingDuration = p.unfinishedTask.reduce((acc, cur) => acc + cur.estimatedDays, 0);
          let missingEffort = p.unfinishedTask.reduce((acc, cur) => acc + cur.estimatedTime, 0);
          let estFinishDate = new Date(p.estimatedFinishDate);
          estFinishDate.setDate(estFinishDate.getDate() + 1);
          estFinishDate.setHours(0);
          let currentDate = new Date();
          currentDate.setHours(0);
          let statusByDuration = true;
          let itemsTaskOrderByProjectedDate = p.unfinishedTask.sort((a,b) => new Date(b.projectedDate).getTime() - new Date(a.projectedDate).getTime());
          let projectedDate = null;
          if (itemsTaskOrderByProjectedDate.length > 0) {
            let pd = new Date(itemsTaskOrderByProjectedDate[0].projectedDate);
            pd.setDate(pd.getDate() + 1);
            projectedDate = getOnlyDateWithoutTime(pd);
          } else {
            projectedDate = getOnlyDateWithoutTime(currentDate);
          }
          if (p.status.idStatus === ID_STATUS_COMPLETED) {
            let finishDate = new Date(p.finishDate);
            finishDate.setDate(finishDate.getDate() + 1);
            statusByDuration = finishDate.getTime() <= estFinishDate.getTime();
            projectedDate = getOnlyDateWithoutTime(finishDate);
          } else {
            if (currentDate.getTime() >= estFinishDate.getTime()) {
              statusByDuration = false;
            } else {
              statusByDuration = projectedDate.getTime() <= estFinishDate.getTime();
            }
          }

          let statusByEffort = true;
          if (p.estimatedTime > p.dedicatedTime) {
            statusByEffort = false;
          } else {
            let ED = (p.estimatedTime - p.dedicatedTime) - missingEffort;
            statusByEffort = ED >= 0;
          }
          
          return {...p, missingDuration, missingEffort, statusByDuration, projectedDate, statusByEffort};
        });
        setProjectsReport(data);
      } else {
        console.error(response);
      }
    }).catch((ex) => console.error(ex)).finally(() => setShowLoader(false));
  }

  const getOnlyDateWithoutTime = (date) => {
    let date_ = new Date(date);
    date_.setHours(0);
    date_.setMinutes(0);
    date_.setSeconds(0);
    date_.setMilliseconds(0);

    return date_;
  }

  const fillDataByWeeklyService = async () => {
    reportsService.findGlobalUsersWeekly(pageWeeklyTab + 1, countPerPageWeekly, dateToDateLocale(startDate).toISOString().slice(0,10), 
      dateToDateLocale(endDate).toISOString().slice(0,10), idsSelectedUsers.length == 1 ? idsSelectedUsers[0].idUser : "", 
      idSelectedProject, periodsTab.map((p) => {return {
        startTime: dateToDateLocale(p.startTime).toISOString(), endTime: dateToDateLocale(p.endTime).toISOString()};}))
      .then(({data: response}) => {
      if (response.success) {
        setDataByWeekly(response.data);
        setShowLoader(false);
      } else {
        console.error(response);
      }
    }).catch((ex) => {
      console.error(ex);
    }).finally(() => setShowLoader(false));
  };

  const fillSummaryUsersService = async () => {
    //console.log("fillSummaryUsersService")
    let start = dateToDateLocale(startDate != null ? startDate : new Date()).toISOString();
    let end = dateToDateLocale(endDate != null ? endDate : new Date()).toISOString();
    reportsService.findGlobalUsers(start.slice(0, 10), end.slice(0, 10), idSelectedUser, idSelectedProject)
    .then(({data: response}) => {
      if (response.success) {
        setDataUsers(response.data);
      }
    }).catch(({data: response}) => console.error(response))
    .finally(() => setShowLoader(false));

    // CONSULTAR LA ÚLTIMA ACTIVIDAD DEL USUARIO
    userService.getLastActivity().then(({data: response}) => {
      setLastUserActivity(response);
    }).catch(({data: response}) => {
      console.error("Error getting last activity");
    });
  };

  const handleChangeUser = (id) => {
    setAnchorElemFilter(null);
    setIdSelectedUser(id);
    setSelectedUser(users.find(us => us.idUser == id));
  }
  
  const handleChangeUsers = (id) => {
    //setIdSelectedUser(id);
    setAnchorElemFilter(null);
    let us = users.find(us => us.idUser == id);
    let aux = selectedUsers.map(u => u);
    aux.push(us);
    setSelectedUsers(aux);
  }

  const handleChangeProject = (id) => {
    //console.log("handleChangeProject");
    setAnchorElemFilter(null);
    setIdSelectedProject(id);
    setSelectedProject(id == "" || id == "-1" ? {} : allProjects.find(p => p.idProject == id));
  }

  const handleChangeDays = (id) => {
    setAnchorElemFilter(null);
    let start = new Date();
    switch (id) {
      case 1:
        setStartDate(new Date());
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 2:
        start.setDate(start.getDate() - 5)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 3:
        start.setDate(start.getDate() - 15)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 4:
        start.setDate(start.getDate() - 30)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
    }
    if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const handleChangeIndexTab = (event, val) => {
    setIndexTab(val);
    setIdSelectedUser("");
    setIdsSelectedUsers([]);
  };

  const handleChangeIndexTabProject = (event, val) => {
    setIndexTabProject(val);
  };

  const handleChangeUsersGraph = (id) => {
    let ids = idsSelectedUsers.map(i => i);
    let index = ids.findIndex(u => u.idUser == id);
    setAnchorElemFilter(null);
    if (index == -1) {
      let us = users.find(us => us.idUser == id);
      let aux = idsSelectedUsers.map(u => u);
      aux.push(us);
      setIdsSelectedUsers(aux);
    } else {
      ids.splice(index, 1);
      setIdsSelectedUsers(ids);
    }
  }

  return (
    <Grid>
      <Grid container spacing={0.5} sx={{paddingTop: '0.5em'}}  >
        {(report == "users" || indexTabProject == 1) && <Grid item xl={4} md={3} xs={12} >
          <TrueffortSearchInput
            id="idInputSearchReports"
            placeholder={t("search")}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Grid>}
        {report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedDays"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 1)}
            className={nameSelectedDays == t("dashboard.customized") ? "" : "active"}
            fullWidth
            //noneHeight={nameSelectedDays.length > 5}
          >
            <CustomIconTrueffortScreen icon={<CalendarMonthIcon />} />
              {cutValueFormated(nameSelectedDays, 11)}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {(report == "users" || indexTabProject == 0) && <Grid item>
          <div id="idStartDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              maxDate={endDate}
              onChange={date => {
                if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
                setStartDate(date);
              }}
              customInput={<CustomDatePickerInput label={t("start_date")} />}
            />
          </div>
        </Grid>}
        {(report == "users" || indexTabProject == 0) && <Grid item>
          <div id="idEndDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              minDate={startDate}
              onChange={date => {
                if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
                setEndDate(date);
              }}
              customInput={<CustomDatePickerInput label={t("end_date")} />}
            />
          </div>
        </Grid>}
        {[0,1].includes(indexTab) && report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedUser"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 3)}
            className={idSelectedUser != "" ? "active" : ""}
            noneHeight={false}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<GroupsIcon />} />
            {idSelectedUser != "" ? cutValueFormated(selectedUser.name + " " + selectedUser.lastName, 16) : t("dashboard.all_users")}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {[2,3,4].includes(indexTab) && <Grid item >
          <TrueffortNavButton
            id="idsSelectedUsers"
            size="small"
            variant="contained"
            onClick={(event) => {
              if (report == "users" && indexTab == 3) { setPageWeeklyTab(0); }
              handleClickMenu(event, 2);
            }}
            className={idsSelectedUsers.length != 0 ? "active" : ""}
            noneHeight={false}
          >
            <CustomIconTrueffortScreen icon={<GroupsIcon />} />
              {idsSelectedUsers.length == 0 ? t("dashboard.all_users") : idsSelectedUsers.map((u, i) => u.name + (i < (idsSelectedUsers.length-1) ? ", " : ""))}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {report == "projects" && indexTabProject == 0 && <Grid item xs={2} sm={2}>
          <SelectMulti
            name="selectUsers"
            isMulti
            placeholder={t("User")}
            value={valuesSelectedUsers}
            onChange={(option) => setValuesSelectedUsers(option)}
            options={
              users.map((us) => (
                {
                  value: us.idUser,
                  label: us.name + ' ' + us.lastName
                }
              ))
            }
          />
        </Grid>}
        {report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedProject"
            size="small"
            variant="contained"
            onClick={(event) => {
              if (report == "users" && indexTab == 3) { setPageWeeklyTab(0); }
              handleClickMenu(event, 4);
            }}
            className={idSelectedProject != "" ?"active" : ""}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<DashboardIcon />} />
              {idSelectedProject != "" ? idSelectedProject != "-1" ? cutValueFormated(selectedProject.name, 18): t("project_page.without_project") : t("dashboard.all_projects")}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {report == "projects" && indexTabProject == 1 && 
          <Grid item xs={2} md={2}>
            <SelectMulti
              isMulti
              label={t("home.status")}
              placeholder={t("project_page.status_activity")}
              value={valuesStatusSelectFilter}
              options={
                  taskStates.map((s, k) => {
                  return ({
                    value: s.idStatus,
                    label: s.name
                  })
                })
              }
              onChange={(option) => {
                setValuesStatusSelectFilter(option);
              }}
            />
        </Grid>}
        {report == "projects" && <Grid item xs={2} sm={2}>
          <SelectMulti
            name="selectProjects"
            isMulti
            placeholder={t("reports.projects.title")}
            value={valuesSelectedProjects}
            onChange={(option) => setValuesSelectedProjects(option)}
            options={
              allProjects.map((pro) => (
                { value: pro.idProject, label: pro.name }
              ))
            }
          />
        </Grid>}
      </Grid>
      <Menu id="idElementsFilterDashboards"
        anchorEl={anchorElemFilter}
        open={openOptions}
        onClose={() => setAnchorElemFilter(null)}
      >
        {menuNumber == 1 && <div>
          <MenuItem id="idDays_0" key={"_1"} onClick={() => handleChangeDays(1)}>
            {t("dashboard.today")}
          </MenuItem>
          <MenuItem id="idDays_1" key={"_5"} onClick={() => handleChangeDays(2)}>
            {t("dashboard.last_5_days")}
          </MenuItem>
          <MenuItem id="idDays_2" key={"_15"} onClick={() => handleChangeDays(3)}>
            {t("dashboard.last_15_days")}
          </MenuItem>
          <MenuItem id="idDays_3" key={"_30"} onClick={() => handleChangeDays(4)}>
            {t("dashboard.last_30_days")}
          </MenuItem>
        </div>}
        {menuNumber == 2 && <div>
          <MenuItem id="idUsersGraph_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setIdsSelectedUsers([])}>
            {t("dashboard.all_users")}
          </MenuItem>
          <Divider />
          {users.map((u, i) => (
            <MenuItem id={"idUsersGraph_"+(i+1)} key={i} 
              //disabled={idsSelectedUsers.findIndex(us => us.idUser == u.idUser) != -1} 
              sx={{fontSize: ".8rem!important", background: idsSelectedUsers.findIndex(us => us.idUser == u.idUser) != -1 ? '#F5F5F5' : '#FFFFFF'}} 
              onClick={() => handleChangeUsersGraph(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 3 && <div>
          <MenuItem id="idUser_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUser("")}>
            {t("dashboard.all_users")}
          </MenuItem>
          <Divider />
          {users.map((u, i) => (
            <MenuItem id={"idUser_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUser(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 4 && <div>
          <MenuItem id="idProject_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject("")}>
            {t("dashboard.all_projects")}
          </MenuItem>
          <Divider />
          {allProjects.map((p, i) => (
            <MenuItem id={"idProject_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject(p.idProject)}>
              {p.name}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 5 && <div>
          <MenuItem id="idUsers_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setSelectedUsers([])}>
            {t("dashboard.all_users")}
          </MenuItem>
          {users.map((u, i) => (
            <MenuItem id={"idUsers_"+(i+1)} key={i} disabled={selectedUsers.findIndex(us => us.idUser == u.idUser) != -1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUsers(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
      </Menu>
      <div sx={{ padding: '8px', marginTop:'0.5em' }}>
        {showLoader &&
          <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
            <CircularProgress />
          </Stack>
        }
      </div>
      {(features.userProductivityReportItemsPerformed || features.worksheetsUsers || 
        features.reportBenchmark) && !showLoader && report == "users" && 
        <GlobalReportUsers 
          startDate={startDate}
          endDate={endDate}
          idSelectedUser={idSelectedUser}
          idsSelectedUsers={idsSelectedUsers}
          idSelectedProject={idSelectedProject}
          dataUsers={dataUsers}
          dataByWeekly={dataByWeekly}
          inputSearch={inputSearch}
          indexTab={indexTab}
          handleChangeIndexTab={handleChangeIndexTab}
          countDatesWeekly={countDatesWeekly}
          pageWeeklyTab={pageWeeklyTab}
          setPageWeeklyTab={setPageWeeklyTab}
          dataByBenchmark={dataByBenchmark}
          users={allUsers}
          periods={periodsTab}
          setPeriodsTab={setPeriodsTab}
          countPerPageWeekly={countPerPageWeekly}
          setCountPerPageWeekly={setCountPerPageWeekly}
        />
      }
      {(features.globalReportProjectsStatus || features.reportProjectsEvaluation) && 
        !showLoader && report == "projects" && 
        <GlobalReportProjects 
          valuesSelectedUsers={valuesSelectedUsers}
          valuesSelectedProjects={valuesSelectedProjects}
          projectsReport={projectsReport}
          inputSearch={inputSearch}
          indexTab={indexTabProject}
          handleChangeIndexTab={handleChangeIndexTabProject}
          valuesStatusSelect={valuesStatusSelectFilter}
        />
      }
    </Grid>
  );
}

export default GlobalReportTabs;