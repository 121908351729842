import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, TablePagination } from "@mui/material";
import DashboardCategoryAppTrue from "../DashboardCategoryAppTRUE";
import { useTranslation } from "react-i18next";
import { formatSeconds } from "../../util/GlobalFunctions";
import { ArrowRight } from "@mui/icons-material";

function DetailCategoriesAndApps({id, isProductive, user, startDate, 
    endDate, idProfile, idSelectedProject, listProfiles, listCategories, defaultShowMore, users, _showCatList}) {
    //console.log(user);
    const {t} = useTranslation();
    let ROWS_PER_PAGE = 3;
    const [rowsPerPageCategoryApps, setRowsPerPageCategoryApps] = React.useState(ROWS_PER_PAGE);
    const [categoryAppsPage, setCategoryAppsPage] = React.useState(0);
    const [categoryAppsCount, setCategoryAppsCount] = React.useState(user.categoriesRelevant.length);//Cantidad de categorias
    const [rowsPerPageIrrelevantCategoryApps, setRowsPerPageIrrelevantCategoryApps] = React.useState(ROWS_PER_PAGE);
    const [irrelevantCategoryAppsPage, setIrrelevantCategoryAppsPage] = React.useState(0);
    const [irrelevantCategoryAppsCount, setIrrelevantCategoryAppsCount] = React.useState(user.categoriesNotRelevant.length);//Cantidad de categorias

    React.useEffect(() => {
        setCategoryAppsCount(user.categoriesRelevant.length);
        setIrrelevantCategoryAppsCount(user.categoriesNotRelevant.length);
    }, [user])
    const handleChangeCategoryAppsRowsPerPage = (event) => {
        setRowsPerPageCategoryApps(parseInt(event.target.value, 10));
        setCategoryAppsPage(0);
    }
    
    const handleChangeCategoryAppsPage = (event, newPage) => {
        setCategoryAppsPage(newPage);
    }

    const handleChangeIrrelevantCategoryAppsRowsPerPage = (event) => {
        setRowsPerPageIrrelevantCategoryApps(parseInt(event.target.value, 10));
        setIrrelevantCategoryAppsPage(0);
    }
    
    const handleChangeIrrelevantCategoryAppsPage = (event, newPage) => {
        setIrrelevantCategoryAppsPage(newPage);
    }

    return (
        <Card sx={{padding:'0.3em', width:'100%', margin:'0.2em', height: ((user.showMoreProductive && isProductive) || (user.showMoreNotProductive && !isProductive) || defaultShowMore) ? '150px' : 'none',
            background: isProductive ? "rgba(207, 241, 171, 0.25)" : "rgba(255, 205, 207, 0.25)", overflow: "auto"}}>
            {isProductive && <div style={{color: "#45661E", display:'flex', justifyContent:'space-between', fontSize:'14px'}}>
                <span id={id+"_titleProductive"} style={{width:'140px'}} onClick={_showCatList}>
                    <FontAwesomeIcon icon="circle-check" color="#78B737" className="mr-1"/>
                    <u style={{fontWeight:'bold'}}>{t("dashboard_timeline.productive")}</u>
                </span>
                <span onClick={_showCatList}><ArrowRight /></span>
                <span onClick={_showCatList}>{formatSeconds(user.productiveTime, false, false, true)}</span></div>}
            {!isProductive && <div style={{color:"#7E363A", display:'flex', justifyContent:'space-between'}}>
                <span id={id+"_titleNotProductive"} style={{width:'140px'}} onClick={_showCatList}>
                    <FontAwesomeIcon icon="circle-xmark" color="#7E363A" className="mr-1"/>
                    <u style={{fontWeight:'bold'}}>{t("dashboard_timeline.non_productive")}</u>
                </span>
                <span onClick={_showCatList}><ArrowRight /></span>
                <span onClick={_showCatList}>{formatSeconds(user.nonProductiveTime, false, false, true)}</span></div>}
            {(user.showMoreProductive || defaultShowMore) && isProductive && <div>
                <div className={"max-heigth-to-category"}>
                {user.categoriesRelevant.slice(categoryAppsPage * rowsPerPageCategoryApps, categoryAppsPage * rowsPerPageCategoryApps + rowsPerPageCategoryApps).map((cat, k) =>
                    <div id={id + "_parent"} className="list-category-parent">
                        <DashboardCategoryAppTrue
                            id={id+"_category"}
                            category={cat}
                            startDate={startDate}
                            endDate={endDate}
                            idUser={user.idUser}
                            idProfile={idProfile}
                            idSelectedProject={idSelectedProject}
                            totalSeconds={user.productiveTime}
                            users={users}
                            listProfiles={listProfiles}
                            listCategories={listCategories}
                            className={"div-app-windows-clean"}
                            isLarge={false}
                        />
                    </div>
                )}</div>
                <TablePagination
                    id={id+"_pagination"}
                    rowsPerPageOptions={[]}
                    component="div"
                    onRowsPerPageChange={handleChangeCategoryAppsRowsPerPage}
                    onPageChange={handleChangeCategoryAppsPage}
                    count={categoryAppsCount}
                    rowsPerPage={rowsPerPageCategoryApps}
                    page={categoryAppsPage}
                    sx={{"& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar":{minHeight: 'none'}}}
                />
                </div>
            }
            {(user.showMoreNotProductive || defaultShowMore) && !isProductive && <div>
                <div className={"max-heigth-to-category"}>
                {user.categoriesNotRelevant.slice(irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps, irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps + rowsPerPageIrrelevantCategoryApps).map((cat, k) =>
                    <div id={id+"_parent"} className="list-category-parent irrelevant">
                        <DashboardCategoryAppTrue
                            id={id+"_category"}
                            category={cat}
                            startDate={startDate}
                            endDate={endDate}
                            idUser={user.idUser}
                            idProfile={idProfile}
                            idSelectedProject={idSelectedProject}
                            totalSeconds={user.productiveTime}
                            users={[]}
                            listProfiles={listProfiles}
                            listCategories={listCategories}
                            className={"div-app-windows-clean"}
                            isLarge={false}
                        />
                    </div>
                )}</div>
                <TablePagination
                    id={id+"_pagination"}
                    rowsPerPageOptions={[]}
                    component="div"
                    onRowsPerPageChange={handleChangeIrrelevantCategoryAppsRowsPerPage}
                    onPageChange={handleChangeIrrelevantCategoryAppsPage}
                    count={irrelevantCategoryAppsCount}
                    rowsPerPage={rowsPerPageIrrelevantCategoryApps}
                    page={irrelevantCategoryAppsPage}
                />
                </div>
            }
        </Card>
    );
}

export default DetailCategoriesAndApps;
