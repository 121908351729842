import {
  Grid, Card,
  Box,
  Typography,
  IconButton,
  Avatar
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardByList from "../custom/CardByList";
import CustomAvatar from "../custom/CustomAvatar";
import { formatDateString } from "../../util/DateDataFormat";
import CardsItemsStatistics from "./CardsItemsStatistics";
import { ResponsiveBar } from "@nivo/bar";
import {
  ExpandMore as ExpandMoreICon, ExpandLess as ExpandLessIcon, ArrowDropDown as ArrowDropDownIcon,
ArrowRight as ArrowRightIcon} from "@mui/icons-material";
import { ID_STATUS_COMPLETED, ID_STATUS_CREATED } from "../../util/Constants";
import CardTaskDetail from "./CardTaskDelay";

function ProjectsReportByStatusTab ({ project }) {
  
  const { t } = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const [showMoreListDelay, setShowMoreListDelay] = React.useState(false);
  const [showMoreListCreated, setShowMoreListCreated] = React.useState(false);


  const getGraphicComparative = (title, axisTitle, keys, data) => {
    return (
      <div style={{ height: '200px', width:'50%', paddingTop:'0.5em'}}>
        <div style={{ width:'100%', textAlign:'center', margin: '0 2rem', fontSize: '1rem', fontWeight:'bold'}}>
          {title}
        </div>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={'key'}
          layout="horizontal"
          margin={{ top: 5, right: 130, bottom: 50, left: 60 }}
          padding={0.1}
          valueScale={{ type: "linear" }}
          colors={["#E56033","#054279"]}
          colorBy="index"
          animate={true}
          enableLabel={true}
          theme={{labels: {text:{fontSize:'16px'}}}}
          labelTextColor={'white'}
          enableGridX={true}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5, tickPadding: 5,
            tickRotation: 0, legend: axisTitle,
            legendPosition: "middle", legendOffset: 30
          }}
          
        />
      </div>
    );
  }

  const getStatus = (status, type, text) => {
    return (
      <div style={{display:'flex'}}>
        <Avatar sx={{ bgcolor: status ? '#48be25' : '#be2525', width: '30px', height:'30px'}} >{""}</Avatar>
        <span style={{marginLeft: '0.5em'}}><span style={{fontWeight:'bold'}}>{type + ": "}</span> {text}</span>
      </div>)
  }

  const getCardTaskList = (title, showMore_, setShowMore_, listTask) => {
    return (
      <Card style={{padding:'0.5em'}}>
        <div style={{paddingBottom:'0.5em'}}>
          <Typography sx={{ fontSize: '0.9rem', fontWeight: '700' }} >
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowMore_(!showMore_)}
            >
                {showMore_ ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            {title}
          </Typography>
        </div>
        {showMore_ && listTask.map((t) => (<CardTaskDetail task={t} />))}
      </Card>
    );
  }

  return (
    <CardByList>
      <Grid xs={12} container spacing={1}>
        <Grid item xs={12} style={{display:'flex', alignItems:'center', paddingBottom:'1em'}}>
            <CustomAvatar code={project.code}></CustomAvatar>
            <h5 style={{paddingLeft: '1em', margin: 0}}>{project.name}</h5>
        </Grid>
        <Grid item xs={2}>
          <div style={{paddingBottom:'1em'}}>{t("reports.projects.created_date")}</div>
          <span>{formatDateString(project.created, 'yyyy-MM-dd')}</span>
        </Grid>
        <Grid item xs={2}>
          <div style={{paddingBottom:'1em'}}>{t("reports.projects.start_date")}</div>
          <span>{project.startDate ? formatDateString(project.startDate, 'yyyy-MM-dd') : "-"}</span>
        </Grid>
        <Grid item xs={2}>
          <div style={{paddingBottom:'1em'}}>{t("reports.projects.estimated_finish_date")}</div>
          <span>{project.estimatedFinishDate ? formatDateString(project.estimatedFinishDate, 'yyyy-MM-dd') : "-"}</span>
        </Grid>
        <Grid item xs={6}>
          {project.projectedDate && getStatus(project.statusByDuration, t("reports.projects.type_duration"), project.statusByDuration ? 
            t("reports.projects.healthy_project_status_per_duration").replace("{0}", t("days." + project.projectedDate.getDay()))
              .replace("{1}", project.projectedDate.getDate()).replace("{2}", t("months." + (project.projectedDate.getMonth() + 1)))
              .replace("{3}", project.projectedDate.getFullYear()) 
            : t("reports.projects.risk_project_status_per_duration").replace("{0}", t("days." + project.projectedDate.getDay()))
              .replace("{1}", project.projectedDate.getDate()).replace("{2}", t("months." + (project.projectedDate.getMonth() + 1)))
                .replace("{3}", project.projectedDate.getFullYear()) 
            )}
          {getStatus(project.statusByEffort, t("reports.projects.type_effort"), project.statusByEffort ? t("reports.projects.healthy_project_status_per_effort") 
            : t("reports.projects.risk_project_status_per_effort"))}
        </Grid>
        {project?.status?.idStatus === ID_STATUS_COMPLETED &&
        <Grid item xs={2}>
          <div style={{paddingBottom:'1em'}}>{t("reports.projects.end_date")}</div>
          <span>{project.finishDate ? formatDateString(project.finishDate, 'yyyy-MM-dd') : "-"}</span>
        </Grid>}
      </Grid>
      {showMore && <>
        <Grid item xs={12} style={{padding:'0.3em'}}>
          <CardsItemsStatistics
            statistics={project.statistics} />
        </Grid>
        <Grid item xs={12} style={{height: '260px', padding:'0.3em'}}>
          <Card style={{height:'16em', display:'flex', flexDirection:'row'}}>
              {getGraphicComparative(t("reports.duration"), t("estimation_page.days_duration"), ["duration"], [{duration: project.estimatedDuration, key:"Estimada"}, {duration: project.realDuration, key:"Real"}])}
              {getGraphicComparative(t("reports.effort"), t("estimation_page.effort_man_hours"), ["hours"], [{hours: project.estimatedTime ? project.estimatedTime.toFixed(2) : 0, key:"Estimada"}, {hours: project.dedicatedTime ? project.dedicatedTime.toFixed(2) : 0, key:"Real"}])}
          </Card>
        </Grid>
        <Grid item xs={12} style={{padding: '0.3em'}}>
          {project.taskWithDelay.length > 0 && 
            getCardTaskList(t("reports.projects.task_delay_title").replace("{0}", project.taskWithDelay.length), 
            showMoreListDelay, setShowMoreListDelay, project.taskWithDelay)}
        </Grid>
        <Grid item xs={12} style={{padding: '0.3em'}}>
          {project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED).length > 0 && 
            getCardTaskList(t("reports.projects.task_in_creation_title").replace("{0}", project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED).length), 
              showMoreListCreated, setShowMoreListCreated, project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED)
          )}
        </Grid>
      </>}
      <Grid item xs={12} style={{paddingLeft:'10px', paddingTop:0}}>
        <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem", marginTop: "0.75rem"}} 
          onClick={() => setShowMore(!showMore)}>
          {!showMore && <><ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}</>}
          {showMore && <><ExpandLessIcon sx={{fontSize: ".9rem"}} /> {t("show_less")}</>}
        </Box>
      </Grid>
    </CardByList>
  );
}

export default ProjectsReportByStatusTab;