import React from "react";
import { Card, Container, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import ProductivityCard from "./ProductivityCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bar } from "react-chartjs-2";
import TableProductivityCard from "./TableProductivityCard";
import "../../css/Productivity.css";
import { Groups2, ImportExport, AutoGraph as AutoGraphIcon } from "@mui/icons-material";
import { formatSeconds } from "../../util/GlobalFunctions";
import { COLORS_RELEVANT, TIME_FORMAT } from "../../util/Constants";
import CardEmpty from "../custom/CardEmpty";
import { formatDateString } from "../../util/DateDataFormat";
import { ResponsiveLine } from '@nivo/line';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const DashboardProductivity = ({ idSelectedProject, recordsTimeline, showLoader,
  startDate, endDate, selectedUsers, componentDownloadExcel, listCategories, listProfiles, idProfile,
  range, users }) => {
  //console.log(users);
  //console.log(recordsTimeline);
  const { t } = useTranslation();
  const colorsGraphic = { workedOverload: "#64A8EC", productive: "#78B737", nonProductive: "#E4555A" };
  const [recordsTimelineAux, setRecordsTimelineAux] = React.useState([]);
  const [recordsProductivityBar, setRecordsProductivityBar] = React.useState([]);
  const [objectBar, setObjectBar] = React.useState({});
  const [workOverload, setWorkOverload] = React.useState(0);
  const [healthyWork, setHealthyWork] = React.useState(0);
  const [underUtilization, setUnderUtilization] = React.useState(0);
  const [dimensions, setDimensions] = React.useState({
    height: document.documentElement.clientHeight,
    width: document.documentElement.clientWidth
  });
  const [recordsTimeLineWithAverage, setRecordsTimeLineWithAverage] = React.useState([]);
  const [dataLineMedias, setDataLineMedias] = React.useState({
    avg: {fullNameShort: 'Promedio', dataLineAvg: [] }, min: {fullNameShort: 'Minimo', dataLineAvg: [] },
    max: {fullNameShort: 'Maximo', dataLineAvg: [] }})
  const [dataLineAvg, setDataLineAvg] = React.useState({fullNameShort:'Promedio', dataLine: []});
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({ height: document.documentElement.clientHeight, width: document.documentElement.clientWidth });
    }, 100)
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  });

  React.useEffect(() => {
    let listAux = [];
    if (selectedUsers.length > 0) {
      recordsTimeline.forEach(u => {
        let exist = selectedUsers.find(us => us.idUser == u.idUser);
        if (exist) {
          listAux.push({...u, dataLine:constructDataComparative(u), showMoreProductive: false, 
            showMoreNotProductive: false});
        }
      });
    } else {
      listAux = recordsTimeline.map(u => {
        return {...u, dataLine:constructDataComparative(u), showMoreProductive: false, 
        showMoreNotProductive: false}}
      );
    }

    
    //let dataLineAve;
    let dataLineProm = [];
    if (listAux.length > 0) {
      for (let l of listAux[0].dataLine.map((d) => d.label)) {
        let sum = 0;
        listAux.forEach((us) => {
          let obj_ = us.dataLine.find((dl) => dl.label == l);
          sum += obj_.value;
        });
        let avg = sum / listAux.length;
        dataLineProm.push({label: l, value: avg});
      }
    }
    let objDataLine = { fullName:'Promedio', fullNameShort: 'Promedio', dataLine: dataLineProm};
    let dataLineMin = [];
    if (listAux.length > 0) {
      for (let l of listAux[0].dataLine.map((d) => d.label)) {
        let mins = [];
        listAux.forEach((us) => {
          let obj_ = us.dataLine.find((dl) => dl.label == l);
          mins.push(obj_.value);
        });
        mins = mins.sort((a,b) => a - b);
        dataLineMin.push({label: l, value: mins[0]});
      }
    }
    let objDataLineMin = { fullNameShort: 'Minimo', dataLine: dataLineMin};
    let dataLineMax = [];
    if (listAux.length > 0) {
      for (let l of listAux[0].dataLine.map((d) => d.label)) {
        let maxs = [];
        listAux.forEach((us) => {
          let obj_ = us.dataLine.find((dl) => dl.label == l);
          maxs.push(obj_.value);
        });
        maxs = maxs.sort((a,b) => b - a);
        dataLineMax.push({label: l, value: maxs[0]});
      }
    }
    let objDataLineMax = { fullNameShort: 'Maximo', dataLine: dataLineMax};
    setDataLineMedias({avg: objDataLine, min: objDataLineMin, max: objDataLineMax});
    setDataLineAvg(objDataLine);
    
    setRecordsTimelineAux(listAux.sort((a, b) => {
      if ((a.productiveTime / a.totalTime) < (b.productiveTime / b.totalTime)) {
        return 1;
      } else if ((a.productiveTime / a.totalTime) > (b.productiveTime / b.totalTime)) {
        return -1;
      }
      return 0;
    }));
    //console.log("recordsTimelineAux", listAux);

    let objBar = {};
    let lBar = [];
    let dBar = [];
    let cBar = [];
    let tBar = [];
    let recordsWithSchedule = [];
    listAux.forEach(e => {
      if (e.scheduleRegister !== null && e.scheduleRegister !== 0) {
        recordsWithSchedule.push(e);
      }
    });

    recordsWithSchedule.sort((a, b) => a.productiveTime < b.productiveTime ? 1 : -1);
    setRecordsProductivityBar(recordsWithSchedule);
    //console.log(recordsWithSchedule);
    recordsWithSchedule.forEach(e => {
      //console.log(e);
      lBar.push(e.fullNameShort)
      let val = ((e.productiveTime / e.scheduleRegister) * 100).toFixed(1);
      dBar.push(val);
      if (val > range[1]) {
        cBar.push(colorsGraphic.workedOverload);
        tBar.push(1);
      } else if (val < range[0]) {
        cBar.push(colorsGraphic.nonProductive);
        tBar.push(3);
      } else {
        cBar.push(colorsGraphic.productive);
        tBar.push(2);
      }
    });
    objBar['labels'] = lBar;
    objBar['data'] = dBar;
    objBar['colors'] = cBar;
    objBar['type'] = tBar;
    let data = {
      labels: objBar.labels,
      datasets: [
        {
          label: '',
          data: objBar.data,
          backgroundColor: objBar.colors,
          borderWidth: 1,
          borderRadius: 4
        },
      ],
    };
    objBar['dataBarObj'] = data;
    setDataCardsBar(objBar, recordsWithSchedule);
    setObjectBar(objBar);
    let listCurrent = [createObjectAverage(listAux)];
    //console.log(listCurrent);
    setRecordsTimeLineWithAverage(listCurrent);
    //console.log(listCurrent);
  }, [recordsTimeline, selectedUsers, range]);

  function setDataCardsBar(objDataAux, recordsWithScheduleAux) {
    //console.log(objDataAux);
    let suma = 0, count = 0, name = "";
    for (let i = 0; i < objDataAux.type.length; i++) {
      if (objDataAux.type[i] === 3) {
        name = objDataAux.labels[i];
        let ind = recordsWithScheduleAux.findIndex(x => x.fullNameShort === name);
        let subs = recordsWithScheduleAux[ind].scheduleRegister - recordsWithScheduleAux[i].productiveTime;
        subs = Math.abs(subs);
        suma += subs;
        count++;
      }
    }
    if (count !== 0) {
      setUnderUtilization(formatSeconds(suma / count, false, false, true));
    } else {
      setUnderUtilization(formatSeconds(suma / count, false, false, true));
    }

    suma = 0;
    for (let i = 0; i < objDataAux.labels.length; i++) {
      name = objDataAux.labels[i];
      let ind = recordsWithScheduleAux.findIndex(x => x.fullNameShort === name);
      let dateAux = new Date(startDate);
      let countDaysHealty = 0;
      let countDays = 0;
      while (dateAux <= endDate) {
        let seconds = 0;
        let filterDayInit = new Date(dateAux);
        filterDayInit.setHours(0, 0, 0, 0);
        let filterDayFinal = new Date(dateAux);
        filterDayFinal.setHours(23, 59, 59, 999);
        let applicationes = recordsWithScheduleAux[ind].listReelevantApplications;
        applicationes.forEach(e => {
          e.activities.forEach(ele => {
            let startActivity = new Date(ele.startTime);
            startActivity = new Date(startActivity.getTime() + startActivity.getTimezoneOffset() * 60000);
            let total = ele.totalSeconds;
            if (startActivity >= filterDayInit && startActivity <= filterDayFinal) {
              seconds += total;
            }
          });
        });
        if (recordsWithScheduleAux[ind].timeObligatoryByDay[dateAux.getDay()] !== 0) {
          let average = (seconds / recordsWithScheduleAux[ind].timeObligatoryByDay[dateAux.getDay()]) * 100;
          if (average >= range[0] && average <= range[1]) {
            countDaysHealty++;
          }
          countDays++;
        }

        dateAux.setDate(dateAux.getDate() + 1);
      }
      suma += countDaysHealty / countDays;
    }

    if (objDataAux.labels.length !== 0) {
      let res = Math.abs(suma / objDataAux.labels.length);
      setHealthyWork(res != 0 ? res.toFixed(3) : res);
    } else {
      setHealthyWork(0);
    }

    suma = 0;
    count = 0;
    for (let j = 0; j < objDataAux.type.length; j++) {
      if (objDataAux.type[j] === 1) {
        name = objDataAux.labels[j];
        let ind = recordsWithScheduleAux.findIndex(x => x.fullNameShort === name);
        let subs = recordsWithScheduleAux[ind].scheduleRegister - recordsWithScheduleAux[j].productiveTime;
        subs = Math.abs(subs);
        suma += subs;
        count++;
      }
    }
    if (count !== 0) {
      setWorkOverload(formatSeconds(suma/count, false, false, true));
    } else {
      setWorkOverload(formatSeconds(0, false, false, true));
    }
  }

  function constructDataComparative (usr) {
    //console.log(usr);
    let datas = [];
    let dateAux = new Date(startDate);
    dateAux.setHours(0, 0, 0, 0);
    let dateEndAux = new Date(endDate);
    dateEndAux.setHours(0, 0, 0, 0);
    let dayDiff = (dateEndAux.getTime() - dateAux.getTime()) / 86400000;
    let intervalDays = dayDiff <= 30 ? 1 : dayDiff <= 180 ? 6 : 29;
    while (dateAux <= endDate) {
      let seconds = 0;
      let filterDayInit = new Date(dateAux);
      filterDayInit.setHours(0, 0, 0, 0);
      let filterDayFinal = new Date(dateAux);
      filterDayFinal.setHours(23, 59, 59, 999);
      if(intervalDays != 1) {filterDayFinal.setDate(filterDayFinal.getDate() + intervalDays);}
      let applicationes = usr.listReelevantApplications;
      applicationes.forEach(e => {
        e.activities.forEach(ele => {
          let startActivity = new Date(ele.startTime);
          startActivity = new Date(startActivity.getTime() + startActivity.getTimezoneOffset() * 60000);
          if (startActivity >= filterDayInit && startActivity <= filterDayFinal) {
            seconds += ele.totalSeconds;
          }
        });
      });
      let totalTimeBySchedule = 0;
      let filterDayInitAux = new Date(filterDayInit);
      while (filterDayInitAux < filterDayFinal) {
        if (usr.timeObligatoryByDay[filterDayInitAux.getDay()] !== 0) {
          totalTimeBySchedule += usr.timeObligatoryByDay[filterDayInitAux.getDay()];
        }
        filterDayInitAux.setDate(filterDayInitAux.getDate() + 1);
      }
      let average = totalTimeBySchedule == 0 ? 0 : (seconds / totalTimeBySchedule) * 100;
      filterDayFinal.setHours(0,0,0,0);
      datas.push({label: formatDateString(filterDayInit, 'MM-dd', t) + (intervalDays == 1 ? "" : ">" + formatDateString(filterDayFinal, 'MM-dd', t)), 
        value: Math.round(average)});
      dateAux.setDate(dateAux.getDate() + (intervalDays != 1 ? intervalDays : 0) + 1);
    }
    //console.log(datas);
    return datas;
  }

  function createObjectAverage(recordsTimeline) {
    let obj = {
      idUser: 0, img: undefined, fullName:'Promedio',
      fullNameShort:'Promedio', name: "Promedio",
      nonProductiveTime: 0,
      productiveTime: 0,
      scheduleRegister: null,
      timeRegistred: 0,
      totalTime: 0,
      totalTimeRegistred: 0,
      unrecordedTime: null,
      categoriesRelevant: [],
      categoriesNotRelevant: []
    };
    if (recordsTimeline.length > 0) {
      let sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.productiveTime;
      });
      let average = Math.ceil(sum / recordsTimeline.length);
      obj.productiveTime = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.nonProductiveTime;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.nonProductiveTime = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.unrecordedTime;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.unrecordedTime = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.timeRegistred;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.timeRegistred = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.totalTime;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.totalTime = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.totalTimeRegistred;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.totalTimeRegistred = average;

      sum = 0;
      recordsTimeline.forEach(e => {
        sum += e.scheduleRegister;
      });
      average = Math.ceil(sum / recordsTimeline.length);
      obj.scheduleRegister = average;
    }

    return obj;
  }

  //console.log(objectBar.dataBarObj);
  const options = {
    animation: false,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1
      }
    },
    //barPercentage: 1,
    //categoryPercentage: 1,
    barThickness: (objectBar.dataBarObj && objectBar.dataBarObj.labels.length > 0) ? ((dimensions.width * 0.3) / 3) / objectBar.dataBarObj.labels.length : 10,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const getDataGraphicUser = (users) => {
    let data2 = users.map((us, ind) => {
      let datas = [];
      us.dataLine.forEach((dl, ind) => {
        if (dl.value == 0) {
          let lb = dl.label;
          let differenceZero = false;
          for (let i = 0; i < users.length; i++) {
            let us_ = users[i];
            if (us_.idUser != us.idUser) {
              let lb_ = us_.dataLine.find(dl_ => dl_.label == lb);
              differenceZero = lb_.value != 0;
              if (differenceZero) { break; }
            }
          }
          if (differenceZero) {
            datas.push({x:dl.label, y: dl.value});
          }
        } else {
          datas.push({x:dl.label, y: dl.value});
        }
      })
      //us.dataLine.map((dl) => {return {x:dl.label, y:dl.value}})
      return {id: us.fullNameShort, color: COLORS_RELEVANT[ind], data: datas}
    });
    //console.log(data2);

    return data2;
  }

  const joinUsersAndAvg = () => {
    let a = recordsTimelineAux.map(u => u);
    a.push(dataLineMedias.avg);
    return a;
  }

  const configToolTip = (point, limitLeft, limitRight) => {
    return (
      <div
        style={{
          background: 'white',
          padding: '6px 6px 6px 6px',
          border: '1px solid #ccc',
          marginLeft: point.x < limitLeft ? '10em' : 0,
          marginRight: point.x > limitRight ? '10em' : 0,
          display:'flex',
          flexDirection:'row',
          alignItems: 'center'
        }}
      >
        <div style={{width:'10px', height:'10px', backgroundColor: point.serieColor, marginRight:'0.3em'}}></div>
        <div >x: <span style={{fontWeight:'bold'}}>{point.data.xFormatted}</span> 
        &nbsp;y: <span style={{fontWeight:'bold'}}>{point.data.yFormatted}</span></div>
      </div>
    );
  } 

  const getResponsiveLine = (data) => {
    return (<ResponsiveLine
      data={data}
      margin={{ top: 50, right: 100, bottom: 70, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 0, max: 'auto',
        stacked: false, reverse: false
      }}
      colors={{ scheme: 'category10' }}
      yFormat=" >-.2f"
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      axisBottom={{tickRotation: -45}}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      pointSize={3}
      useMesh={true}
      legends={[{ anchor: 'top-right', direction: 'column', justify: false,
        translateX: 70, translateY: -20, itemsSpacing: 0, itemDirection: 'left-to-right',
        itemWidth: 80, itemHeight: 15, itemOpacity: 0.75, symbolSize: 12,
        symbolShape: 'circle', symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [ { on: 'hover', style: { 
          itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 } } ]
      }]}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('dashboard.productivity')+ ' (%)',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
      }}
      tooltip={({ point }) => {
        return (
          <>
            {configToolTip(point, 50, 1000)}
          </>
        )
      }} 
    />);
  }

  return (
    <div>
      {!showLoader && recordsProductivityBar.length > 0 &&
        <Paper elevation={0} rounded sx={{ padding: "8px", marginTop: '1em', background: "#EFEFEF", overflow: "unset" }}>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} justifyContent='space-between' >
                <Typography variant='h5' color='#07074E' >
                  {t("Overview")}
                </Typography>
                <div>{componentDownloadExcel}</div>
              </Stack>
            </Grid>
            {!showLoader && recordsProductivityBar.length > 0 &&
              <Grid id={"tableProductivityAverage"} key={"tlp"} xl={12} md={12} xs={12}>
                <TableProductivityCard
                  id="tableProductivityAverage"
                  recordsTimeline={recordsTimeLineWithAverage}
                  idUser={""}
                  showLoader={false}
                  acceptClick={false}
                  showAll={true}
                />
              </Grid>
            }
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: '0.5em' }} >
              <Grid id='dataAverages' item xl={6} lg={6} md={6} sm={12} xs={6} >
                <Grid item xs={12} >
                  <p id={"subTitleAverage"} className="sub-title section"><ImportExport />{t("dashboard_productivity.averages")}</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container style={{display:'flex', padding:'0'}}>
                    <Grid item xs={12} sm={4} md={4} style={{padding:0}}>
                      <Card sx={{ height: '100%' }} >
                        <div id={"dataCardWorkOverload"} className={"div-data-card"}>
                          <Grid>
                            <span id={"dataCardWorkOverloadTitle"} className="prod-text"
                              style={{ width: "100%" }}>
                              {t("dashboard_productivity.work_overload_card")}
                            </span>
                          </Grid>
                            <Grid>
                              <div style={{ width: "100%" }}>
                                <span id={"dataCardWorkOverloadValue"} className="prod-title-card">
                                  {workOverload}
                                </span>
                                {localStorage.getItem(TIME_FORMAT) == 'dec' && <span className="prod-text">
                                  {" " + t("dashboard_productivity.hrs")}
                                </span>}
                              </div>
                            </Grid>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}  style={{padding:0}}>
                      <Card sx={{ height: '100%' }} >
                        <div id={"dataCardHealthyWork"} className={"div-data-card"}>
                          <Grid>
                            <span id={"dataCardHealthyWorkTitle"} className="prod-text"
                              style={{ width: "100%" }}>
                              {t("dashboard_productivity.healthy_work_card")}
                            </span>
                          </Grid>
                          <Grid>
                            <div style={{ width: "100%" }}>
                              <span id={"dataCardHealthyValue"} className="prod-title-card" >
                                {healthyWork}
                              </span>
                              <span className="prod-text">
                                {" " + t("dashboard_productivity.days")}
                              </span>
                            </div>
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}  style={{padding:0}}>
                      <Card sx={{ height: '100%' }} >
                        <div id={"dataCardUnderWork"} className={"div-data-card"}>
                          <Grid>
                            <span id={"dataCardUnderWorkTitle"} className="prod-text"
                              style={{ width: "100%" }}>
                              {t("dashboard_productivity.under_work_card")}
                            </span>
                          </Grid>
                          <Grid>
                            <div style={{ width: "100%" }}>
                              <span id={"dataCardUnderWorkValue"} className="prod-title-card">
                                {underUtilization}
                              </span>
                              {localStorage.getItem(TIME_FORMAT) == 'dec' && <span className="prod-text">
                                {" " + t("dashboard_productivity.hrs")}
                              </span>}
                            </div>
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  </Container>
                </Grid>
                <Grid item xs={12} >
                  <p id={"subTitleProductivity"} className="sub-title section" style={{ marginTop: '0.5em' }}>
                    <FontAwesomeIcon icon="chart-area" className="mr-1" />{t("dashboard_productivity.title_graphic_bar")}
                  </p>
                </Grid>
                <Grid item xs={12} >
                  <Card style={{ padding: '0.5em' }}>
                    <Grid id={"idChartProductivity"} container item xs={12}>
                      <Bar data={objectBar.dataBarObj} options={options} />
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              <Grid id="tableProductivityUsers" item xl={6} lg={6} md={6} sm={12} xs={6}>
              <Grid item xs={12} >
                  <p id={"subTitleUsers"} className="sub-title section"><Groups2 />{t("Users.title")}</p>
                </Grid>
                <Grid item xs={12} >
                  <TableProductivityCard
                    id="tableProductivityUser"
                    recordsTimeline={recordsTimelineAux}
                    idUser={""}
                    showLoader={false}
                    acceptClick={false}
                    showAll={false}
                    height={"448px"}
                    objDataLineMedias={dataLineMedias}
                    getDataGraphicUser={getDataGraphicUser}
                    configToolTip={configToolTip}
                  />
                </Grid>
              </Grid>
              <Grid id='graphicAveragesAndUsers' item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} >
                  <p id={"subTitleAverage"} className="sub-title section">
                    <AutoGraphIcon />{t("dashboard_productivity.title_graphic_productivity_compartive")}</p>
                </Grid>
                <Card style={{marginTop:'0.5em', padding:'0.5em', width:'100%'}}>
                  <div style={{ height: "370px", width:'98%', marginBottom: "1em"}}>
                    {getResponsiveLine(getDataGraphicUser(joinUsersAndAvg()))}
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      {!showLoader && 
      <Grid>
        <Card sx={{ padding: "8px", marginTop: '1em', background: "#EFEFEF", overflow: "unset" }}>
          <div style={{ paddingTop: "10px" }}></div>
          {recordsTimelineAux.length == 0 &&
            <Grid>  
              <CardEmpty> <div>{t("msg.info.empty_response")}</div></CardEmpty>
            </Grid>
          }
          {recordsTimelineAux.length > 0 &&
            <div>
              <p className="sub-title">{t("dashboard_productivity.productivity_users")}</p>
              <Card sx={{padding:'0.5em'}}>
                <Grid container xs={12} spacing={1}>
                  {recordsTimelineAux.map((user, k) => {
                    return (
                      <Grid key={"prodcol" + k} item xl={3} md={3} xs={12}>
                        <ProductivityCard key={"prodc" + k}
                          id={"cardUser_"+k}
                          idSelectedProject={idSelectedProject}
                          user={user}
                          startDate={startDate}
                          endDate={endDate}
                          idProfile={idProfile}
                          users={users}
                          range={range}
                          colorsGraphic={colorsGraphic}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Card>
            </div>
          }
        </Card>
      </Grid>
      }
    </div>
  );
};

export default DashboardProductivity;