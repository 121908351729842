import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class SummaryActivityService {

    find(startDate: string, endDate: string, currentDate: string, idUser: number, idProfile: number, idProject: number) {
      return http?.get(baseURL.readApi.summaryActivities + "/find?startDate="
        + startDate + "&endDate=" + endDate + "&currentDate=" + currentDate + "&idUser=" + idUser
        + "&idProfile=" + idProfile  + "&idProject=" + idProject);
    }

    findDetailByUser(startDate: string, endDate: string, currentDate: string, idUser: number, idProject: number) {
        return http?.get(baseURL.readApi.summaryActivities + "/findDetailByUser?startDate="
            + startDate + "&endDate=" + endDate + "&currentDate=" + currentDate + "&idUser=" + idUser + "&idProject=" + idProject);
    }
}

export default SummaryActivityService;