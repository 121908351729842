import {arrMeetingApps, arrMeetingWindows, PROJECT_ID_KEY, PROJECT_KEY} from "./Constants";
import {WHITOUT_CATEGORY_NAME} from "../util/Constants";
import { User } from "../models/user";
import { formatSeconds } from "./GlobalFunctions";
import { formatDateString } from "./DateDataFormat";
/**
 * ARchivo para concentrar aquí los procesamientos de arreglos de activities para las diferentes gráficas.
 */

//const REG_EXP_MEETING_APPS = new RegExp(arrMeetingApps.join("|"), "i");
const REG_EXP_MEETING_WINDOWS = new RegExp(arrMeetingWindows.join("|"), "i");

/**
 * Procesa la lista completa de actividades para mostrarla en la gráfica del dashboard general.
 * @param listAllActivities Arreglo de todas las activities tal cual vienen de BD.
 */
export const processActivitiesForDashboardGeneral = (
    listAllActivities: any, listCategories: any, listProfiles: any, idSelectedCategory: string,
    idSelectedProfile: string, idSelectedUser: string, inputSearch: string, listUsers:any) => {
    let listUsersAux = listUsers.map((u:any) => u.idUser);
    if (listCategories.length == 0) { return {categories: [], applications: []}; }
    // Separar los registros AFK
    // @ts-ignore
    let arrActvities = listAllActivities.slice();
    let catUncategoryzed = listCategories.find((c:any) => c.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
    /* Filtrar por categorías, si aplica */
    if (idSelectedCategory != "") {
        if (idSelectedCategory != "0") {
            let selectedCat = listCategories.find((el: { idCategory: string; }) => el.idCategory == idSelectedCategory);
            if (Array.isArray(selectedCat.applications)) {
                arrActvities = arrActvities.filter((data: any) => {
                    let findAppInCat = selectedCat.applications.find((el: { idApplication: any; }) => el.idApplication == data.idApplication);
                    if (typeof findAppInCat != "undefined") {
                        return data;
                    }
                });
            }
        } else {
            // SIN CATEGORÍA
            arrActvities = arrActvities.filter((data: any) => {
                let existsInCat = false;
                // por cada actividad recorrer todas las categorías para obtener sólo las que cuya aplicación no pertenezca a ninguna
                listCategories.map((c: any, i: number) => {
                    if (Array.isArray(c.applications)) {
                        let findAppInCat = c.applications.find((el: { idApplication: any; }) => el.idApplication == data.idApplication);
                        if (typeof findAppInCat != "undefined") {
                            existsInCat = true;
                            return;
                        }
                    }
                });
                if (!existsInCat) {
                    return data;
                }
            });
        }
    }
    //console.log(listAllActivities);

    /* FILTRAR por equipo, si aplica */
    /* FILTRAR por perfil, si aplica (SE APLICA LO MISMO) */
    if (idSelectedProfile != "") {
        // Se tiene que obtener el tiempo, sólo de las actividades de los usuarios que tengan ese perfil.
        // Entonces en realidad se filtra por los usuarios de ese perfil.
        arrActvities = arrActvities.filter((data:any) => listUsersAux.includes(data.idUser));
        /*arrActvities = arrActvities.filter((data: any) => {
            let findInUsr = listProfiles.find((el: { idUser: any; }) => el.idUser == data.idUser);
            if (typeof findInUsr != "undefined") {
                return data;
            }
        });*/
    }

    /*if (idSelectedTeam != "") {
        arrActvities = arrActvities.filter((data: any) => {
            let findInUsr = users.find((el: { idUser: any; }) => el.idUser == data.idUser);
            if (typeof findInUsr != "undefined") {
                return data;
            }
        });
    }*/

    /* FILTRAR por usuario, si aplica */
    if (idSelectedUser != "") {
        arrActvities = arrActvities.filter((data: any) => data.idUser == idSelectedUser);
    }

    /* FILTRAR por nombre de aplicación (campo de búsqueda), si aplica */
    let filter = inputSearch;
    if (filter != "") {
        arrActvities = arrActvities.filter((data: any) => data.applicationName.toLowerCase().includes(filter.toLowerCase()));
    }

    // Agrupar por categoria para sumar los tiempos
    let arrGroupedCategory:any = {};
    for (let {idApplication,  ...fields} of arrActvities) {
        let cat = listCategories.find((c:any) => c.applications.find((ap:any) => ap.idApplication == idApplication));
        if (cat == undefined) { cat = catUncategoryzed; }
        // @ts-ignore
        if (!arrGroupedCategory[cat.idCategory]) arrGroupedCategory[cat.idCategory] = [];
        // @ts-ignore
        arrGroupedCategory[cat.idCategory].push({
            ...fields,
            "idApplication": idApplication
        });
    }
    //console.log(arrGroupedCategory);

    let applicationsByCategory:any = [];
    for (let x of Object.keys(arrGroupedCategory)) {
        let cat = listCategories.find((c:any) => c.idCategory == x);
        applicationsByCategory.push({idCategory: x, categoryName: cat.name, image:cat.image, activities: arrGroupedCategory[x]})
    }
    //console.log(applicationsByCategory);

    // Agrupar por aplicación para sumar los tiempos
    for (let i = 0; i < applicationsByCategory.length; i++) {
        //console.log(applicationsByCategory[i]);
        let listApps = [];
        let arrGroupedByApplication:any = {};
        for (let {idApplication, applicationName, applicationIconUrl, applicationIdImage, ...fields} of applicationsByCategory[i].activities) {
            //console.log(idApplication);
            //console.log(fields);
            // @ts-ignore
            if (!arrGroupedByApplication[applicationName]) arrGroupedByApplication[applicationName] = [];

            arrGroupedByApplication[applicationName].push({
                ...fields,
                "idApplication": idApplication,
                "iconUrl": applicationIconUrl,
                "idImage": applicationIdImage
            });
        }
        //console.log(arrGroupedByApplication);
        for (let g  of Object.keys(arrGroupedByApplication)) {
            listApps.push({idApplication: arrGroupedByApplication[g][0].idApplication, name: g, activities: arrGroupedByApplication[g] });
        }
        //console.log(listApps);
        applicationsByCategory[i]["applications"] = listApps;
        applicationsByCategory[i]["activities"] = null;
    }
    //console.log(applicationsByCategory);

    let arrFinalCategorias: {
            idCategory: number, name:string, applications:[{ idApplication: number; name: string; iconUrl: string; idImage: number; totalSeconds: number }],
            totalSeconds:number, isRelevant:boolean
    }[] = [];
    applicationsByCategory.map(function (cat:any) {
        //console.log(cat);
        let secondCategory = 0;
        let secondsCategoryNotRelevant = 0;
        let applications:any = [];
        let applicationsNotRelevant:any = [];

        cat.applications.forEach((app:any, ind:number) => {
            //console.log(app);
            let isRelevant = false;
            let relevantByProfile = false;
            let idApp = app.idApplication;
            let seconds = 0;
            let secondsIrrelevant = 0;
            let iconUrl = "";
            let idImage = 0;

            if (idSelectedUser == "") {
                if (idSelectedProfile != "") {
                    listProfiles.map((prof: any, p: number) => {
                        if (prof.idProfile == idSelectedProfile) {
                            if (Array.isArray(prof.applications)) {
                                prof.applications.map((app: any, a: number) => {
                                    if (app.idApplication == idApp) {
                                        isRelevant = true;
                                        relevantByProfile = true;
                                    }
                                });
                            }
                        }
                    });
                } else {
                    for (let i = 0; i< listProfiles.length; i++) {
                        if (Array.isArray(listProfiles[i].applications)) {
                            for (let j = 0; j < listProfiles[i].applications.length; j++) {
                                if (listProfiles[i].applications[j].idApplication == idApp) {
                                    isRelevant = true;
                                    relevantByProfile = true;
                                    break;
                                }
                            }
                            if (isRelevant) break;
                        }
                    }
                }
            } else {
                let selectedUsr = listUsers.find((el: { idUser: string; }) => el.idUser == idSelectedUser);   
                if (idSelectedProfile == "") {
                    for (let i = 0; i < selectedUsr.profiles.length; i++) {
                        let prof = listProfiles.find((p:any) => p.idProfile == selectedUsr.profiles[i].idProfile);
                        if (Array.isArray(prof.applications)) {
                            prof.applications.map((app: any, a: number) => {
                                if (app.idApplication == idApp) {
                                    isRelevant = true;
                                    relevantByProfile = true;
                                }
                            });
                        }
                    }
                } else {
                    let prof = listProfiles.find((p:any) => p.idProfile == idSelectedProfile);
                    let profUser = selectedUsr.profiles.find((p:any) => p.idProfile == idSelectedProfile);
                    if (prof && profUser) {
                        if (Array.isArray(prof.applications)) {
                            prof.applications.map((app: any, a: number) => {
                                if (app.idApplication == idApp) {
                                    isRelevant = true;
                                    relevantByProfile = true;
                                }
                            });
                        }
                    }
                }
            }
            
            
            if (!isRelevant) {
                if (idSelectedUser != "") {
                    // Determinar relevancia por usuario
                    let selectedUsr = listUsers.find((el: { idUser: string; }) => el.idUser == idSelectedUser);
                    //console.log(selectedUsr)
                    if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantApplications != "undefined" && selectedUsr.relevantApplications != null) {
                        let thisApp = selectedUsr.relevantApplications.find((ap: { idApplication: number; }) => ap.idApplication == idApp);
                        if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                            isRelevant = thisApp.isRelevant;
                        }
                    }
                } else {
                    for (let i = 0; i < listUsers.length; i++) {
                        let selUsr = listUsers[i];
                        let acts = app.activities.filter((ac:any) => ac.idUser == selUsr.idUser);
                        /*if(idApp == 1745) {
                          console.log("user ", selUsr.idUser);
                          console.log(acts);
                        }*/
                        if (acts.length > 0) {
                            if (typeof selUsr !== "undefined" && typeof selUsr.relevantApplications != "undefined" && selUsr.relevantApplications != null) {
                                let thisApp = selUsr.relevantApplications.find((ap: { idApplication: number; }) => ap.idApplication == idApp);
                                if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                                    isRelevant = thisApp.isRelevant;
                                    if (isRelevant) break;
                                }
                            }
                        }
                    }
                }
                //console.log(isRelevant);
            }
            

            // Recorrer los registros de la misma aplicación para determinar el tiempo
            app.activities.map((act: any, j: number) => {
                if (act.totalSeconds > 0) {
                    if (isRelevant) {
                        seconds += act.totalSeconds;
                        secondCategory += act.totalSeconds;
                    } else {
                        secondsIrrelevant += act.totalSeconds;
                        secondsCategoryNotRelevant += act.totalSeconds;
                    }

                    //idApp = act.idApplication;
                    iconUrl = act.iconUrl;
                    idImage = act.idImage;

                    // QUITAR TIEMPO AFK
                    let dt = new Date(act.startTime);
                    dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
                    let dtEnd = new Date(dt);
                    dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);
                }
            });

            if (isRelevant) {
                applications.push({
                    application: {
                        idApplication: idApp,
                        name: app.name,
                        iconUrl: iconUrl,
                        idImage: idImage
                    },
                    totalSeconds: seconds,
                    isRelevant: isRelevant,
                    relevantByProfile
                });
            } else {
                applicationsNotRelevant.push({
                    application: {
                        idApplication: idApp,
                        name: app.name,
                        iconUrl: iconUrl,
                        idImage: idImage
                    },
                    totalSeconds: secondsIrrelevant,
                    isRelevant: isRelevant,
                    relevantByProfile
                });
            }
        });
        //console.log(applications);
        if (applications.length > 0) {
            let obj:any = {
                idCategory: cat.idCategory, categoryName: cat.categoryName,image:cat.image, applications: applications,
                totalSeconds: secondCategory, isRelevantCategory: true
            }
            arrFinalCategorias.push(obj);
        }

        if (applicationsNotRelevant.length > 0) {
            let obj2:any = {
                idCategory: cat.idCategory, categoryName: cat.categoryName, image:cat.image, applications: applicationsNotRelevant,
                totalSeconds: secondsCategoryNotRelevant, isRelevantCategory: false
            }
            arrFinalCategorias.push(obj2);
        }
    });
    arrFinalCategorias.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);


    // Agrupar por aplicación para sumar los tiempos
    let arrGrouped:any = {};
    for (let {idApplication, applicationName, applicationIconUrl, applicationIdImage, ...fields} of arrActvities) {
        //console.log(cat);
        // @ts-ignore
        if (!arrGrouped[applicationName]) arrGrouped[applicationName] = [];
        // @ts-ignore
        arrGrouped[applicationName].push({
            ...fields,
            "idApplication": idApplication,
            "iconUrl": applicationIconUrl,
            "idImage": applicationIdImage
        });
    }

    let arrFinal: { application: { idApplication: number; name: string; iconUrl: string; idImage: number; }; totalSeconds: number; isRelevant: boolean; }[] = [];
    Object.keys(arrGrouped).map(function (key) {
        //console.log(key);
        //console.log(arrGrouped[key]);
        let isRelevant = false;
        let relevantByProfile = false;
        let idApp = 0;
        let seconds = 0;
        let iconUrl = "";
        let idImage = 0;

        // Recorrer los registros de la misma aplicación para determinar el tiempo
        // @ts-ignore
        arrGrouped[key].map((act: any, j: number) => {
            if (act.totalSeconds > 0) {
                seconds += act.totalSeconds;
                idApp = act.idApplication;
                iconUrl = act.iconUrl;
                idImage = act.idImage;
                //isRelevant = act.isRelevant;

                // QUITAR TIEMPO AFK
                let dt = new Date(act.startTime);
                dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
                let dtEnd = new Date(dt);
                dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);
                //console.log("activity [" + dt + " to " + dtEnd + "]");
            }
        });

        // Determinar relevancia por perfil
        if (idSelectedUser == "") {
            if (idSelectedProfile != "") {
                listProfiles.map((prof: any, p: number) => {
                    if (prof.idProfile == idSelectedProfile) {
                        if (Array.isArray(prof.applications)) {
                            prof.applications.map((app: any, a: number) => {
                                if (app.idApplication == idApp) {
                                    isRelevant = true;
                                    relevantByProfile = true;
                                }
                            });
                        }
                    }
                });
            } else {
                for (let i = 0; i< listProfiles.length; i++) {
                    if (Array.isArray(listProfiles[i].applications)) {
                        for (let j = 0; j < listProfiles[i].applications.length; j++) {
                            if (listProfiles[i].applications[j].idApplication == idApp) {
                                isRelevant = true;
                                relevantByProfile = true;
                                break;
                            }
                        }
                        if (isRelevant) break;
                    }
                }
            }
        } else {
            let selectUsr = listUsers.find((el: {idUser: string;}) => el.idUser == idSelectedUser);
            if (idSelectedProfile != "") {
                let prof = listProfiles.find((p:any) => p.idProfile == idSelectedProfile);
                let profUser = selectUsr.profiles.find((p:any) => p.idProfile == idSelectedProfile);
                if (prof && profUser) {
                    if (Array.isArray(prof.applications)) {
                        prof.applications.map((app: any, a: number) => {
                            if (app.idApplication == idApp) {
                                isRelevant = true;
                                relevantByProfile = true;
                            }
                        });
                    }
                }
            } else {
                for (let h = 0; h < selectUsr.profiles.length; h++) {
                    let prof = listProfiles.find((p:any) => p.idProfile == selectUsr.profiles[h].idProfile); 
                    if (Array.isArray(prof.applications)) {
                        for (let j = 0; j < prof.applications.length; j++) {
                            if (prof.applications[j].idApplication == idApp) {
                                isRelevant = true;
                                relevantByProfile = true;
                                break;
                            }
                        }
                    }
                    if (isRelevant) break;
                }
                
            }
        }
        

        if (!isRelevant) {
            if (idSelectedUser != "") {
                // Determinar relevancia por usuario
                let selectedUsr = listUsers.find((el: { idUser: string; }) => el.idUser == idSelectedUser);
                //console.log(selectedUsr);
                if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantApplications != "undefined" && selectedUsr.relevantApplications != null) {
                    let thisApp = selectedUsr.relevantApplications.find((ap: { idApplication: number; }) => ap.idApplication == idApp);
                    if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                        isRelevant = thisApp.isRelevant;
                    }
                }
            } else {
                for (let i = 0; i < listUsers.length; i++) {
                    let selUsr = listUsers[i];
                    let acts = arrGrouped[key].filter((ac:any) => ac.idUser == selUsr.idUser);
                    if (acts.length > 0) {
                        if (typeof selUsr !== "undefined" && typeof selUsr.relevantApplications != "undefined" && selUsr.relevantApplications != null) {
                            let thisApp = selUsr.relevantApplications.find((ap: { idApplication: number; }) => ap.idApplication == idApp);
                            if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                                isRelevant = thisApp.isRelevant;
                                if (isRelevant) break;
                            }
                        }
                    }
                }
            }
            //console.log("-> " + isRelevant);   
        }

        let obj = {
            application: {
                idApplication: idApp,
                name: key,
                iconUrl: iconUrl,
                idImage: idImage
            },
            totalSeconds: seconds,
            isRelevant: isRelevant,
            relevantByProfile
        }
        arrFinal.push(obj);
    });
    // Ordenar por tiempo
    arrFinal.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);
    //console.log(arrFinal);
    return {categories: arrFinalCategorias, applications: arrFinal};
}

/**
 * Procesa las activities y devuelve el arreglo para representarlo como gráfica PIE.
 * @param listAllActivities Arreglo de todas las activities tal cual vienen de BD.
 */
export const processActivitiesForPieChart = (
        listAllActivities: any, listCategories: any, listProfiles: any, idSelectedCategory: string,
        idSelectedProfile: string, idSelectedUser: string, getRelevant: boolean, users: any, listSites: any) => {
    if (listSites.length == 0 || listCategories.length == 0) return;
    let arrActvities = listAllActivities.slice();
    let catUncategoryzed = listCategories.find((c:any) => c.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
    /* FILTRAR por equipo, si aplica */
    /* FILTRAR por perfil, si aplica (SE APLICA LO MISMO) */
    if (idSelectedProfile != "") {
        // Se tiene que obtener el tiempo, sólo de las actividades de los usuarios que tengan ese perfil.
        // Entonces en realidad se filtra por los usuarios de ese perfil.
        arrActvities = arrActvities.filter((data: { idUser: any; }) => {
            let findInUsr = users.find((el: { idUser: any; }) => el.idUser == data.idUser);
            if (typeof findInUsr != "undefined") {
                return data;
            }
        });
    }

    /* FILTRAR por usuario, si aplica */
    if (idSelectedUser != "") {
        arrActvities = arrActvities.filter((data: { idUser: string; }) => data.idUser == idSelectedUser);
    }

    // AGRUPAR POR SITIO WEB
    let arrGrouped:any = {};
    for (let site of listSites) {
        // @ts-ignore
        arrGrouped[site.name] = [];
    }
    for (let {notes, ...fields} of arrActvities) {
        for (let site of listSites) {
            if (notes.includes(site.name)) {
                // @ts-ignore
                arrGrouped[site.name].push({
                    ...fields,
                    idSite: site.idSite,
                    idImageSite: site.imageDto?.idImage
                })
            }
        }
    }

    // AGRUPAR POR CATEGORIA
    //console.log(catUncategoryzed);
    //console.log(arrGrouped);
    let arrGroupedCategory: any = {};
    for (let obj of Object.keys(arrGrouped)){
        //console.log(obj);
        //console.log(arrGrouped[obj]);
        let cat = catUncategoryzed;
        let idSite = 0;
        let idImageSite = 0;
        if (arrGrouped[obj].length > 0) {
            //console.log(arrGrouped[obj]);
            idSite = arrGrouped[obj][0].idSite;
            idImageSite = arrGrouped[obj][0].idImageSite;
            cat = listCategories.find((c:any) => c.sites.find((si:any) => si.idSite == idSite));
        } else {
            let site = listSites.find((s:any) => s.name.toUpperCase() == obj.toUpperCase());
            idSite = site.idSite;
            idImageSite = site.idImageSite;
        }

        if (!arrGroupedCategory[cat.idCategorysite]) arrGroupedCategory[cat.idCategorysite] = {
            idCategorysite: cat.idCategorysite, iconUrl: cat.image?.url,
            name:cat.name, sites:[]
        };

        let sites = arrGroupedCategory[cat.idCategorysite].sites;
        sites.push({idSite, idImageSite, name: obj, activities: arrGrouped[obj]});
        arrGroupedCategory[cat.idCategorysite] = {
            ...arrGroupedCategory[cat.idCategorysite],
            sites: sites
        }
    }
    //console.log(arrGroupedCategory);
    //console.log(arrGrouped);
    let arrFinalCategories: {
        idCategorySite:number, name:string, label:string, iconUrl:string, idImage: number,
            totalSeconds:number, value:number, isRelevant: boolean,
        sites: [{idSite: number, name:string, iconUrl:string, idImage:number, totalSeconds:number}]
    }[] = [];
    Object.keys(arrGroupedCategory).map(function (key:string) {
       //console.log(key);
       let totalSeconds = 0;
       let sites:any = [];
       let sitesNotRelevant:any = [];
       arrGroupedCategory[key].sites.forEach((site:any) => {
           //console.log(site);
           let totalSecondsSite = 0;
           site.activities.map((act:any) => {
               totalSecondsSite += act.totalSeconds;
               totalSeconds += act.totalSeconds;
           });
           //console.log(site);
           let isRelevant = false;
           // Determinar relevancia por perfil
           if (idSelectedProfile != "") {
               listProfiles.map((prof: any, p: number) => {
                   if (prof.idProfile == idSelectedProfile) {
                       if (Array.isArray(prof.profileSites)) {
                           prof.profileSites.map((siteP: any, a: number) => {
                               if (siteP.idSite == site.idSite) {
                                   isRelevant = site.isRelevant;
                               }
                           });
                       }
                   }
               });
            } else {
                for (let i = 0; i < listProfiles.length; i++) {
                    let prof = listProfiles[i];
                    if (prof.idProfile == idSelectedProfile) {
                        if (Array.isArray(prof.profileSites)) {
                            for (let j = 0; j < prof.profileSites.length; j++) {
                                if (prof.profileSites[j].idSite == site.idSite) {
                                    isRelevant = prof.profileSites[j].isRelevant;
                                    if (isRelevant) break;
                                }
                            }
                        }
                        if (isRelevant) break;
                    }
                }
            }
            
            if (!isRelevant) {
                if (idSelectedUser != "") {
                   // Determinar relevancia por usuario
                   let selectedUsr = users.find((el: { idUser: string; }) => el.idUser == idSelectedUser);
                   if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantSites != "undefined" && selectedUsr.relevantSites != null) {
                       let thisSite = selectedUsr.relevantSites.find((ap: { idSite: number; }) => ap.idSite == site.idSite);
                       if (typeof thisSite != "undefined" && typeof thisSite.isRelevant != "undefined" && thisSite.isRelevant != null) {
                           isRelevant = thisSite.isRelevant;
                       }
                   }
               } else {
                    for (let i = 0; i < users.length; i++) {
                        let selectedUsr = users[i];
                        if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantSites != "undefined" && selectedUsr.relevantSites != null) {
                            let thisSite = selectedUsr.relevantSites.find((ap: { idSite: number; }) => ap.idSite == site.idSite);
                            if (typeof thisSite != "undefined" && typeof thisSite.isRelevant != "undefined" && thisSite.isRelevant != null) {
                                isRelevant = thisSite.isRelevant;
                            }
                        }
                    }
               }
            }

           if (totalSecondsSite > 0) {
               if (isRelevant) {
                   sites.push({idSite: site.idSite, id: site.name,
                       label:site.name, totalSeconds: totalSecondsSite, isRelevant,
                       value: totalSecondsSite, application: {name: site.name, idImage: site.idImageSite}});
               } else {
                   sitesNotRelevant.push({idSite: site.idSite, id: site.name,
                       label:site.name, totalSeconds: totalSecondsSite, isRelevant,
                       value: totalSecondsSite, application: {name: site.name, idImage: site.idImageSite,}});
               }
           }
       });
       //console.log(sites);
       if (sites.length > 0 && getRelevant == true) {
           let total = sites.reduce((acu:number, current:any) => acu+=current.totalSeconds,0);
           let obj:any = {
               idCategorysite: key,
               name: arrGroupedCategory[key].name,
               label: arrGroupedCategory[key].name,
               id: arrGroupedCategory[key].name,
               iconUrl: arrGroupedCategory[key].iconUrl,
               totalSeconds: total, isRelevant: true,
               value: total,
               sites
           }
           arrFinalCategories.push(obj);
       }

       if (sitesNotRelevant.length > 0 && getRelevant == false) {
           let total = sitesNotRelevant.reduce((acu:number, current:any) => acu+=current.totalSeconds,0);
           let obj:any = {
               idCategorysite: key,
               name: arrGroupedCategory[key].name,
               label: arrGroupedCategory[key].name,
               id: arrGroupedCategory[key].name,
               iconUrl: arrGroupedCategory[key].iconUrl,
               totalSeconds: total, isRelevant: false,
               value: total,
               sites: sitesNotRelevant
           }
           arrFinalCategories.push(obj);
       }
    });
    //console.log(arrFinalCategories);
    arrFinalCategories.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);

    let arrFinal: { application: { idApplication: number; name: string; iconUrl: string; idImage: number; }; totalSeconds: number; isRelevant: boolean; }[] = [];
    Object.keys(arrGrouped).map(function (key) {
        let isRelevant = false;
        let idApp = 0;
        let seconds = 0;
        let iconUrl = "";
        let idImage = 0;
        let idSite = 0;

        // Recorrer los registros de la misma aplicación para determinar el tiempo
        // @ts-ignore
        arrGrouped[key].map((act, j) => {
            if (act.totalSeconds > 0) {
                seconds += act.totalSeconds;
                idApp = act.idApplication;
                iconUrl = act.iconUrl;
                idImage = act.idImageSite;
                idSite = act.idSite;
                //isRelevant = act.isRelevant;

                // QUITAR TIEMPO AFK
                let dt = new Date(act.startTime);
                dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
                let dtEnd = new Date(dt);
                dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);
                //console.log("activity [" + dt + " to " + dtEnd + "]");
            }
        });

        //isRelevant = true;
        // Determinar relevancia por perfil
        if (idSelectedProfile != "") {
            listProfiles.map((prof: any, p: number) => {
                if (prof.idProfile == idSelectedProfile) {
                    if (Array.isArray(prof.profileSites)) {
                        prof.profileSites.map((site: any, a: number) => {
                            if (site.idSite == idSite) {
                                isRelevant = site.isRelevant;
                            }
                        });
                    }
                }
            });
        } else {
            for (let i = 0; i < listProfiles.length; i++) {
                let prof = listProfiles[i];
                if (prof.idProfile == idSelectedProfile) {
                    if (Array.isArray(prof.profileSites)) {
                        for (let j = 0; j < prof.profileSites.length; j++) {
                            if (prof.profileSites[j].idSite == idSite) {
                                isRelevant = prof.profileSites[j].isRelevant;
                                if (isRelevant) break;
                            }
                        }
                    }
                    if (isRelevant) break;
                }
            }
        }

        if (!isRelevant) {
            if (idSelectedUser != "") {
                // Determinar relevancia por usuario
                let selectedUsr = users.find((el: { idUser: string; }) => el.idUser == idSelectedUser);
                if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantSites != "undefined" && selectedUsr.relevantSites != null) {
                    let thisSite = selectedUsr.relevantSites.find((ap: { idSite: number; }) => ap.idSite == idSite);
                    if (typeof thisSite != "undefined" && typeof thisSite.isRelevant != "undefined" && thisSite.isRelevant != null) {
                        isRelevant = thisSite.isRelevant;
                    }
                }
            } else {
                for (let i = 0; i < users.length; i++) {
                    let selectedUsr = users[i];
                    if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantSites != "undefined" && selectedUsr.relevantSites != null) {
                        let thisSite = selectedUsr.relevantSites.find((ap: { idSite: number; }) => ap.idSite == idSite);
                        if (typeof thisSite != "undefined" && typeof thisSite.isRelevant != "undefined" && thisSite.isRelevant != null) {
                            isRelevant = thisSite.isRelevant;
                        }
                    }
                }
           }
        }

        let obj = {
            id: key,
            idSite: idSite,
            label: key,
            value: seconds,
            isRelevant: isRelevant,
            totalSeconds: seconds,
            application: {
                name: key,
                idImage: idImage
            }
        }

        if (isRelevant == getRelevant) {
            // @ts-ignore
            arrFinal.push(obj);
        }
    });

    /* Filtrar por SITIO WEB, si aplica */
    if (typeof idSelectedCategory != "undefined" && idSelectedCategory != "") {
        if (idSelectedCategory != "0") {
            let selectedCat = listCategories.find((el: any) => el.idCategorysite == idSelectedCategory);
            //console.log(selectedCat);
            if (Array.isArray(selectedCat.sites)) {
                arrFinal = arrFinal.filter((data: any) => {
                    let findSiteInCat = selectedCat.sites.find((el: any) => el.idSite == data.idSite);
                    if (typeof findSiteInCat != "undefined") {
                        return data;
                    }
                });
            }
        } else {
            // SIN CATEGORÍA
            arrFinal = arrFinal.filter((data: any) => {
                let existsInCat = false;
                // por cada actividad recorrer todas las categorías para obtener sólo las que cuya aplicación no pertenezca a ninguna
                listCategories.map((c: any, i: number) => {
                    if (Array.isArray(c.sites)) {
                        let findSiteInCat = c.sites.find((el: any) => el.idSite == data.idSite);
                        if (typeof findSiteInCat != "undefined") {
                            existsInCat = true;
                            return;
                        }
                    }
                });
                if (!existsInCat) {
                    return data;
                }
            });
        }
    }

    // Ordenar por tiempo
    arrFinal.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);
    //console.log(arrFinal);
    return {sites: arrFinal, categorySites: arrFinalCategories};
}

/**
     * Procesar el arreglo para la creación de linea de tiempo
     * */
export const processArrayTimeline = (listCategories:any, activitiesTimeline:any, users:any,
    idSelectedProfile:string, idSelectedUser:string, inputSearch:string, report:string,
    objectUsers:any, allProfiles:any, schedulesByUser:any, startDate:any, endDate:any) => {
    if (listCategories.length == 0) return;
    let arrActvities = activitiesTimeline.slice();
    //console.log(users);
    let listUsersAux = users.map((u:User) => u.idUser);

    /* FILTRAR por equipo, si aplica */
    /* FILTRAR por perfil, si aplica (SE APLICA LO MISMO) */
    if (idSelectedProfile != "") {
        // Filtra por los usuarios de ese perfil
        arrActvities = arrActvities.filter((data:any) => listUsersAux.includes(data.idUser));
    }

    /* FILTRAR por usuario, si aplica */
    if (idSelectedUser != "") {
        arrActvities = arrActvities.filter((data:any) => data.idUser == idSelectedUser);
    }

    /* FILTRAR por nombre de aplicación (campo de búsqueda), si aplica */
    let filter = inputSearch;
    if (filter != "") {
        arrActvities = arrActvities.filter((data:any) => data.applicationName.toLowerCase().includes(filter.toLowerCase()));
    }

    let listUsers = arrActvities.map((act:any) => {
      let name = objectUsers[act.idUser].name;
      let lastName = objectUsers[act.idUser].lastName;
      return {
          idUser: act.idUser, 
          name: name ? name : '',
          lastName: lastName ? lastName : '',
          fullName: name + ' ' + lastName,
          fullNameShort: (name ? name.split(' ')[0] :'') + ' ' + (lastName ? lastName.split(' ')[0] :''),
          profiles: objectUsers[act.idUser].profiles
      };
    })

    let hash:any = {};
    listUsers = listUsers.filter((curr:any) => {
        let exists = !hash[curr.idUser];
        hash[curr.idUser] = true;
        return exists;
    });

    for (let i = 0; i < listUsers.length; i++) {
        listUsers[i]['data'] = arrActvities.filter((x:any) => x.idUser === listUsers[i].idUser && x.totalSeconds > 0);
    }
    //console.log(listUsers);

    listUsers = listUsers.map((k:any) => {
        //let k = listUsers[i];
        let arrGrouped:any = {};
        for (let {idApplication, applicationName, applicationIconUrl, applicationIdImage, ...fields} of k.data) {
            if (!arrGrouped[applicationName]) {
                arrGrouped[applicationName] = [];
            }
            arrGrouped[applicationName].push({
                ...fields,
                "idApplication": idApplication,
                "iconUrl": applicationIconUrl,
                "idImage": applicationIdImage
            });
        }
        return { idUser: k.idUser, name: k.name, lastName: k.lastName, fullName: k.fullName,
          fullNameShort: k.fullNameShort, profiles: k.profiles, applications: arrGrouped };
    });
    //console.log(listUsers);

    listUsers = listUsers.map((obj:any) => {
        let listReelevantApplications:any = {};
        let productiveTime = 0;
        let listNonReelevantApplications:any = {};
        let nonProductiveTime = 0;
        Object.keys(obj.applications).map(function (key) {
            let isRelevant = false;
            let idApp = 0;
            let seconds = 0;
            let iconUrl = "";
            let idImage = "";
            // Recorrer los registros de la misma aplicación para determinar el tiempo
            let nApplications = obj.applications[key].map((act:any) => {
                let localSeconds = act.totalSeconds;
                seconds += act.totalSeconds;
                idApp = act.idApplication;
                iconUrl = act.iconUrl;
                idImage = act.idImage;
                //isRelevant = act.isRelevant;

                // QUITAR TIEMPO AFK
                let dt = new Date(act.startTime);
                dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
                let dtEnd = new Date(dt);
                dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);
                act.totalSeconds = localSeconds;

                return act;
            });
            
            isRelevant = false;
            if (idSelectedProfile == "") {
                for (let i= 0; i < obj.profiles.length; i++) {
                    let prof = allProfiles.find((p:any) => p.idProfile == obj.profiles[i].idProfile);
                    if (Array.isArray(prof.applications)) {
                        for (let j = 0; j < prof.applications.length; j++){
                            if (prof.applications[j].idApplication == idApp) {
                                isRelevant = true;
                                break;
                            }
                        }
                    }
                }
            } else {
                let prof = allProfiles.find((p:any) => p.idProfile == idSelectedProfile);
                if (Array.isArray(prof.applications)) {
                    for (let j = 0; j < prof.applications.length; j++){
                        if (prof.applications[j].idApplication == idApp) {
                            isRelevant = true;
                            break;
                        }
                    }
                }
            }

            // Determinar relevancia por usuario
            if (!isRelevant) {
                let selectedUsr = users.find((el:any) => el.idUser == obj.idUser);
                if (selectedUsr && selectedUsr?.relevantApplications) {
                    let thisApp = selectedUsr.relevantApplications.find((ap:any) => ap.idApplication == idApp);
                    if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                        isRelevant = thisApp.isRelevant;
                    }
                }
            }

            if (isRelevant) {
                productiveTime += seconds;
                listReelevantApplications[key] = nApplications;
            } else {
                nonProductiveTime += seconds;
                listNonReelevantApplications[key] = nApplications;
            }
        });

        let res = getTimeByScheduleAndUser(obj.idUser, schedulesByUser, startDate, endDate);
        //console.log(res);
        let secondsRegister = res.time;
        let scheduleRegister, unrecordedTime, totalTimeRegistred;
        let timeRegistred = productiveTime + nonProductiveTime;
        let timeProd = secondsRegister;
        let timeProdAux = timeProd;
        if (timeProd == -1 || timeProd == 0) {
            scheduleRegister = null;
            unrecordedTime = null;
            totalTimeRegistred = productiveTime + nonProductiveTime;
            timeProd = totalTimeRegistred;
        } else {
            scheduleRegister = timeProd;
            let value = secondsRegister > (productiveTime + nonProductiveTime) ? secondsRegister - productiveTime - nonProductiveTime : 0;
            unrecordedTime = value > 0 ? value : 0;
            totalTimeRegistred = productiveTime + nonProductiveTime + Math.abs(value);
        }

        let lRAAux:any = [];
        Object.keys(listReelevantApplications).map((key, ind) => {
            lRAAux.push(transformApplicationJSONToArray(key, listReelevantApplications[key]))
        });
        lRAAux.sort((a:any, b:any) => a.totalSeconds < b.totalSeconds ? 1 : -1);
        listReelevantApplications = lRAAux;
        lRAAux = [];
        Object.keys(listNonReelevantApplications).map((key, ind) => {
            lRAAux.push(transformApplicationJSONToArray(key, listNonReelevantApplications[key]));
        });
        lRAAux.sort((a:any, b:any) => a.totalSeconds < b.totalSeconds ? 1 : -1);
        listNonReelevantApplications = lRAAux;
        obj = {
            idUser: obj.idUser,
            name: obj.name,
            lastName: obj.lastName,
            fullName: obj.fullName,
            fullNameShort: obj.fullNameShort,
            productiveTime,
            listReelevantApplications: listReelevantApplications,
            nonProductiveTime: nonProductiveTime,
            listNonReelevantApplications: listNonReelevantApplications,
            unrecordedTime,
            scheduleRegister,
            timeRegistred,
            totalTimeRegistred,
            totalTime: timeProd,
            timeObligatoryByDay: res.timeObligatoryByDay,
            timeProdAux
        };
        
        let userDto = users.find((user:any) => user.idUser == obj.idUser);
        obj.imgUrl = userDto?.imageDto?.url;
        //console.log(obj);
        return obj;
    });

    let catUncategoryzed = listCategories.find((c:any) => c.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
    //console.log(listCategories);
    let arrGroupedCategoryRelevant:any = {};
    let arrGroupedCategoryNotRelevant:any = {};
    listUsers = listUsers.map((us:any) => {
        for (let {idApplication, totalSeconds, ...fields} of us.listReelevantApplications) {
            let cat = listCategories.find((c:any) => c.applications.find((a:any) => a.idApplication == idApplication));
            if (cat == undefined) {
                cat = catUncategoryzed;
            }
            if (!arrGroupedCategoryRelevant[cat.idCategory]) arrGroupedCategoryRelevant[cat.idCategory] = [];
            arrGroupedCategoryRelevant[cat.idCategory].push({application:{...fields, idApplication, totalSeconds}, totalSeconds});
        }
        let categoriesRelevant = [];
        for (let x of Object.keys(arrGroupedCategoryRelevant)) {
            let cat = listCategories.find((c:any) => c.idCategory == x);
            let totalSeconds = arrGroupedCategoryRelevant[x].reduce((acu:any, current:any) => acu+=current.application.totalSeconds, 0);
            categoriesRelevant.push({idCategory: cat.idCategory, categoryName: cat.name,
                applications:arrGroupedCategoryRelevant[x], totalSeconds, image: {url: cat.image?.url}});
        }

        for (let {idApplication, totalSeconds, ...fields} of us.listNonReelevantApplications) {
            let cat = listCategories.find((c:any) => c.applications.find((a:any) => a.idApplication == idApplication));
            if (cat == undefined) {
                cat = catUncategoryzed;
            }
            if (!arrGroupedCategoryNotRelevant[cat.idCategory]) arrGroupedCategoryNotRelevant[cat.idCategory] = [];
            arrGroupedCategoryNotRelevant[cat.idCategory].push({application: {...fields, idApplication, totalSeconds}, totalSeconds});
        }
        let categoriesNotRelevant = [];
        for (let x of Object.keys(arrGroupedCategoryNotRelevant)) {
            let cat = listCategories.find((c:any) => c.idCategory == x);
        let totalSeconds = arrGroupedCategoryNotRelevant[x].reduce((acu:any, current:any) => acu+=current.application.totalSeconds, 0);
            categoriesNotRelevant.push({idCategory: cat.idCategory, categoryName: cat.name,
                applications:arrGroupedCategoryNotRelevant[x], totalSeconds, image: {url: cat.image?.url}});
        }
        arrGroupedCategoryRelevant = {};
        arrGroupedCategoryNotRelevant = {};
        return {...us, categoriesRelevant, categoriesNotRelevant};
    })
    //console.log(listUsers);

    return listUsers;
}

/*Convierte un json tipo
    * app:[{activity1},{activivity2}]
    * a
    * obj:{name, activities:[], totalSeconds}
    * */
const transformApplicationJSONToArray = (name:string, activities:any) => {
    let act = [];
    let totalSeconds = 0;
    let idApp = 0;
    let idImage = 0;
    activities.forEach((a:any) => {
        idApp = a.idApplication;
        idImage = a.idImage;
        act.push(a);
        totalSeconds += a.totalSeconds;
    })
    return {name, idApplication: idApp, idImage: idImage, activities: activities, totalSeconds};
}

const getTimeByScheduleAndUser = (idUser:number, schedulesByUser:any, startDate:any, endDate:any) => {
    //console.log(idUser)
    //console.log(schedulesByUser);
    let ind = -1;
    if (ind === -1) {
        for (let i = 0; i < schedulesByUser.length; i++) {
            let index = schedulesByUser[i].users.findIndex((x:any) => x.idUser == idUser);
            if (index !== -1) {
                ind = i;
                break;
            }
        }
    }
    if (ind === -1) return {time: -1, timeObligatoryByDay: {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}};
    //console.log("wow");
    let timeObligatoryByDay:any = {};
    let dayAndTimeObligatory = timeObligatory(schedulesByUser[ind].days);
    
    let days = schedulesByUser[ind].days;
    let totalSeconds = 0;
    let arrGrouped:any = {};
    for (let {dayOfWeek, ...fields} of days) {
        if (!arrGrouped[dayOfWeek]) arrGrouped[dayOfWeek] = [];
        arrGrouped[dayOfWeek].push({...fields});
    }
    
    let startD = new Date(startDate);
        while (startD <= endDate) {
        let day = startD.getDay();
        
        let executeCode = true;
        if (dayAndTimeObligatory !== null) {
            if (dayAndTimeObligatory[day] !== undefined) {
                if (!timeObligatoryByDay[day]) {
                    timeObligatoryByDay[day] = dayAndTimeObligatory[day] * 3600;
                }
                totalSeconds += dayAndTimeObligatory[day] * 3600;
                executeCode = false;
            }
        }
        if (executeCode) {
            let breakTime:any = [], workingTime:any = [];
            let secondsByDay = 0;
            if (arrGrouped[day] !== undefined) {
                arrGrouped[day].forEach((d:any) => {
                    if (d.isBreakTime) {
                        breakTime.push(d);
                    } else {
                        workingTime.push(d);
                    }
                });


                for (let i = 0; i < workingTime.length; i++) {
                    secondsByDay += workingTime[i].seconds;
                    for (let j = 0; j < breakTime.length; j++) {
                        if (breakTime[j].hInit >= workingTime[i].hInit && breakTime[j].hFin <= workingTime[i].hFin) {
                            secondsByDay -= breakTime[j].seconds;
                        }
                    }
                }
            }
            if (!timeObligatoryByDay[day]) {
                timeObligatoryByDay[day] = secondsByDay;
            }
            totalSeconds += secondsByDay;
        }
        startD.setDate(startD.getDate() + 1);
    }
    //console.log(idUser);
    //console.log(timeObligatoryByDay);
    return {time: totalSeconds, timeObligatoryByDay};
}

const timeObligatory = (days:any) => {
    //console.log("timeObligatory");
    let res:any = {};
    days.forEach((d:any) => {
        if (d.obligatoryHours != 0 && d.obligatoryHours != null) {
            res[d.dayOfWeek] = d.obligatoryHours;
        }
    });
    //console.log(res);
    return res;
}

/**
 * Obtiene las ventanas del sitio indicado.
 * @param listAllActivities
 */
export const getWindowsBySite = (listAllActivities: any, idSelectedUser: string, siteName: string) => {
    // Separar los registros AFK
    let arrAfk = listAllActivities.filter((data: { applicationName: string; }) => data.applicationName.toLowerCase() == "afk");
    let arrActivities = listAllActivities.flat();

    /* FILTRAR por usuario, si aplica */
    if (idSelectedUser != "") {
      arrActivities = arrActivities.filter((data: { idUser: string; }) => data.idUser == idSelectedUser);
    }

    arrActivities = arrActivities.filter((data: {notes:string}) => data.notes.toUpperCase().includes(siteName.toUpperCase()));

    // Agrupar por nombre ventana para sumar los tiempos
    let arrGrouped = {};
    for (let {notes, ...fields} of arrActivities) {
      // @ts-ignore
      if (!arrGrouped[notes]) arrGrouped[notes] = [];
      // @ts-ignore
      arrGrouped[notes].push({...fields});
    }

    let arrFinal: { application: { name: string; }; totalSeconds: number; notes: string; }[] = [];
    Object.keys(arrGrouped).map(function (key) {
        let seconds = 0;

        // Recorrer los registros de la misma ventana para determinar el tiempo
        // @ts-ignore
        arrGrouped[key].map((act, j) => {
            if (act.totalSeconds > 0) {
                seconds += act.totalSeconds;

                let dt = new Date(act.startTime);
                dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
                let dtEnd = new Date(dt);
                dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);

                if (!REG_EXP_MEETING_WINDOWS.test(key)) {    // Para no restar el tiempo inactivo en las aplicaciones de MEETING

                    arrAfk.map((afk: any, k: number) => {
                        // El usuaro debe ser el mismo para comparar el tiempo AFK
                        if (afk.totalSeconds > 0 && afk.user.idUser == act.user.idUser) {
                            let startAfk = new Date(afk.startTime);
                            startAfk = new Date(startAfk.getTime() + startAfk.getTimezoneOffset() * 60000);
                            startAfk.setSeconds(startAfk.getSeconds() - 180);   // El tiempo AFK empieza a contar 3 min. antes
                            let endAfk = new Date(startAfk);
                            endAfk.setSeconds(endAfk.getSeconds() + afk.totalSeconds);

                            if (dt <= startAfk && startAfk <= dtEnd) {
                                //console.log("AFK [" + startAfk + " to " + endAfk + "]");
                                if (endAfk <= dtEnd) {
                                    // El tiempo afk termina antes o igual que la actividad (la actividad contiene al afk)
                                    seconds -= afk.totalSeconds;
                                } else {
                                    // El tiempo afk termina después que la actividad, sólo restar hasta el final de la actividad.
                                    let diff = (dtEnd.getTime() - startAfk.getTime()) / 1000;
                                    seconds -= diff;
                                }
                            }
                        }
                    });

                }
            }
        });

        let obj = {
            application: {
                name: key
            },
            totalSeconds: seconds,
            notes: key
        }
        arrFinal.push(obj);
    });

    // Ordenar por tiempo
    arrFinal.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);
    return arrFinal;
}

export const secondsToHMSExcel = (seconds: number) => {
    let h = Math.floor( seconds/ 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    let s = (seconds - (h * 3600) - (m * 60)).toFixed();
    return ( h <= 9 ? ("0" + h ) : h ) + ":" + ( m <= 9 ? ("0" + m) : m) + ":" + (s.length <= 1 ? ("0" + s): s);
}

export const exportDataExcel = (allActivities:any, selectedUsers:any, idSelectedProfile:string, listProfiles:any,
        users:any, inputSearch:string, report:string,
        idSelectedCategory:string, idSelectedCategoryNonRelevant:string, listCategories:any, selectedProject:any, allProjects: any
    ) => {
    /*console.log("idSelectedUser ", idSelectedUser);
    console.log("idSelectedProfile ", idSelectedProfile);
    console.log("listProfiles ", listProfiles);
    console.log("inputSearch ", inputSearch);
    console.log("idCategory ",idSelectedCategory);
    console.log("idSelectedCategoryNonRelevant ", idSelectedCategoryNonRelevant);
    console.log("listCategories ", listCategories);
    console.log("allActivities", allActivities);*/
    // 0 Sin proyecto, -1 Todos los proyectos, n El proyeto
    //let projectName = (localStorage.getItem(PROJECT_ID_KEY) == "" ?"0":(localStorage.getItem(PROJECT_ID_KEY) == "-1"?"-1":localStorage.getItem(PROJECT_KEY)));

    let arrFinal:{startDate:any, endDate:any, project:string, team:string, username:string, profile:string,
        isRelevant:boolean, category:string, application:string, time1:string, time2:number, timeSeconds:any,
        projectTask:string}[] = [];

    let arrActvities = allActivities.slice();
    //console.log(arrActvities);
    /* FILTRAR por perfil, si aplica (SE APLICA LO MISMO) */
    if (idSelectedProfile != "") {
        // Se tiene que obtener el tiempo, sólo de las actividades de los usuarios que tengan ese perfil.
        // Entonces en realidad se filtra por los usuarios de ese perfil.
        arrActvities = arrActvities.filter((data:any) => {
            let findInUsr = users.find((el:any) => el.idUser == data.idUser);
            if (typeof findInUsr != "undefined") {
                return data;
            }
        });
    }

    /* FILTRAR por usuario, si aplica */
    if (selectedUsers.length > 0) {
        arrActvities = arrActvities.filter((data:any) => selectedUsers.includes(data.idUser));
    }

    /* FILTRAR por nombre de aplicación (campo de búsqueda), si aplica */
    if (inputSearch != "" && report !== "productivity") {
        arrActvities = arrActvities.filter((data:any) => data.applicationName.toLowerCase().includes(inputSearch.toLowerCase()));
    }
    //console.log("arrActivities", arrActvities);
    if (JSON.stringify(selectedProject) != '{}') {
        arrActvities = arrActvities.filter((act:any) => act.idProject == selectedProject.idProject);
    }

    // Obtener los diferentes usuarios (de las actividades)
    //console.log(users);
    let listUsers = users.map((us:any, ind:number) => {
        return {idUser: us.idUser, name: (us.name + ' ' + us.lastName), email: us.email, profiles: us.profiles};
    });
    let hash:{} = {};
    listUsers = listUsers.filter((curr:any) => {
        // @ts-ignore
        let exists = !hash[curr.idUser];
        // @ts-ignore
        hash[curr.idUser] = true;
        return exists;
    });

    let objProjects:any = {};
    for (let {idProject,name, ...fields} of allProjects) {
        // @ts-ignore
        if (!objProjects[idProject]) objProjects[idProject] = [];
        // @ts-ignore
        objProjects[idProject] = name;
    }
    //console.log(objProjects);
    //console.log(arrActvities);
    // En lugar de agrupar por aplicación, agrupar por proyecto y después por aplicación
    // Obtener los diferentes proyectos (de las actividades)
    let listProjects = arrActvities.map((act: any, id: number) => {
        return {idProject: act.idProject, name: objProjects[act.idProject]};
    });
    let hash2:{} = {};
    listProjects = listProjects.filter((curr: any) => {
        // @ts-ignore
        let exists = !hash2[curr.idProject];
        // @ts-ignore
        hash2[curr.idProject] = true;
        return exists;
    });
    //console.log("listProjects", listProjects);

    // Asignar los proyectos a cada usuario
    for (let i = 0; i < listUsers.length; i++) {
        listUsers[i]['projects'] = listProjects;
    }

    let listUsersCopy = [];
    /**
     * Por cada usuario, asignar las actividades que pertenezcan al usuario y proyecto.
     * TUVE QUE HACER UNA COPA DEL ARREGLO, PORQUE SI NO SE LE ASIGNABAN LOS MISMOS PROYECTOS-ACTIVIDADES A CADA USUARIO.
     */
    for (let i = 0; i < listUsers.length; i++) {
        let newU = {idUser: listUsers[i].idUser, name: listUsers[i].name, email: listUsers[i].email, profiles: listUsers[i].profiles, projects: []};
        for (let k = 0; k < listUsers[i].projects.length; k++) {
            let projTmp = listUsers[i].projects[k];
            let newProj = {idProject: projTmp.idProj, name: projTmp.name, data: []};
            let arrActTmp = arrActvities.filter((data: any) => {
                if (data.idProject != null) {
                    if (data.idUser == listUsers[i].idUser && data.idProject == listUsers[i].projects[k].idProject) {
                        return data;
                    }
                } else {
                    if ((typeof projTmp.idProject == "undefined" || projTmp.idProject == null) && data.idUser == listUsers[i].idUser) {
                        return data;
                    }
                }
            });
            newProj['data'] = arrActTmp.slice();
            // @ts-ignore
            newU.projects.push(newProj);
        }
        listUsersCopy.push(newU);
        //listUsers[i]['data'] = arrActvities.filter((x:any) => x.idUser === listUsers[i].idUser && x.totalSeconds > 0);
    }
    //console.log("listUsersCopy", listUsersCopy);

    // Agrupar actividades por nombre de aplicación
    listUsers = listUsersCopy.map((k:any, ind:number) => {
        //console.log("k", k);
        let projectsWithApps = k.projects?.map((p: any, n: number) => {
            let arrGrouped = {};
            for (let {idApplication, applicationName, applicationIconUrl, applicationIdImage, ...fields} of p.data) {
                // @ts-ignore
                if (!arrGrouped[applicationName]) {
                    // @ts-ignore
                    arrGrouped[applicationName] = [];
                }
                // @ts-ignore
                arrGrouped[applicationName].push({
                    ...fields,
                    "idApplication": idApplication,
                    "iconUrl": applicationIconUrl,
                    "idImage": applicationIdImage
                });
            }
            return {idProject: p.idProject, name: p.name, applications: arrGrouped, profiles: p.profiles};
        });

        return {idUser: k.idUser, name: k.name, email: k.email, projects: projectsWithApps, profiles: k.profiles};
    });
    //console.log(listUsers);

    // DETERMINAR SI EN LAS ACTIVIDADES ALTUNA NO TIENE STARTTIME (ES DE UN DÍA ANTERIOR). DESPUÉS SI HAY ACTIVIDADES SIN STARTTIME, QUITARLE A TODAS LA HORA
    let anyActivityWithStartTimeNull = false;
    arrActvities.map((act: any, k: number) => {
        if (act.startTime == null) {
            anyActivityWithStartTimeNull = true;
        }
    });
    //console.log(listProfiles);
    listUsers.forEach((obj:any, ind:any) => {
        obj.projects.map((proj: any, i: number) => {
            //console.log("proj", proj);
            Object.keys(proj.applications).map((key: any, i: number) => {
                //Object.keys(obj.applications).map((key:any, i:number) => {
                //console.log(obj.applications[key]);
                    //console.log("key", key);
                    let isRelevant = false;
                    let idApp = proj.applications[key][0].idApplication;
                    let nameProject = proj.name;
                    let seconds = 0;
                    let profile = "";
                    // DETERMINAR EL PERFIL
                    if (idSelectedProfile == "") {
                        let index = users.findIndex((x: any) => x.idUser == obj.idUser);
                        if (index != -1 && users[index].profiles != null) {
                            users[index].profiles.forEach((nameProfile: any) => {
                                profile += nameProfile.name + ", ";
                            });
                            profile = (profile.length > 0 ? profile.substr(0, profile.length - 2) : profile);
                        }
                    } else {
                        let indexProfile = listProfiles.findIndex((x: any) => x.idProfile == idSelectedProfile);
                        if (indexProfile != -1) {
                            profile = listProfiles[indexProfile].name;
                        }
                    }

                    //console.log(obj);
                    // Determinar relevancia por perfil
                    isRelevant = false;
                    if (idSelectedProfile == "") {
                        for (let i = 0; i < obj.profiles.length; i++) {
                            let prof = obj.profiles[i];
                            let pr = listProfiles.find((pr:any) => pr.idProfile == prof.idProfile);
                            if (Array.isArray(pr.applications)) {
                                for (let j = 0; j < pr.applications.length; j++) {
                                    let app = pr.applications[j];
                                    if (app.idApplication == idApp) {
                                        isRelevant = true;
                                        break;
                                    }
                                }
                                if (isRelevant) {
                                    break;
                                }
                            }
                        }
                    } else {
                        let prof = obj.profiles.find((pr:any) => pr.idProfile == idSelectedProfile);
                        if (prof) {
                            let pr = listProfiles.find((pr:any) => pr.idProfile == idSelectedProfile);
                            pr.applications.map((app: any, a: number) => {
                                if (app.idApplication == idApp) {
                                    isRelevant = true;
                                }
                            });
                        }
                    }
                    if (!isRelevant) {
                        // Determinar relevancia por usuario
                        isRelevant = false;
                        let selectedUsr = users.find((el: { idUser: string; }) => el.idUser == obj.idUser);
                        if (typeof selectedUsr !== "undefined" && typeof selectedUsr.relevantApplications != "undefined" && selectedUsr.relevantApplications != null) {
                            let thisApp = selectedUsr.relevantApplications.find((ap: { idApplication: number; }) => ap.idApplication == idApp);
                            if (typeof thisApp != "undefined" && typeof thisApp.isRelevant != "undefined" && thisApp.isRelevant != null) {
                                isRelevant = thisApp.isRelevant;
                            }
                        }
                    }

                    let ejecuteCode = true;
                    let nameCategory = "";
                    if (isRelevant) {
                        /* Filtrar por categorías, si aplica */
                        if (idSelectedCategory != "") {
                            if (idSelectedCategory != "0") {
                                let selectedCat = listCategories.find((el: { idCategory: string; }) => el.idCategory == idSelectedCategory);
                                if (Array.isArray(selectedCat.applications)) {
                                    let indexCat = selectedCat.applications.findIndex((o: any) => {
                                        return o.idApplication == idApp
                                    });
                                    if (indexCat == -1) {
                                        ejecuteCode = false;
                                    } else {
                                        nameCategory = selectedCat.name;
                                    }
                                }
                            } else {
                                // SIN CATEGORÍA
                                let existsInCat = false;
                                // por cada actividad recorrer todas las categorías para obtener sólo las que cuya aplicación no pertenezca a ninguna
                                listCategories.map((c: any, i: number) => {
                                    if (Array.isArray(c.applications)) {
                                        let findAppInCat = c.applications.find((el: { idApplication: any; }) => el.idApplication == idApp);
                                        if (typeof findAppInCat != "undefined") {
                                            existsInCat = true;
                                            return;
                                        }
                                    }
                                });
                                if (existsInCat) {
                                    ejecuteCode = false;
                                } else {
                                    nameCategory = "0";
                                }
                            }
                        } else {
                            let found = false;
                            for (let i = 0; i < listCategories.length; i++) {
                                let cat = listCategories[i];
                                let index = cat.applications.findIndex((ap: any) => {
                                    return ap.idApplication == idApp
                                });
                                if (index != -1) {
                                    found = true;
                                    nameCategory = cat.name;
                                    break;
                                }
                            }
                            if (!found) {
                                nameCategory = "0";
                            }
                        }
                    } else {
                        if (idSelectedCategoryNonRelevant != "") {
                            if (idSelectedCategoryNonRelevant != "0") {
                                let selectedCat = listCategories.find((el: { idCategory: string; }) => el.idCategory == idSelectedCategoryNonRelevant);
                                if (Array.isArray(selectedCat.applications)) {
                                    let indexCat = selectedCat.applications.findIndex((o: any) => {
                                        return o.idApplication == idApp
                                    });
                                    if (indexCat == -1) {
                                        ejecuteCode = false;
                                    } else {
                                        nameCategory = selectedCat.name;
                                    }
                                }
                            } else {
                                // SIN CATEGORÍA
                                let existsInCat = false;
                                // por cada actividad recorrer todas las categorías para obtener sólo las que cuya aplicación no pertenezca a ninguna
                                listCategories.map((c: any, i: number) => {
                                    if (Array.isArray(c.applications)) {
                                        let findAppInCat = c.applications.find((el: { idApplication: any; }) => el.idApplication == idApp);
                                        if (typeof findAppInCat != "undefined") {
                                            existsInCat = true;
                                            return;
                                        }
                                    }
                                });
                                if (existsInCat) {
                                    ejecuteCode = false;
                                } else {
                                    nameCategory = "0";
                                }
                            }
                        } else {
                            let found = false;
                            for (let i = 0; i < listCategories.length; i++) {
                                let cat = listCategories[i];
                                let index = cat.applications.findIndex((ap: any) => {
                                    return ap.idApplication == idApp
                                });
                                if (index != -1) {
                                    found = true;
                                    nameCategory = cat.name;
                                    break;
                                }
                            }
                            if (!found) {
                                nameCategory = "0";
                            }
                        }
                    }

                    if (!ejecuteCode) {
                        return;
                    }

                    let startDate = new Date();
                    //let endDate = new Date();
                    // Recorrer los registros de la misma aplicación para determinar el tiempo
                    //let idsProjectsTask:any = [];
                    proj.applications[key].forEach((act: any, j: number) => {
                        //idsProjectsTask.push(act.idProjectTask);
                        if (j == 0) {
                            startDate = new Date(act.startTime != null ? act.startTime : act.startDate);
                        }
                        seconds += act.totalSeconds;
                        idApp = act.idApplication;
                    });

                    if (seconds > 0) {
                        //console.log("startDate", startDate);
                        // @ts-ignore
                        arrFinal.push({
                            startDate: anyActivityWithStartTimeNull ? formatDateString(startDate, "dd/MM/yyyy HH:mm:ss").slice(0, 10) : formatDateString(startDate, "dd/MM/yyyy HH:mm:ss"),
                            project: nameProject,
                            username: obj.name + " (" + obj.email + ")",
                            profile,
                            isRelevant,
                            category: nameCategory,
                            application: key,
                            time1: formatSeconds(seconds, false, true, true),
                            /*timeSeconds: withOnlySeconds ? seconds : null,
                            projectTask: idsProjectsTask.filter((item:number,index:number)=>{
                                return idsProjectsTask.indexOf(item) === index;
                              }).toString()*/
                        });
                    }
            });
        });

    });

    arrFinal.sort((a, b) => a.time2 < b.time2 ? 1 : -1);
    //console.log("arrFinal", arrFinal);
    return arrFinal;
}