import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ActivityService {

    find(startDate: string, endDate: string, idUser: number, idTeam: number, idProfile: number, idCategory: number, idProject: number) {
        return http?.get(baseURL.readApi.activity + "/find?startDate="
            + startDate + "&endDate=" + endDate + "&idUser=" + idUser + "&idTeam=" + idTeam
            + "&idProfile=" + idProfile + "&idCategory=" + idCategory + "&idProject=" + idProject);
    }

    findByFiltersOnlyBrowsers(startDate: string, endDate: string, idUser: number, idProfile: number, idProject: number) {
      return http?.get(baseURL.readApi.activity + "/findByFiltersOnlyBrowsers?startDate="
        + startDate + "&endDate=" + endDate + "&idUser=" + idUser + "&idProfile=" + idProfile + "&idProject=" + idProject);
    }

    findWindowsByApplication(startDate: string, endDate: string, idUser: number, 
                             idProfile: number, idProject: number, idApplication: number) {
        return http?.get(baseURL.readApi.activity + "/findWindowsByApplication?startDate="
            + startDate + "&endDate=" + endDate + "&idUser=" + idUser 
            + "&idProfile=" + idProfile + "&idProject=" + idProject + "&idApplication=" + idApplication);
    }

    export(startDate: string, endDate: string, idUser: number, idTeam: number, idProfile: number, idCategory: number, idCategoryNonRelevant: number, idProject: number) {
        return http?.get(baseURL.readApi.activity + "/export?startDate="
            + startDate + "&endDate=" + endDate + "&idUser=" + idUser + "&idTeam=" + idTeam + "&idProfile=" + idProfile + "&idCategory=" + idCategory + "&idCategoryNonRelevant=" +
            +idCategoryNonRelevant + "&idProject=" + idProject, {responseType: "blob"});
    }

    userByWorkstations(idsWorkstation: string) {
        return http?.get(baseURL.readApi.activity + "/userByWorkstations?idsWorkstation=" + idsWorkstation);
    }

    registerDummyActivities() {
        http?.get(baseURL.activitiesapi.activity + "/registerDummyActivities");
    }
}

export default ActivityService;