import React from "react";
import {
    Card, CardContent, Paper, TableContainer,
    TableHead, TableBody, Table, Chip, TablePagination, Box, Stack
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import SiteService from "../../services/site.service";
import {confirmAlert} from "react-confirm-alert";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {USER_LANGUAGE_KEY} from "../../util/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { StyledTableCell, StyledTableRow, TrueffortButton, TrueffortCardTable, TrueffortInputLabel, TrueffortSearchInput, TrueffortTableHeadCell, TrueffortTextField } from "../../MUIstyle/MUICustom";
import { Add, Clear, Delete, Edit, Save } from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomAvatar from "../custom/CustomAvatar";

function SitesTabTRUE() {
    const {t} = useTranslation();
    const {user} = useAuthContext();

    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));

    const [sites, setSites] = React.useState([]);
    const [sitesFiltered, setSitesFiltered] = React.useState([]);
    const imageFile = React.useRef();
    const [imageInfo, setImageInfo] = React.useState();
    //const [arrMessages, setArrMessages] = React.useState([]);
    //const [errorMessage, setErrorMessage] = React.useState("");
    //const [infoMessage, setInfoMessage] = React.useState("");
    const [state, setState] = React.useState({isCreating: false, isEditing: false, showFormAdd: false});
    const siteService = React.useMemo(() => new SiteService(), []);

    //const [pageActive, setPageActive] = React.useState({activePage: 1});
    //const [indexPage, setIndexPage] = React.useState(0);
    //const [sitesPage, setSitesPage] = React.useState([]);
    let ROWS_PER_PAGE = 10;
    const [rowsPerPageSites, setRowsPerPageSites] = React.useState(ROWS_PER_PAGE);
    const [sitesPage, setSitesPage] = React.useState(0);
    const [sitesCount, setSitesCount] = React.useState(0);//Cantidad de categorias

    let NUMBER_ITEMS_BY_PAGE = 10;
    React.useEffect(() => {
        fillSites();
    }, []);

    /*React.useEffect(() => {
        let page = sites.filter((user, k) => k >= indexPage && k < indexPage + NUMBER_ITEMS_BY_PAGE);
        // console.log("page",page, indexPage)
        setSitesPage(page);
    }, [sites, indexPage]);*/

    const fillSites = () => {
        // @ts-ignore
        siteService.findAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                let arr = response.data;
                arr.sort((a, b) => a.name > b.name ? 1 : -1);
                setSites(arr);
                setSitesFiltered(arr);
                setSitesCount(arr.length);
            }
        });
    }

    const handleChangeSearchInput = (searchFilter) => {
        let items = sites.filter((data) => {
            if (searchFilter == "") {
                return sites;
            } else {
                if (data.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return data;
                }
            }
        });

        setSitesFiltered(items);
        setSitesCount(items.length);
    }

    const validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = "Required";
        } else if (values.name.length > 50) {
            errors.name = 'forms.nameLengthValidation50';
        }
        if (imageInfo) {
            if (imageInfo?.type !== "image/png" && imageInfo?.type !== "image/jpeg") {
                errors.image = 'forms.photoTypeValidation';
            } else if (imageInfo.size > 1000000) {
                errors.image = 'forms.photoSizeValidation';
            }
        }
        return errors;
    }

    const handleSubmit = (values) => {
        let dataRequest = {
            name: values.name
        }
        // @ts-ignore
        siteService.create(getFormData(dataRequest)).then(({data: response}) => {
            formik.setSubmitting(false);
            if (response.internalError != null && response.internalError && response.message != null) {
                //setArrMessages([...arrMessages, {type: "danger", message: response.message}]);
                toast.error(t(response.message));
            } else {
                setState({...state, showFormAdd: false, isCreating: false});
                //setArrMessages([...arrMessages, {type: "success", message: t("site_page.msg_success_add")}]);
                toast.success(t("site_page.msg_success_add"));
            }
            fillSites();
        });
    }

    const handleEdit = (values) => {
        let dataRequest = {
            idSite: values.idSite,
            name: values.name,
            idInstance: user.instance.idInstance
        }
        // @ts-ignore
        siteService.updateImage(values.idSite, getFormData(dataRequest)).then(({data: response}) => {
            //console.log(response);
            formik.setSubmitting(false);
            //console.log("finish submiting")
            if (response.internalError != null && response.internalError && response.message != null) {
                //setArrMessages([...arrMessages, {type: "danger", message: response.message}]);
                toast.error(t(response.message));
            } else {
                //setArrMessages([...arrMessages, {type: "success", message: t("site_page.msg_success_update")}]);
                toast.success(t("site_page.msg_success_update"));
            }
            setState({...state, isEditing: false});
            fillSites();
        });
    }

    const getFormData = (dataRequest) => {
        var formData = new FormData();
        if (imageInfo) {
            formData.append("image", imageFile.current.files[0], imageFile.current.files[0].name);
        } else {
            formData.append("image", new Blob([JSON.stringify(null)], {
                type: "application/json"
            }));
        }
        formData.append('siteDto', new Blob([JSON.stringify(dataRequest)], {
            type: "application/json"
        }));

        return formData;
    }

    const formik = useFormik({
        initialValues: {
            idProject: 0,
            name: '',
            code: '',
            idUserInCharge: 0,
            image: ""
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            if (!state.isEditing) {
                handleSubmit(values);
            } else {
                handleEdit(values);
            }
        }
    });

    const prepareEdit = (obj) => {
        setImageInfo(null);
        obj["isEditing"] = true;
        formik.setValues(obj);
        setState({...state, isEditing: true});
    }

    const handleDelete = (obj) => {
        confirmAlert({
            title: t("site_page.msg_delete_confirm1"),
            message: t("site_page.msg_delete_confirm2") + "" + obj.name + "?",
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            siteService.delete(obj.idSite).then(({data: response}) => {
                                //console.log(response);
                                if (response.internalError != null && response.internalError && response.message != null) {
                                    //setArrMessages([...arrMessages, {type: "danger", message: response.message}]);
                                    toast.error(t(response.message));
                                } else {
                                    /*setArrMessages([...arrMessages, {
                                        type: "success",
                                        message: t("site_page.msg_success_delete")
                                    }]);*/
                                    toast.success(t("site_page.msg_success_delete"));
                                    fillSites();
                                }
                            });
                        } catch (error) {
                            console.log("Error #Sites handleDelete" + JSON.stringify(error));
                            /*setArrMessages([...arrMessages, {
                                type: "danger",
                                message: "Ocurrió un error al eliminar sitio."
                            }]);*/
                            toast.error("Ocurrió un error al eliminar sitio.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const handleCancel = () => {
        formik.resetForm();
        setState({...state, isCreating: false, isEditing: false, showFormAdd: false});
    };

    function onChangeImage() {
        setImageInfo({
            name: imageFile.current?.files[0].name,
            size: imageFile.current?.files[0].size,
            type: imageFile.current?.files[0].type
        });
    }

    const handleResetFormik = () => {
        formik.resetForm();
        let obj = {
            idProject: 0,
            name: '',
            code: '',
            idUserInCharge: 0,
            image: ""
        };
        formik.setValues(obj);
    }

    /*const handlePageChange = (pageNumber) => {
        //console.log(`active page is ${pageNumber}`);
        let nextpage = (pageNumber == 1) ? 0 : (pageNumber - 1) * NUMBER_ITEMS_BY_PAGE;
        setIndexPage(nextpage);
        setPageActive({activePage: pageNumber});
    }*/

    const handleChangeSitesRowsPerPage = (event) => {
        setRowsPerPageSites(parseInt(event.target.value, 10));
        setSitesPage(0);
    }
    
    const handleChangeSitesPage = (event, newPage) => {
        setSitesPage(newPage);
    }

    return (
        <Card sx={{padding:'8px', background:'#EFEFEF'}}>
            <Grid container spacing={1} style={{marginBottom:'0.4em'}}>
                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={1} >
                        {/*<FormControl fullWidth>*/}
                        <TrueffortSearchInput
                            id="idSearchInputSites"
                            placeholder={t("site_page.search")}
                            variant="outlined"
                            size="small"
                            fullWidth={matchesXS}
                            onChange={(e) => handleChangeSearchInput(e.target.value)}
                        />
                        {/*</FormControl>*/}

                        <TrueffortButton
                            id="idBtnAddSites"
                            color="secondary"
                            variant="contained"
                            fullWidth={matchesXS}
                            startIcon={<Add />}
                            disabled={state.isEditing}
                            onClick={() => {
                                setImageInfo(null);
                                handleResetFormik();
                                setState({...state, isCreating: true, showFormAdd: true});
                            }}
                            maxHeight="25px">
                            {t("add_new")}
                        </TrueffortButton>
                    </Stack>
                </Grid>
            </Grid>

            {/*arrMessages.map((msg: any, k: number) => (
                <div key={k}>
                    <Alert className="mt-3" type={msg.type} isDismissible>
                        {msg.message}
                    </Alert>
                </div>
            ))*/}

            {state.showFormAdd &&
            <Paper style={{marginBottom:'0.4em'}}>
                <CardContent>
                    {t("site_page.create")}
                    <Grid container spacing={1}>
                        <Grid item lg={5} md={5}>
                            <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                            <TrueffortTextField
                                id="id-field-name-sites-new"
                                maxLength={200}
                                name="name"
                                size="small"
                                required={true}
                                fullWidth
                                placeholder={t("site_page.name_placeholder")}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                            />
                        </Grid>
                        <Grid item lg={5} md={5}>
                            <TrueffortInputLabel shrink labelstring={t("site_page.image")} />
                            <div className="custom-file">
                                <input
                                    type="file"
                                    ref={imageFile}
                                    className="custom-file-input"
                                    lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                    name="image"
                                    disabled={formik.isSubmitting}
                                    readOnly={formik.isSubmitting}
                                    onChange={onChangeImage}
                                    onBlur={formik.handleBlur}
                                    accept="image/png, image/jpeg"
                                />
                                <label
                                    className="custom-file-label"
                                    style={{
                                        whiteSpace: "nowrap",
                                        display: "block",
                                        overflow: "hidden",
                                        background: 'rgb(245, 245, 245)'
                                    }}
                                >
                                    {imageInfo?.name || t("forms.choose_file")}
                                </label>
                            </div>
                            {formik.errors?.image && formik.touched?.image && !formik.isSubmitting ?
                                <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                        </Grid>
                        <Grid item lg={2}>
                            <div style={{display:"flex", alignItems:'flex-end'}}>
                                <TrueffortButton
                                    id="new-sites-button"
                                    size="small"
                                    variant="contained" 
                                    color="primary"
                                    onClick={formik.handleSubmit}>
                                        <Save />
                                    </TrueffortButton>
                                    <div style={{paddingRight:'0.2em'}}></div>
                                <TrueffortButton 
                                    id="cancel-new-sites-button"
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={handleCancel}
                                    disabled={formik.isSubmitting}
                                    ><Clear /></TrueffortButton>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>
            }

            <TrueffortCardTable>
                <Grid container spacing={1} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                    <TableContainer component={Paper}
                        onSubmit={formik.handleSubmit}
                    >
                        <Table
                            responsive={true}>
                            <TableHead>
                                <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                    <TrueffortTableHeadCell style={{width:'8%'}}></TrueffortTableHeadCell>
                                    <TrueffortTableHeadCell style={{width:'77%'}}>{t("Name")}</TrueffortTableHeadCell>
                                    <TrueffortTableHeadCell style={{width:'15%'}}>{t("actions")}</TrueffortTableHeadCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {sitesFiltered.slice(sitesPage * rowsPerPageSites,
                                    sitesPage * rowsPerPageSites + rowsPerPageSites).map((obj, k) => (
                                    !obj.isEditing ?
                                        <StyledTableRow key={obj.idSite}>
                                            <StyledTableCell style={{width:'8%'}}>
                                              <CustomAvatar 
                                                url={obj.imageDto?.url}
                                                code={obj.name}
                                              />
                                            </StyledTableCell>
                                            <StyledTableCell style={{width:'77%'}}>   
                                                {obj.name}
                                            </StyledTableCell>
                                            <StyledTableCell style={{width:'15%'}}>
                                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    <Chip size="small"
                                                        icon={<Edit fontSize="small" />}
                                                        variant="outlined"
                                                        color='primary'
                                                        disabled={state.isEditing || state.isCreating}
                                                        onClick={() => prepareEdit(obj)}
                                                        label={t("elements.buttons.edit")}
                                                    />
                                                    <div style={{ marginLeft: "0.5em" }} />
                                                    <Chip size="small"
                                                        icon={<Delete fontSize="small" />}
                                                        onClick={() => handleDelete(obj)}
                                                        label={t("elements.buttons.delete")}
                                                        variant="outlined"
                                                        color="error"
                                                        disabled={state.isEditing || state.isCreating}
                                                    />
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow> :
                                        <StyledTableRow key={obj.idSite}>
                                            <StyledTableCell colSpan={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={4}>
                                                        <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                                                        <TrueffortTextField
                                                            id="id-btn-name-sites-edit"
                                                            name="name"
                                                            maxLength={200}
                                                            size="small"
                                                            fullWidth
                                                            required={true}
                                                            placeholder={t("project_page.name_placeholder")}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.name}
                                                            error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                            helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4}>
                                                        <TrueffortInputLabel shrink labelstring={t("site_page.image")} />
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                ref={imageFile}
                                                                className="custom-file-input"
                                                                lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                                                name="image"
                                                                disabled={formik.isSubmitting}
                                                                readOnly={formik.isSubmitting}
                                                                onChange={onChangeImage}
                                                                onBlur={formik.handleBlur}
                                                                accept="image/png, image/jpeg"
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    display: "block",
                                                                    overflow: "hidden",
                                                                    background: 'rgb(245, 245, 245)'
                                                                }}
                                                            >{imageInfo?.name || t("forms.choose_file")}
                                                            </label>
                                                        </div>
                                                        {formik.errors?.image && formik.touched?.image && !formik.isSubmitting ?
                                                            <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                                                    </Grid>
                                                    <Grid item lg={4} style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center'}}>
                                                        <Chip size="small"
                                                            icon={<Save fontSize="small" />}
                                                            variant="outlined"
                                                            color='success'
                                                            onClick={formik.handleSubmit}
                                                            label={t("elements.buttons.save")}
                                                        />
                                                        <div style={{ marginLeft: "0.5em" }} />
                                                        <Chip size="small"
                                                            icon={<Clear fontSize="small" />}
                                                            onClick={() => {
                                                                obj["isEditing"] = false;
                                                                formik.resetForm();
                                                                setImageInfo(null);
                                                                setState({...state, isEditing: false});
                                                            }}
                                                            label={t("elements.buttons.cancel")}
                                                            variant="outlined"
                                                            color="error"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </TrueffortCardTable>
            <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                    alignItems: "center", marginTop: '1em' }}>
                    <TablePagination 
                        labelRowsPerPage={t("elements.pagination.label")}
                        rowsPerPageOptions={[5,10,15]}
                        component={"div"}
                        onRowsPerPageChange={handleChangeSitesRowsPerPage}
                        onPageChange={handleChangeSitesPage}
                        count={sitesCount}
                        rowsPerPage={rowsPerPageSites}
                        page={sitesPage}
                    />
                </Box>
            </div>
        </Card>
    );
};

export default SitesTabTRUE;